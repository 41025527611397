import { Box, Card } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Tabela from 'src/components/FaturasRepresentante/Tabela';
import { BoxTableMinHeight, DynamicDataContainer, TableNavigation } from 'src/ds'
import { CardFormFiltrosRef } from 'src/ds/components/CardFormFiltros';
import { RootState } from 'src/store/reducers';
import { listarMinhasStart } from 'src/store/reducers/faturas';

const FaturasRepresentante = () => {
    const dispatch = useDispatch();
    const dadosFaturas = useSelector((state: RootState) => state.faturas?.listarMinhasRepresentante?.data?.dados);
    const listaFaturas = useSelector((state: RootState) => state.faturas?.listarMinhasRepresentante?.data?.dados?.lista);
    const loading = useSelector((state: RootState) => state.faturas?.listarMinhasRepresentante?.loading);
    const cardRef = useRef<CardFormFiltrosRef>(null);
    const hookForm = useForm({ defaultValues: { pagina: 1, paginacao: 20 } });
    const { watch } = hookForm;

    const paginacao = watch("paginacao")
    const pagina = watch("pagina")

    useEffect(() => {
        dispatch(listarMinhasStart(hookForm.getValues()));
    }, [paginacao, pagina])

    return (
        <DynamicDataContainer
            hookForm={hookForm}
            sections={[
                {
                    label: 'Faturas',
                    tableNavigation: {
                        indiceInicial: dadosFaturas?.indiceInicial,
                        indiceFinal: dadosFaturas?.indiceFinal,
                        indiceTotal: dadosFaturas?.indiceFinal,
                        ultimaPagina: dadosFaturas?.ultimaPagina || 0
                    },
                    component:
                        <Tabela
                            data={listaFaturas}
                            loading={loading}
                            fezRequisicao={cardRef.current?.fezRequisicao}
                        />
                }
            ]}
        />
    )
}

export default FaturasRepresentante