import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { RootState } from "src/store/reducers";
import { DynamicDataContainer } from "src/ds";
import TabelaDA from "src/components/DespachoAutomatico/TabelaDespachoAutomatico";
import { listarDaStart } from "src/store/reducers/despachoAutomatico";
import { useNavigate } from "react-router-dom";
import { DynamicDataContainerRef } from "src/ds/components/DynamicDataContainer";

const DespachoAutomatico = () => {
    const daDados = useSelector((state: RootState) => state.despachoAutomatico.listaDa.data?.dados);
    const daLista = daDados?.lista;
    const loading = useSelector((state: RootState) => state.despachoAutomatico.listaDa.loading);
    const hookForm = useForm({
        defaultValues: {
            filtros: '',
            empresaID: '',
            dtInicial: '',
            dtFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });
    const dispatch = useDispatch();
    const primeiraRenderizacao = useRef(true);
    const cardRef = useRef<DynamicDataContainerRef>(null)
    const { watch } = hookForm;
    useEffect(() => {
        if (primeiraRenderizacao.current === true) {
            cardRef.current?.onSubmit();
            primeiraRenderizacao.current = false;
            return
        }
    }, [primeiraRenderizacao]);

    const navigate = useNavigate();

    const menuOptions = [{
        label: "Inserir",
        action: () => navigate('/TabelaDespachoAutomatico/Inserir'),
    }]

    useEffect(() => {
        dispatch(listarDaStart({
            ...hookForm.getValues(),
        }));
    }, [watch("paginacao"), watch("pagina")]);

    return (
        <DynamicDataContainer
            ref={cardRef}
            hookForm={hookForm}
            cardFormFiltrosProps={{
                dispatchMethods: {
                    actionName: 'listarDaStart'
                },
                fields: [
                    { label: "Buscar", name: "filtros" },
                    { label: 'Empresas', name: 'empresaID', type: 'autocompleteEmpresa' },
                ],
                loading: loading,
            }}
            sections={[
                {
                    label: "Despacho Automático",
                    component: <TabelaDA
                        dados={daLista}
                        loading={loading}
                        fezRequisicao={cardRef.current?.fezRequisicao}
                    />,
                    tableNavigation: {
                        indiceInicial: daDados?.indiceInicial,
                        indiceFinal: daDados?.indiceFinal,
                        indiceTotal: daDados?.indiceFinal,
                        ultimaPagina: daDados?.ultimaPagina || 0
                    }
                }
            ]}
        />
    );
};

export default DespachoAutomatico;
