// ButtonGroupPages.tsx
import { Controller, UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import useResponsive from '../hooks/UseResponsive';

interface ButtonGroupPagesProps {
    hookForms: UseFormReturn<any>;
    name?: string;
    maxPages?: number;
    ultimaPagina?: number;
    disabled?: boolean;
}

/**
 * Componente ButtonGroupPages
 *
 * Este componente exibe um Grupo de Botões para navegação entre páginas. 
 * Utiliza o `ToggleButtonGroup` e o `ToggleButton` do `@mui/material`, 
 * e depende do `react-hook-form` para manipulação do formulário.
 *
 * @component
 * @param {Object} props - As propriedades do componente.
 * @param {UseFormReturn<any>} props.hookForms - Objeto retornado pelo `useForm` do `react-hook-form`. 
 * @param {number} [props.maxPages=5] - Número máximo de botões de página a serem exibidos.
 * @param {string} [props.name='pagina'] - O nome do campo no formulário, usado pelo `setValue` e `watch`.
 * @param {number | undefined} props.ultimaPagina - Número da última página disponível para navegação.
 * @param {boolean} [props.disabled] - Determina se o botão de navegação está desabilitado.
 * 
 * @returns {JSX.Element} Um grupo de botões para navegação entre as páginas.
 *
 * @example
 * ```tsx
 * const hookForms = useForm()
 * <ButtonGroupPages 
 *   hookForms={hookForms}
 *   name="pagina" 
 *   ultimaPagina={10}
 * />
 * ```
 */
const ButtonGroupPages = ({
    hookForms,
    maxPages = 5,
    name = 'pagina',
    ultimaPagina,
    disabled
}: ButtonGroupPagesProps) => {
    const { isMobile } = useResponsive()
    const [numPages, setNumPages] = useState<number[]>([]);
    const { control, watch, setValue } = hookForms

    useEffect(() => {
        if (!ultimaPagina) return
        const totalPages = ultimaPagina || 1;
        setNumPages(Array.from({ length: totalPages }, (_, index) => index + 1));
        if (watch(name) > totalPages && totalPages > 0) {
            setValue(name, totalPages);
        }
    }, [ultimaPagina, name, setValue]);

    const getVisiblePages = (current: number, total: number) => {
        const half = Math.floor(maxPages / 2);
        let start = Math.max(current - half, 1);
        let end = Math.min(start + maxPages - 1, total);
        start = Math.max(end - maxPages + 1, 1);
        return Array.from({ length: end - start + 1 }, (_, idx) => start + idx);
    };

    const visiblePages = getVisiblePages(watch(name), numPages.length);

    const handlePageChange = (value: string | null, name: string) => {
        if (value === null) return
        setValue(name, value);
    };

    const handleNextPage = () => {
        const pagina = Number(watch(name));
        if (pagina < numPages.length) {
            setValue(name, (pagina + 1).toString());
        }
    };

    const handlePrevPage = () => {
        const pagina = Number(watch(name));
        if (pagina > 1) {
            setValue(name, (pagina - 1).toString());
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: isMobile ? 'center' : 'stretch', direction: isMobile ? 'column' : 'row' }}>
            <ToggleButton value='' size="small" onClick={handlePrevPage} disabled={watch(name) == 1 || disabled}>
                <KeyboardArrowLeftRoundedIcon />
            </ToggleButton>
            <Controller
                name={name}
                control={control}
                //* Quando o controller está disabled, o hookForm ignora/remove o valor do state
                // disabled={disabled} 
                render={({ field: { value, name } }) => (
                    <ToggleButtonGroup
                        size="small"
                        exclusive
                        value={value?.toString()}
                        color="primary"
                        onChange={(_, value) => handlePageChange(value, name)}
                        disabled={disabled}
                    >
                        {visiblePages?.map(page => (
                            <ToggleButton key={page} value={page.toString()} sx={{ width: '40px' }}>
                                {page}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                )}
            />
            <ToggleButton value='' size="small" onClick={handleNextPage} disabled={watch(name) == ultimaPagina || disabled}>
                <KeyboardArrowRightRoundedIcon />
            </ToggleButton>
        </Box>
    )
}

export default ButtonGroupPages;
