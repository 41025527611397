import { useCallback, useEffect, useRef, useState } from 'react'
import { useTheme, Avatar, Box, Card, CardContent, CardMedia, Container, LinearProgress, Typography, linearProgressClasses, styled, CircularProgress, Button, TextField, Grid2 } from '@mui/material'
import logo from '../../assets/images/logo.png'
import { useNavigate, useParams } from 'react-router-dom';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import MapRastreio from '../../components/MapRastreio';
import { useDispatch, useSelector } from 'react-redux';
import { avaliacaoRequest, empresaStart, entregadoresStart, pedidoStart } from '../../store/reducers/rastreio';
import { RootState } from 'src/store/reducers';
import { StarRating } from 'src/ds';
import { buscarTemplateLoginStart } from 'src/store/reducers/utils';
import dayjs from 'dayjs';

interface Etapa {
    situacao: string;
    nEtapa: number;
    texto?: string | null;
}

const etapas: { [key: string]: Etapa } = {
    // estados possíveis = ['RECEBIDO', 'PRONTO', 'DESPACHADO', 'ACEITO', 'NO_ESTABELECIMENTO', 'EM ROTA', 'RETORNANDO', 'FINALIZADO', 'CANCELADO',],
    recebido: {
        situacao: 'Recebido',
        nEtapa: 0,
        texto: 'Seu pedido está sendo preparado...',
    },
    pronto: {
        situacao: 'Pronto',
        nEtapa: 0,
        texto: 'Seu pedido está sendo preparado...',
    },
    despachado: {
        situacao: 'Despachado',
        nEtapa: 1,
        texto: 'Seu pedido está aguardando coleta...',
    },
    aceito: {
        situacao: 'Aceito',
        nEtapa: 1,
        texto: 'Entregador a caminho do estabelecimento...',
    },
    no_estabelecimento: {
        situacao: 'Na origem',
        nEtapa: 1,
        texto: 'Entregador no estabelecimento...',
    },
    em_rota: {
        situacao: 'Em rota',
        nEtapa: 2,
        texto: 'A caminho do seu local...',
    },
    finalizado: { //Avalie
        situacao: 'Finalizado',
        nEtapa: 4,
        texto: 'Chegou no seu local...',
    },
    cancelado: {
        situacao: '',
        nEtapa: 4,
        texto: null,
    },
    retornando: {
        situacao: 'Retornando',
        nEtapa: 4,
        texto: null,
    },
}

const Rastreio = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useTheme();
    const { idParam } = useParams()
    const pedido = useSelector((state: RootState) => state.rastreio.pedido)
    const [nomeCliente, setNomeCliente] = useState('')
    const [horarios, setHorarios] = useState<{
        prevMinEntrega: string | null,
        prevMaxEntrega: string | null,
        ultimaAtualizacao: string | null
    }>({
        prevMinEntrega: '',
        prevMaxEntrega: '',
        ultimaAtualizacao: '',
    })
    const entregadoresOnline = useSelector((state: RootState) => state.rastreio.entregadores)
    const empresa = useSelector((state: RootState) => state.rastreio.empresa)
    const [etapaAtual, setEtapaAtual] = useState<Etapa | null>(null)
    const [estrelas, setEstrelas] = useState(0)
    const [comentario, setComentario] = useState('')
    const [positionCliente, setPositionCliente] = useState<{ lat: number, lng: number } | null>(null)
    const [positionEntregador, setPositionEntregador] = useState<{ lat: number, lng: number } | null>(null)
    const [positionEmpresa, setPositionEmpresa] = useState<{ lat: number, lng: number } | null>(null)
    const timer = useRef<number | null>(null)
    const template = useSelector((state: RootState) => state.config.template?.template)

    const verificarEtapaAtual = (etapaAtiva: string) => {
        const etapa = etapas[etapaAtiva.toLowerCase() as keyof typeof etapas]
        // Lida com a requisição inicial de entregador, evitando que seja feita quando a etapa não está definida
        // e entregador na situação RETORNANDO (caso seja feita a req a posição é ainda é retornada)
        if (etapaAtual?.nEtapa === null && etapa.nEtapa !== 4) dispatch(entregadoresStart(idParam))
        if (!pedido.data?.dados?.lista[0] && etapa.nEtapa === 4) timer.current && clearInterval(timer.current)
        if (etapa.nEtapa !== null && etapa.nEtapa !== undefined) setEtapaAtual(etapa)
    }

    useEffect(() => {
        if (!template) return;
        const { faviconUrl, nome } = template
        const favicon = document.getElementById("dynamic-favicon") as HTMLLinkElement;
        const title = document.getElementById("dynamic-title") as HTMLTitleElement;
        if (favicon && faviconUrl && title) {
            favicon.href = faviconUrl;
            title.innerText = `${nome}`;
        }
    }, [template]);

    const fetchDataEmAndamento = useCallback(() => {
        dispatch(empresaStart(idParam))
        dispatch(pedidoStart(idParam))
        if (etapaAtual?.nEtapa && etapaAtual?.nEtapa !== 4) {
            dispatch(entregadoresStart(idParam))
        }
    }, [etapaAtual, dispatch, idParam])

    useEffect(() => {
        dispatch(buscarTemplateLoginStart({}));
        if (idParam === 'rastreio/finalizar') { navigate('/rastreio/finalizar'); }
        fetchDataEmAndamento()
        timer.current = setInterval(fetchDataEmAndamento, 20000) as unknown as number
        return () => {
            timer.current && clearInterval(timer.current)
        }
    }, [etapaAtual, dispatch, idParam])

    useEffect(() => {
        if (!pedido?.data?.sucesso) return
        if (pedido.data.dados?.lista[0] === undefined) {
            verificarEtapaAtual('FINALIZADO')
        } else {
            const { clienteNome, situacao, dataEtaEntrega, dataEtaEntregaStr, latitude, longitude } = pedido.data.dados.lista[0]
            setNomeCliente(clienteNome)
            verificarEtapaAtual(situacao)
            setHorarios({
                prevMinEntrega: dataEtaEntrega,
                prevMaxEntrega: dataEtaEntrega,
                ultimaAtualizacao: new Date().toLocaleString('en-US', { timeZone: 'America/Sao_Paulo', hour12: false, hour: '2-digit', minute: '2-digit' }),
            })
            setPositionCliente({
                lat: latitude,
                lng: longitude
            })
        }
    }, [pedido])

    useEffect(() => {
        if (!entregadoresOnline?.data?.sucesso || entregadoresOnline?.data?.dados?.lista[0] === undefined) return
        const { latitude, longitude } = entregadoresOnline.data.dados.lista[0]
        setPositionEntregador({ lat: latitude, lng: longitude })
    }, [entregadoresOnline])

    useEffect(() => {
        if (!empresa?.data?.sucesso || empresa?.data?.dados?.lista[0] === undefined) return
        const { latitude, longitude } = empresa.data.dados.lista[0]
        setPositionEmpresa({ lat: latitude, lng: longitude })
    }, [empresa])

    const handleEnviarAvaliacao = () => {
        dispatch(avaliacaoRequest({
            pedidoID: idParam,
            avaliacao: estrelas,
            comentario: comentario,
        }));
        navigate('/rastreio/finalizar')
    }

    /* Estilização */
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 5,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
        },
    }));

    if (!pedido.data) return (
        <Container
            sx={{
                height: '100vh',
                position: 'relative',
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
            }}
            component={'section'}
        >
            <Box maxWidth="xs" sx={{
                height: '100vh', zIndex: 100, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
            }} component={'section'}>
                <CircularProgress color="inherit" size={70} />
            </Box>
        </Container>
    )

    return (
        <Box sx={{ height: '100vh', position: 'relative' }} component={'section'}>
            <MapRastreio
                positionCliente={positionCliente}
                positionEntregador={positionEntregador}
                positionEmpresa={positionEmpresa}
                etapaAtual={etapaAtual}
            />
            <Container maxWidth="xs" sx={{ height: '100vh', zIndex: 100 }} component={'section'}>
                <Grid2 container alignContent="space-between" sx={{ p: '1rem', height: '100vh' }} >
                    <Grid2 container size={12} sx={{ zIndex: 100 }} >
                        <Card
                            sx={{
                                borderRadius: '7.5px',
                                p: '1rem .5rem',
                                width: '100%'
                            }}
                            elevation={3}
                        >
                            <CardMedia
                                onDragStart={(e) => e.preventDefault()}
                                component="img"
                                image={template ? template?.logoUrl : logo}
                                title="Logo Empresa"
                                sx={{ width: '20%', margin: '0 auto', borderRadius: 1 }}
                            />
                            {etapaAtual && (etapaAtual.nEtapa !== 4) && (
                                <CardContent
                                    sx={{
                                        p: '1rem 0',
                                        '&:last-child': {
                                            pb: '0',
                                        }
                                    }}
                                >
                                    <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>
                                        Olá {nomeCliente || 'cliente'}, {horarios.prevMinEntrega
                                            ? <>
                                                a previsão de entrega será
                                                <strong style={{ color: 'primary.main' }}>
                                                    {` ${dayjs(horarios.prevMinEntrega, 'DD/MM/YYYY HH:mm:ss').format('HH:mm')} e
                                                ${horarios.prevMaxEntrega ? dayjs(horarios.prevMaxEntrega, 'DD/MM/YYYY HH:mm:ss').add(10, 'm').format('HH:mm') : '-'}`}
                                                </strong>
                                            </> : 'seu pedido está sendo preparado e logo estará em sua direção'
                                        }.
                                    </Typography>
                                    <Typography sx={{ fontSize: '0.75rem', color: 'rgba(159, 159, 159, 1)' }}>
                                        Última atualização do mapa: {horarios.ultimaAtualizacao || '--:--'}
                                    </Typography>
                                </CardContent>
                            )}
                        </Card>
                    </Grid2>
                    <Grid2 container size={12} sx={{ zIndex: 100 }}>
                        <Card
                            sx={{ borderRadius: '7.5px', width: '100%' }}
                            elevation={3}
                        >
                            {(etapaAtual !== null && etapaAtual !== undefined)
                                && (etapaAtual?.nEtapa !== null && etapaAtual?.nEtapa !== undefined)
                                && (etapaAtual?.nEtapa >= 0 && etapaAtual?.nEtapa <= 3)
                                && (
                                    <CardContent >
                                        <Grid2 container size={12} spacing={1} >
                                            <Grid2 size={3}>
                                                {etapaAtual?.nEtapa === 0 ? <BorderLinearProgress variant="indeterminate" /> :
                                                    <BorderLinearProgress variant="determinate" value={etapaAtual?.nEtapa >= 0 ? 100 : 0} />}
                                            </Grid2>
                                            <Grid2 size={3}>
                                                {etapaAtual?.nEtapa === 1 ? <BorderLinearProgress variant="indeterminate" /> :
                                                    <BorderLinearProgress variant="determinate" value={etapaAtual?.nEtapa >= 1 ? 100 : 0} />}
                                            </Grid2>
                                            <Grid2 size={3}>
                                                {(etapaAtual?.nEtapa === 2) ? <BorderLinearProgress variant="indeterminate" /> :
                                                    <BorderLinearProgress variant="determinate" value={etapaAtual?.nEtapa >= 2 ? 100 : 0} />}
                                            </Grid2>
                                            <Grid2 size={3}>
                                                {etapaAtual?.nEtapa === 3 ? <BorderLinearProgress variant="indeterminate" /> :
                                                    <BorderLinearProgress variant="determinate" value={etapaAtual?.nEtapa >= 3 ? 100 : 0} />}
                                            </Grid2>
                                        </Grid2>
                                        <Typography
                                            sx={{
                                                fontSize: '1rem',
                                                m: '.75rem 0',
                                                fontWeight: '500'
                                            }}
                                        >
                                            {etapaAtual.texto}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                            <Avatar
                                                sx={{
                                                    bgcolor: theme.palette.primary.main,
                                                    width: 46,
                                                    height: 46,
                                                }}
                                                src={pedido.data?.dados?.lista[0]?.entregadorUsuario?.perfilUrl || ''}
                                            >
                                                {pedido.data?.dados?.lista[0]?.entregadorUsuario?.perfilUrl ? null : <SportsMotorsportsIcon />}
                                            </Avatar>
                                            <Typography sx={{ fontSize: '0.75rem', fontWeight: '500' }}>
                                                {pedido.data?.dados?.lista[0]?.entregadorUsuario?.nome
                                                    ? `${pedido.data?.dados.lista[0].entregadorUsuario?.nome}`
                                                    : 'Entregador não alocado'
                                                }
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                )}
                            {(etapaAtual !== null && etapaAtual !== undefined) &&
                                (etapaAtual?.nEtapa !== null && etapaAtual?.nEtapa !== undefined) &&
                                (etapaAtual.nEtapa === 4) && (
                                    <CardContent sx={{ alignContent: "center", textAlign: "center" }}>
                                        <Grid2 container rowGap={'.5rem'} >
                                            <Grid2 container spacing={1}>
                                                <Grid2 size={3}>
                                                    <BorderLinearProgress variant="determinate" value={100} />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <BorderLinearProgress variant="determinate" value={100} />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <BorderLinearProgress variant="determinate" value={100} />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <BorderLinearProgress variant="determinate" value={100} />
                                                </Grid2>
                                            </Grid2>
                                            <Grid2 size={12}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '1rem',
                                                        fontWeight: '500'
                                                    }}
                                                >
                                                    Seu pedido foi finalizado, avalie a entrega
                                                </Typography>
                                            </Grid2>
                                            <Grid2 size={12}>
                                                <StarRating
                                                    estrelas={estrelas}
                                                    setEstrelas={setEstrelas}
                                                    size={'1.875rem'}
                                                    cor='primary.main'
                                                />
                                            </Grid2>
                                            <Grid2 size={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Comentário"
                                                    name='comentario'
                                                    value={comentario}
                                                    multiline
                                                    onChange={(e) => setComentario(e.target.value)}
                                                    maxRows={5}
                                                    minRows={2}
                                                    slotProps={{ htmlInput: { maxLength: 300 } }}
                                                />
                                            </Grid2>
                                            <Grid2 container justifyContent={"center"} textAlign={"center"} size={12}>
                                                <Grid2 size={8}>
                                                    <Button
                                                        variant="containedFilled"
                                                        disabled={estrelas === 0}
                                                        onClick={handleEnviarAvaliacao}
                                                    >
                                                        Enviar avaliação
                                                    </Button>
                                                </Grid2>
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                )
                            }
                        </Card>
                    </Grid2>
                </Grid2>
            </Container>
        </Box >
    )
}

export default Rastreio