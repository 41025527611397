// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardContent, Button, IconButton, Typography, Collapse, Grid, Select, MenuItem, InputLabel, FormControl, TextField, Box, styled, FormHelperText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandLess, Search, TwoWheeler } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import EnderecoDeColeta from '../EnderecoDeColeta';
import EnderecoDeEntrega from '../EnderecoDeEntrega';
import { buscarPorCodigo, buscarPorTelefone, calcularValores, fazerPedido, limparCriarPedido, limparMensagens } from '../../../store/reducers/criarPedido';
import DetalhesDoPedido from '../DetalhesDoPedido';
import AgendarPedido from '../AgendarPedido';
import { usePedido } from '../../../context/PedidoContext';
import { Controller, useForm } from 'react-hook-form';
import { dinheiro, telefone } from '../../../ds/common';
import { AutocompleteEmpresas, BoxLine, ButtonLoading, CardPedidoContent, ContainerLoading, GridColumn, HeaderCard, ModalOpcoes, TextFieldWithButton } from '../../../ds';
import { emAndamentoStart, listarEmpresasPick } from '../../../store/reducers/config';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { setAtualizarEmAndamento } from 'src/store/reducers/utils';

const CriarPedido = ({ close }) => {
    const dadosEditar = usePedido();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [criouPedido, setCriouPedido] = useState(false);
    const prioridades = useSelector(state => state.config?.prioridade);
    const modelo = useSelector(state => state.criarPedido?.modelo); // Modelo retornado pela Reentrega do pedido 
    const loading = useSelector(state => state.criarPedido?.loading);
    const loadingFazerPedido = useSelector(state => state.criarPedido?.fazerPedido?.loading);
    const valores = useSelector(state => state.criarPedido?.valores);
    const pickEmpresaId = useSelector(state => state.config?.empresasPick?.dados?.lista)?.[0]?.empresaID
    const empresaID = useSelector(state => state.config?.empresaID) || pickEmpresaId || '';
    const empresas = useSelector(state => state.config?.empresasPick?.dados?.lista);
    const loadingMasterPage = useSelector(state => state.config?.loadingMasterPage);
    const loadingValores = useSelector(state => state.criarPedido?.loadingValores);
    const { showSuccess, showError } = useSnackbarContext()
    const criarPedidoResposta = useSelector(state => state.criarPedido?.fazerPedido?.resposta);
    const criarPedidoError = useSelector(state => state.criarPedido?.fazerPedido?.error);

    const [positionEnderecoEntrega, setPositionEnderecoEntrega] = useState({
        latitude: null,
        longitude: null
    })
    const [positionEnderecoColeta, setPositionEnderecoColeta] = useState({
        latitude: null,
        longitude: null
    })
    const [openModalCalcular, setOpenModalCalcular] = useState(false);
    const [expandedPontoColeta, setExpandedPontoColeta] = useState(false);
    const [expandedAgendarPedido, setExpandedAgendarPedido] = useState(false);
    const [calcular, setCalcular] = useState(false);
    const [modeloAtual, setModeloAtual] = useState({});
    const hookForm = useForm({
        defaultValues: {
            pedidoID: '',
            origemPedidoID: '',
            empresaID: empresaID,
            codigoExterno: '',
            clienteNome: '',
            clienteTelefone: '',
            valor: 0,
            formaPagamentoID: '',
            trocoPara: '', /**/
            detalhes: '',
            dataAgendamento: '',
            dataInicioPreparacaoSugerida: '',
            prioridadeID: '',
            origemEndereco: {
                logradouro: '',
                CEP: '',
                numero: '',
                complemento: '',
                pontoReferencia: '',
                bairro: '',
                nome: 'endereco',
                latitude: '',
                longitude: '',
            },
            entregaEndereco: {
                logradouro: '',
                CEP: '',
                numero: '',
                complemento: '',
                pontoReferencia: '',
                bairro: '',
                nome: 'endereco',
                latitude: '',
                longitude: '',
            },
        }
    });
    const {
        formState,
        register,
        formState: { errors },
        watch,
        setValue,
        getValues,
        handleSubmit,
        control,
        reset,
    } = hookForm;

    useEffect(() => {
        if (empresaID) setValue('empresaID', empresaID);
    }, [empresaID])

    useEffect(() => {
        if (empresas?.length > 0) return
        dispatch(listarEmpresasPick({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '',
            filtrarPorTags: true
        }))
    }, []);

    function encontrarTaxasMinMax(valores) {
        if (valores?.length === 0 || !valores) return { taxaMin: 0, taxaMax: 0 };

        let taxaMin = valores[0]?.taxaTotalCobrada;
        let taxaMax = valores[0]?.taxaTotalCobrada;

        for (let i = 1; i < valores?.length; i++) {
            if (valores[i].taxaTotalCobrada < taxaMin) {
                taxaMin = valores[i].taxaTotalCobrada;
            }
            if (valores[i].taxaTotalCobrada > taxaMax) {
                taxaMax = valores[i].taxaTotalCobrada;
            }
        }

        return { taxaMin, taxaMax };
    }

    const { taxaMin, taxaMax } = encontrarTaxasMinMax(valores);

    useEffect(() => {
        if (dadosEditar.pedidoAtual) {
            setModeloAtual(dadosEditar.pedidoAtual);
        } else if (Object.keys(modelo)?.length > 0) {
            setModeloAtual(modelo);
        }
    }, [dadosEditar.pedidoAtual, modelo]);

    useEffect(() => {
        if (Object.keys(modeloAtual)?.length > 0) {
            if (modeloAtual?.telefoneReentrega) {
                // Ocorre para a reentrega por telefone
                reset({
                    empresaID: modeloAtual.empresaID || '',
                    clienteNome: modeloAtual.nome || '',
                    clienteTelefone: modeloAtual.telefone || '',
                    dataAgendamento: modeloAtual.dataAgendamento || '',
                    dataInicioPreparacaoSugerida: modeloAtual.dataInicioPreparacaoSugerida || '',
                    entregaEndereco: {
                        nome: 'endereco',
                        logradouro: modeloAtual.endereco?.logradouro || '',
                        complemento: modeloAtual.endereco?.complemento || '',
                        pontoReferencia: modeloAtual.endereco?.pontoReferencia || '',
                        bairro: modeloAtual.endereco?.bairro || '',
                        CEP: modeloAtual.endereco?.CEP || '',
                        // latitude: modeloAtual.endereco?.latitude || '',
                        // longitude: modeloAtual.endereco?.longitude || '',
                    },
                })
                setPositionEnderecoEntrega({
                    latitude: modeloAtual.endereco?.latitude || '',
                    longitude: modeloAtual.endereco?.longitude || ''
                });
            } else if (Object.keys(modeloAtual?.entregaEndereco || {})?.length > 0) {
                // Ocorre para a reentrega por código
                reset({
                    empresaID: modeloAtual.empresaID || '',
                    codigoExterno: modeloAtual.codigoExterno || '',
                    clienteTelefone: modeloAtual.clienteTelefone || '',
                    clienteNome: modeloAtual.clienteNome || '',
                    valor: modeloAtual.valor || 0,
                    formaPagamentoID: modeloAtual.formaPagamentoID || '',
                    prioridadeID: modeloAtual.prioridadeID || '',
                    detalhes: modeloAtual.detalhes || '',
                    dataAgendamento: modeloAtual.dataAgendamento || '',
                    dataInicioPreparacaoSugerida: modeloAtual.dataInicioPreparacaoSugerida || '',
                    origemEndereco: {
                        CEP: modeloAtual.origemEndereco?.CEP || '',
                        complemento: modeloAtual.origemEndereco?.complemento || '',
                        logradouro: modeloAtual.origemEndereco?.logradouro || '',
                        bairro: modeloAtual.origemEndereco?.bairro || '',
                        pontoReferencia: modeloAtual.origemEndereco?.pontoReferencia || '',
                        nome: 'endereco',
                        // latitude: positionEnderecoEntrega.latitude || '',
                        // longitude: positionEnderecoEntrega.longitude || '',
                    },
                    entregaEndereco: {
                        nome: 'endereco',
                        logradouro: modeloAtual.entregaEndereco?.logradouro || '',
                        complemento: modeloAtual.entregaEndereco?.complemento || '',
                        pontoReferencia: modeloAtual.entregaEndereco?.pontoReferencia || '',
                        bairro: modeloAtual.entregaEndereco?.bairro || '',
                        CEP: modeloAtual.entregaEndereco?.CEP || '',
                        // latitude: modeloAtual.entregaEndereco?.latitude || '',
                        // longitude: modeloAtual.entregaEndereco?.longitude || '',
                    },
                });
                setPositionEnderecoEntrega({
                    latitude: modeloAtual.entregaEndereco?.latitude || '',
                    longitude: modeloAtual.entregaEndereco?.longitude || ''
                });
                setPositionEnderecoColeta({
                    latitude: modeloAtual.origemEndereco?.latitude || '',
                    longitude: modeloAtual.origemEndereco?.longitude || ''
                })
            } else {
                // Editar Pedido
                reset({
                    empresaID: modeloAtual.empresaID || '',
                    codigoExterno: modeloAtual.codigoExterno || '',
                    clienteTelefone: modeloAtual.clienteTelefone || '',
                    clienteNome: modeloAtual.clienteNome || '',
                    valor: modeloAtual.valor || 0,
                    formaPagamentoID: modeloAtual.formaPagamentoID || '',
                    prioridadeID: modeloAtual.prioridadeID || '',
                    detalhes: modeloAtual.detalhes || '',
                    pedidoID: modeloAtual.pedidoID || '',
                    // origemEndereco: {
                    //     latitude: positionEnderecoEntrega.latitude || '',
                    //     longitude: positionEnderecoEntrega.longitude || '',
                    // },
                    entregaEndereco: {
                        nome: 'endereco',
                        logradouro: modeloAtual.entregaEnderecoLogradouro || '',
                        complemento: modeloAtual.entregaEnderecoComplemento || '',
                        CEP: modeloAtual.entregaEnderecoCEP || '',
                        pontoReferencia: modeloAtual.entregaEnderecoPontoReferencia || '',
                        bairro: modeloAtual.entregaEnderecoBairro || '',
                        // latitude: modeloAtual?.latitude || '',
                        // longitude: modeloAtual?.longitude || '',
                    },
                });
                setPositionEnderecoEntrega({
                    latitude: modeloAtual?.latitude || '',
                    longitude: modeloAtual?.longitude || ''
                });
                setPositionEnderecoColeta({
                    latitude: modeloAtual.origemEndereco?.latitude || '',
                    longitude: modeloAtual.origemEndereco?.longitude || ''
                })
            }
        }
    }, [modeloAtual, reset]);

    useEffect(() => {
        if (criarPedidoError) {
            showError({
                message: criarPedidoError,
                duration: 5000
            })
            dispatch(limparMensagens());
        }
    }, [criarPedidoError])

    useEffect(() => {
        if (criarPedidoResposta?.sucesso === true) {
            showSuccess({
                message: criarPedidoResposta?.mensagem,
                duration: 5000
            })
            dispatch(limparCriarPedido())
            setCriouPedido(true)
            dispatch(emAndamentoStart(watch('empresaID')))
            close()
            dispatch(setAtualizarEmAndamento())
        }
    }, [criarPedidoResposta])

    const handleExpandPontoColetaClick = () => {
        setExpandedPontoColeta(!expandedPontoColeta);
    };

    const handleExpandAgendarPedido = () => {
        setExpandedAgendarPedido(!expandedAgendarPedido);
    };

    const handleCloseClick = () => {
        close();
    };


    const editar = dadosEditar.pedidoAtual !== "" && dadosEditar.pedidoAtual !== null;

    const propriedadesExcluidas = useMemo(() => [
        'dataAceitoStr',
        'dataChegouEstabelecimentoStr',
        'dataCadastroStr',
        'dataDespachadoStr',
        'dataProntoStr',
        'situacao',
        'tempoDecorridoCadastro',
        'tempoDecorridoSituacao',
        'temRetorno',
        'semCredito',
        'podeAtribuirEntregador',
        'entregadorUsuarioID',
        'entregadorUsuario',
        'entregadorUsuarioNome',
        'tipoVeiculo',
        'codigoQuatro',
        'codigo',
        'origemEnderecoStr',
        'entregaEnderecoID',
        'entregaEnderecoBairro',
        'entregaEnderecoLogradouro',
        'entregaEnderecoComplemento',
        'entregaEnderecoStr',
        'rotaID',
        'rotaOrdem',
        'taxaBaseCobrada',
        'taxaExtraCobrada',
        'taxaBaseEntregador',
        'taxaExtraEntregador',
        'taxaTotalCobrada',
        'taxaTotalEntregador',
        'distanciaCalculadaKm',
        'distanciaCalculadaKmPorRota',
        'trocoPara',
        'entregadorNome',
        'entregadorTelefone',
        'entregadorTipo',
        'minsEtaEntrega',
        'tipoVeiculoID',
        'tagsSeparadas',
    ], []);

    const handleConfirmar = async () => {
        try {
            const form = new FormData();

            function addObjectToFormData(obj, prefix = '') {
                if (!obj || typeof obj !== 'object') return;

                Object.entries(obj)?.forEach(([key, value]) => {
                    if (propriedadesExcluidas.includes(key) || value === undefined) return;

                    const formKey = prefix ? `${prefix}.${key}` : key;

                    if ((key === 'formaPagamento' || key === 'prioridade') && typeof value === 'object') {
                        form.append(key + 'ID', value[key + 'ID']);
                        return;
                    }

                    if (key === 'valor') {
                        value = parseFloat(value.toString().replace('R$', '').replace(',', '.').trim()).toFixed(2).replace('.', ',');
                    } else if (key === 'latitude' || key === 'longitude') {
                        value = value.toString().replace('.', ',');
                    }

                    if (typeof value === 'object' && !Array.isArray(value) && !(value instanceof File)) {
                        addObjectToFormData(value, formKey);
                    } else {
                        form.append(formKey, value);
                    }
                });
            }

            addObjectToFormData(getValues());

            if (!calcular) {
                dispatch(fazerPedido(form));
                setOpenModalCalcular(false);

            } else {
                dispatch(calcularValores(form));
                setOpenModalCalcular(true);
            }
        } catch (error) {
            if (error?.message) {
                showError({ message: error.message, duration: 5000 });
            }
        }

        setCalcular(false);
    };

    function criarPedido(data) {
        handleConfirmar()
    }

    // Lida com reentrega de pedido
    const buscarCodigo = () => {
        dispatch(buscarPorCodigo({ empresaID: watch('empresaID'), codigoPedido: watch('codigoExterno') }));
    }

    const buscarCodigoTelefone = () => {
        dispatch(buscarPorTelefone({ empresaID: watch('empresaID'), telefone: watch('clienteTelefone') }));
    }

    return (
        <Grid
            component={'section'}
            sx={{
                padding: '.5rem',
                backgroundColor: theme.palette.primary.bgDefault,
                height: '100%',
                overflowY: 'auto',
            }}
        >
            {loadingMasterPage
                ? <ContainerLoading />
                : <form onSubmit={handleSubmit(criarPedido)}>
                    <HeaderCard title={editar ? 'Editar pedido' : 'Criar pedido'} onClick={handleCloseClick} />
                    <StyledCard>
                        <CardPedidoContent>
                            <Typography variant="h6" mb={empresas?.length > 1 ? 2 : 0}>Informações básicas</Typography>
                            {empresas?.length > 1 && (
                                <AutocompleteEmpresas
                                    hookForm={{
                                        methods: hookForm,
                                        name: 'empresaID',
                                        rules: { required: 'Empresa obrigatório' }
                                    }}
                                    label='Empresa'
                                // sx={{ flex: '1', width: '1px' }}
                                />
                            )}
                            <BoxLine mt={2}>
                                <Controller
                                    control={control}
                                    name="codigoExterno"
                                    render={({ field }) => (
                                        <TextFieldWithButton
                                            size='small'
                                            label='Número do pedido'
                                            {...field}
                                            loading={loadingFazerPedido}
                                            buttonProps={{
                                                text: 'Reentrega',
                                                variant: 'containedFilled',
                                                onClick: buscarCodigo,
                                                disabled: !watch('empresaID'),
                                            }}
                                            helperText={
                                                !watch('empresaID') || typeof watch('empresaID') !== 'string' || watch('empresaID').trim() === ''
                                                    ? 'Selecione uma empresa!'
                                                    : ''
                                            }
                                            error={
                                                !watch('empresaID') || typeof watch('empresaID') !== 'string' || watch('empresaID').trim() === ''
                                            }
                                        />
                                    )}
                                />
                            </BoxLine>

                            <BoxLine gap={2} mt={2}>
                                <Box sx={{ flex: '1', width: '1px' }}>
                                    <FormControl fullWidth sx={{ margin: '0 1rem 0 0' }}>
                                        <InputLabel shrink htmlFor="observacoes" >Observações</InputLabel>
                                        <Select
                                            size='small'
                                            displayEmpty
                                            label="Observações"
                                            id='observacoes'
                                            fullWidth
                                            {...register('prioridadeID')}
                                            value={watch('prioridadeID')}
                                        >
                                            <MenuItem value="" disabled>
                                                Selecione uma prioridade
                                            </MenuItem>
                                            {prioridades?.map((unidade) => (
                                                <MenuItem
                                                    key={unidade.nome}
                                                    value={unidade.prioridadeID}
                                                    onBlur={() => {
                                                        setValue('prioridadeID', unidade?.prioridadeID);
                                                    }}
                                                    sx={{
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {unidade.nome}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                {/* 
                            // TODO: É de Perfil de Saas
                            <IconButton
                                color="primary"
                                variant='contained'
                                size='small'
                                sx={{ borderRadius: 1 }}
                            >
                                <Cached />
                            </IconButton> 
                            */}
                            </BoxLine>
                        </CardPedidoContent>
                    </StyledCard>
                    <StyledCard>
                        <CardContent
                            sx={{
                                backgroundColor: theme.palette.background.default,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="h6" mb={2}>Cliente</Typography>
                            <TextField
                                size='small'
                                label="Nome do Cliente"
                                variant="outlined"
                                {...register('clienteNome', { required: 'Nome do Cliente obrigatório' })}
                                error={!!errors?.clienteNome}
                                helperText={errors?.clienteNome?.message}
                                InputLabelProps={{ shrink: watch('clienteNome').trim() !== '' }}
                                fullWidth
                            />
                            <BoxLine gap={2} mt={2}>
                                <TextField
                                    size='small'
                                    label="Telefone"
                                    variant="outlined"
                                    {...register('clienteTelefone')}
                                    value={telefone(watch('clienteTelefone'))}
                                    /*  onChange={(e) => {
                                         const formattedValue =  telefone(e.target.value)
                                         setValue('clienteTelefone', e.target.value)
                                     }} */
                                    inputProps={{ maxLength: 15 }}
                                    fullWidth
                                />
                                <IconButton
                                    size='small'
                                    variant='contained'
                                    color="primary"
                                    sx={{ borderRadius: 1 }}
                                    onClick={buscarCodigoTelefone}
                                >
                                    <Search />
                                </IconButton>
                            </BoxLine>
                        </CardContent>
                    </StyledCard>
                    <StyledCard>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '20px'
                            }}
                        >
                            <Typography variant="h6">Alterar ponto de coleta</Typography>
                            <IconButton
                                onClick={handleExpandPontoColetaClick}
                                aria-expanded={expandedPontoColeta}
                                aria-label="show more"
                                color='primary'
                            >
                                {expandedPontoColeta ? <ExpandLess /> : <ExpandMoreIcon />}
                            </IconButton>
                        </CardContent>
                        <Collapse in={expandedPontoColeta} timeout="auto" unmountOnExit>
                            <EnderecoDeColeta
                                position={positionEnderecoColeta}
                                setPosition={setPositionEnderecoColeta}

                                register={register}
                                errors={errors}
                                watch={watch}
                                setValue={setValue}
                                getValues={getValues}
                                formState={formState}
                                control={control}
                            />
                        </Collapse>
                    </StyledCard>
                    <StyledCard>
                        <EnderecoDeEntrega
                            position={positionEnderecoEntrega}
                            setPosition={setPositionEnderecoEntrega}

                            errors={errors}
                            watch={watch}
                            setValue={setValue}
                            getValues={getValues}
                            formState={formState}
                            register={register}
                            control={control}
                        />
                    </StyledCard>
                    <StyledCard>
                        <DetalhesDoPedido
                            errors={errors}
                            watch={watch}
                            setValue={setValue}
                            getValues={getValues}
                            formState={formState}
                            register={register}
                            control={control}
                        />
                    </StyledCard>
                    <StyledCard>
                        <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '20px' }}>
                            <Typography variant="h6">Agendar pedido</Typography>
                            <IconButton
                                onClick={handleExpandAgendarPedido}
                                aria-expanded={expandedAgendarPedido}
                                aria-label="show more"
                                style={{ marginLeft: 'auto' }}
                            >
                                {expandedAgendarPedido ? <ExpandLess color='primary' /> : <ExpandMoreIcon color='primary' />}
                            </IconButton>
                        </CardContent>
                        <Collapse in={expandedAgendarPedido} timeout="auto" unmountOnExit>
                            <AgendarPedido
                                watch={watch}
                                setValue={setValue}
                                register={register}
                                methods={hookForm}
                            />
                        </Collapse>
                    </StyledCard>


                    {!editar && <Button
                        onClick={() => { setCalcular(true) }}
                        type='submit'
                        variant="containedFilled"
                        color='secondary'
                        sx={{ mb: theme.spacing(2) }}
                    >
                        Calcular valores
                    </Button>}
                    <ButtonLoading
                        onClick={() => { setCalcular(false) }}
                        type='submit'
                        variant="containedFilled"
                        loading={loadingFazerPedido}
                    >
                        {editar ? 'Confirmar alterações' : 'Confirmar pedido'}
                    </ButtonLoading>
                    <ModalOpcoes
                        open={openModalCalcular}
                        onClose={() => { setOpenModalCalcular(false) }}
                        descricao={'Taxa de entrega'}
                        opcoes={[
                            { label: 'Cancelar', action: () => { setOpenModalCalcular(false) } },
                            { label: 'Fazer pedido', action: () => { handleConfirmar(true) }, loading: loadingFazerPedido || loadingValores },
                        ]}
                        acao={
                            loadingValores
                                ? <ContainerLoading sx={{ minHeight: '88px' }} />
                                : <GridColumn sx={{
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    p: 2
                                }}>
                                    <TwoWheeler />
                                    <Typography variant="h6">
                                        {dinheiro(taxaMin) === dinheiro(taxaMax)
                                            ? `Por ${dinheiro(taxaMin)}`
                                            : `De ${dinheiro(taxaMin)} até ${dinheiro(taxaMax)} `}
                                    </Typography>
                                </GridColumn>
                        }
                    />
                </form>
            }
        </Grid >
    );
};

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    boxShadow: '1px 4px 6px 6px rgba(0, 0, 0, 0.07)',
    borderRadius: '6px',
}))

export default CriarPedido;
