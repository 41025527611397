import React, { Dispatch, useEffect, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Button, Container, IconButton, MenuItem, Select, SelectChangeEvent, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { addDays, addWeeks, differenceInCalendarWeeks, endOfWeek, format, parse, set, startOfWeek } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { escalasStart, iniciarJornadaStart, finalizarJornadaStart, excluirJornadaStart, limparEscalas } from '../../../store/reducers/escalas';
import { BoxLine, ButtonLoading, maskMoney, ModalOpcoes, TableRowLoading, TableRowNotFound } from '../../../ds';
import { RootState } from 'src/store/reducers';
import { Escala } from 'src/dtos/EscalasDTO';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ModalAbrirVagas from './ModalAbrirVagas';
import dayjs from 'dayjs';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface Props {
    empresaID?: string;
    setDateInterval: Dispatch<React.SetStateAction<{ dataInicio: string; dataFim: string }>>;
    callback: () => void;
    podeVisualizar: boolean;
}

interface WeekInterval {
    inicio: string;
    fim: string;
}

interface WeekOptions {
    label: string;
    value: string;
}

const TabelaEscalas = (
    { empresaID, setDateInterval, callback, podeVisualizar }: Props
) => {
    const SEM_ENTREGADOR = '00000000-0000-0000-0000-000000000000'
    const theme = useTheme();
    const { showSuccess, showError, showSnackbar } = useSnackbarContext();
    const dispatch = useDispatch()
    const [weekInterval, setWeekInterval] = useState<WeekInterval>({ inicio: '', fim: '' })
    const [weeksOffset, setWeeksOffset] = useState<number>(0)
    const [rows, setRows] = useState<Escala[]>([])
    const [listaState, setListaState] = useState<Escala[]>([])
    const [weekOptions, setWeekOptions] = useState<WeekOptions[]>([])
    const [selectedWeek, setSelectedWeek] = useState<string>('')
    const [modalFecharVaga, setModalFecharVaga] = useState<boolean>(false)
    const [modalFinalizarVaga, setModalFinalizarVaga] = useState<boolean>(false)
    const [pedidoIDAtual, setPedidoIDAtual] = useState<string | null>('')
    const [modalCriarVaga, setModalCriarVaga] = useState<boolean>(false)

    const tipoUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario)
    const escalasStore = useSelector((state: RootState) => state.escalas?.listaEscalas)
    const loadingEscalas = useSelector((state: RootState) => state.escalas?.listaEscalas?.loading)
    const errorEscalas = useSelector((state: RootState) => state.escalas?.listaEscalas?.error)
    const excluirJornada = useSelector((state: RootState) => state.escalas.excluirJornada)
    const finalizarJornada = useSelector((state: RootState) => state.escalas.finalizarJornada)
    const iniciarJornada = useSelector((state: RootState) => state.escalas.iniciarJornada)

    let ultimaData: null | string = null // Referência da última data p/ separação de linhas

    // Função para formatar data em horário
    const formatedHour = (date: string) => {
        const parsedDate = parse(date, 'dd/MM/yyyy HH:mm:ss', new Date())
        return format(parsedDate, 'HH:mm', { locale: ptBR })
    }

    // Função para formatar data
    const formatedDate = (date: string) => {
        const parsedDate = parse(date, 'dd/MM/yyyy HH:mm:ss', new Date())
        return format(parsedDate, 'dd/MM/yyyy', { locale: ptBR })
    }

    // Função para formatar data em extenso
    const formateExtenseDate = (dateString: string) => {
        const date = parse(dateString, 'dd/MM/yyyy', new Date());
        return format(date, "d 'de' MMMM 'de' yyyy", { locale: ptBR });
    }

    // Função para formatar data em extenso
    const formateDayOfWeekDate = (dateString: string) => {
        const date = parse(dateString, 'dd/MM/yyyy', new Date());;
        return format(date, 'EEEE', { locale: ptBR });
    }

    // Função que retorna data início e fim da semana
    const getStartAndEndOfWeek = (weeksOffset: number) => {
        const today = new Date();
        const targetDate = addWeeks(today, weeksOffset);
        const start = startOfWeek(targetDate, { weekStartsOn: 0 });
        const end = endOfWeek(targetDate, { weekStartsOn: 0 });
        const startFormatted = format(start, 'dd/MM/yyyy', { locale: ptBR });
        const endFormatted = format(end, 'dd/MM/yyyy', { locale: ptBR });
        return { inicio: startFormatted, fim: endFormatted }
    };

    // Função para filtrar e ordenar a lista de dados
    const filterAndSortListaData = ({ inicio, fim }: WeekInterval): Escala[] => {
        const dataInicio = parse(inicio, 'dd/MM/yyyy', new Date());
        const dataFim = addDays(parse(fim, 'dd/MM/yyyy', new Date()), 2);

        return listaState
            ?.filter((item) => {
                const dataItem = parse(item.dataInicio, 'dd/MM/yyyy HH:mm:ss', new Date());
                return dataItem >= dataInicio && dataItem <= dataFim;
            })
            ?.sort((a, b) => {
                const dataA = parse(a.dataInicio, 'dd/MM/yyyy HH:mm:ss', new Date());
                const dataB = parse(b.dataInicio, 'dd/MM/yyyy HH:mm:ss', new Date());
                return dataA.getTime() - dataB.getTime();
            })
    };

    const getWeekOptions = () => {
        if (!weekInterval.inicio || !weekInterval.fim) return [];

        const options = [];
        const date = parse(weekInterval.inicio, 'dd/MM/yyyy', new Date())
        const currentWeekStart = startOfWeek(date, { weekStartsOn: 0 });

        for (let i = -4; i <= 4; i++) {
            const weekStartDate = addWeeks(currentWeekStart, i);
            const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 0 });

            const weekStartFormatted = format(weekStartDate, 'dd/MM/yyyy');

            options.push({
                label: `${format(weekStartDate, "d 'de' MMMM 'de' yyyy", { locale: ptBR })} - ${format(weekEndDate, "d 'de' MMMM 'de' yyyy", { locale: ptBR })}`,
                value: weekStartFormatted,
            });
        }
        return options;
    };

    useEffect(() => {
        setWeekOptions(getWeekOptions());
        return () => {
            dispatch(limparEscalas());
            setPedidoIDAtual('');
        };
    }, []);

    useEffect(() => {
        if (escalasStore && errorEscalas) showError({
            message: errorEscalas,
            duration: 5000
        });
    }, [escalasStore, errorEscalas]);

    useEffect(() => {
        const date = !weekInterval.inicio ? new Date() : parse(weekInterval.inicio, 'dd/MM/yyyy', new Date());
        const currentWeekStart = startOfWeek(date, { weekStartsOn: 0 });
        const currentWeekValue = format(currentWeekStart, 'dd/MM/yyyy');
        setWeekOptions(getWeekOptions());
        setSelectedWeek(currentWeekValue);
    }, [weekInterval]);

    useEffect(() => {
        const interval = getStartAndEndOfWeek(weeksOffset);
        setDateInterval({ dataInicio: interval.inicio, dataFim: dayjs(interval.fim, 'DD/MM/YYYY').add(2, 'day').format('DD/MM/YYYY') });
        setWeekInterval(interval)
    }, [weeksOffset]);

    useEffect(() => {
        setRows(filterAndSortListaData(weekInterval))
    }, [setRows, weekInterval, listaState]);

    const atualizarLista = () => {
        const newEndDate = addDays(parse(weekInterval.fim, 'dd/MM/yyyy', new Date()), 2);
        dispatch(escalasStart({ data: { inicio: weekInterval.inicio, fim: format(newEndDate, 'dd/MM/yyyy') }, empresaID }));
    }

    // UseEffect para Lidar com Loading das Requisições
    useEffect(() => {
        if ((!excluirJornada?.loading && !finalizarJornada?.loading && !iniciarJornada?.loading) && (weekInterval.inicio && weekInterval.fim)) {
            atualizarLista()
        }
    }, [excluirJornada?.loading, finalizarJornada?.loading, iniciarJornada?.loading, weekInterval, empresaID]);

    useEffect(() => {
        if (escalasStore.data?.dados?.lista) setListaState(escalasStore.data.dados.lista)
    }, [escalasStore]);

    // UseEffect para Lidar com o Retorno das Requisições
    useEffect(() => {
        if (!excluirJornada.data) return
        if (!excluirJornada?.loading) {
            if (excluirJornada.data) {
                showSuccess({
                    message: excluirJornada.data.mensagem,
                    duration: 5000
                });
            } else if (excluirJornada.error) {
                showError({
                    message: excluirJornada.error,
                    duration: 5000
                });
            }
        }
    }, [excluirJornada]);

    useEffect(() => {
        if (!iniciarJornada.data) return
        if (!iniciarJornada?.loading && (iniciarJornada.data || iniciarJornada?.error)) {
            showSnackbar({
                message: iniciarJornada.data ? iniciarJornada.data.mensagem : iniciarJornada?.error,
                severity: iniciarJornada.data ? (iniciarJornada.data.sucesso ? 'success' : 'error') : 'error',
                duration: 5000
            })
        }
    }, [iniciarJornada]);

    useEffect(() => {
        if (!finalizarJornada.data) return
        if (!finalizarJornada?.loading && (finalizarJornada.data || finalizarJornada?.error)) {
            showSnackbar({
                message: finalizarJornada.data ? finalizarJornada.data.mensagem : finalizarJornada?.error,
                severity: finalizarJornada.data ? (finalizarJornada.data.sucesso ? 'success' : 'error') : 'error',
                duration: 5000
            })
        }
    }, [finalizarJornada]);

    // Funções de callback
    const handleFecharVaga = (pedidoID: string) => {
        setPedidoIDAtual(pedidoID)
        setModalFecharVaga(true)
    }

    const handleModalFecharVaga = () => {
        dispatch(excluirJornadaStart(pedidoIDAtual));
        setModalFecharVaga(false);
    };

    const handleIniciarVaga = (pedidoID: string) => {
        setPedidoIDAtual(pedidoID);
        dispatch(iniciarJornadaStart(pedidoID));
    };

    const handleFinalizarVaga = (pedidoID: string) => {
        setPedidoIDAtual(pedidoID);
        setModalFinalizarVaga(true);
    };

    const handleModalFinalizarVaga = () => {
        dispatch(finalizarJornadaStart(pedidoIDAtual));
        setModalFinalizarVaga(false);
    }

    const handleEditar = (pedidoID: string) => {
        const vaga = rows.find((row) => row.pedidoID === pedidoID);
        setPedidoIDAtual(pedidoID)
        if (vaga) setModalCriarVaga(true)
    }

    const handleFecharModalCriarVaga = () => {
        setModalCriarVaga(false)
        setPedidoIDAtual(null)
    }

    const handleWeekChange = (e: SelectChangeEvent<unknown>) => {
        const selectedValue = e.target.value as string;
        const parsedDate = parse(selectedValue, 'dd/MM/yyyy', new Date());
        const today = new Date();
        const weeksOffset = differenceInCalendarWeeks(parsedDate, today);
        setWeeksOffset(weeksOffset);
    }

    const handleNextWeek = () => { setWeeksOffset(weeksOffset + 1); }

    const handlePrevWeek = () => { setWeeksOffset(weeksOffset - 1); }

    // Estilização
    const StyledButton = styled(Button)(({ theme }) => ({
        maxWidth: 'min-content',
        borderRadius: '.5rem',
        '& svg': {
            color: 'inherit',
        },
        '&:hover': {
            bgcolor: 'initial',
            '& svg': {
                color: theme.palette.primary.main,
            },
        }
    }))

    const stylesBotaoSituacao = {
        width: '10rem',
        height: '30px',
        padding: '.375rem',
        borderRadius: '.375rem',
    }

    const StyledSelect = styled(Select)(({ theme }) => ({
        fontSize: '1rem',
        fontWeight: 600,
        borderRadius: '0',
        backgroundColor: 'transparent',
        borderBottom: '2px solid transparent',
        '&:hover, &:focus': {
            cursor: 'pointer',
            borderColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiSelect-select': {
            padding: 0,
            wordBreak: 'break-word',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
            width: '100%',
            order: 3,
            margin: 'auto',
            m: '.5rem',
            flex: '1 1 100%',
        },
    }));

    return (
        <Container
            sx={{
                height: '100%',
                flex: '1 1 100%',
                display: 'flex',
                flexDirection: 'column',
                '&. MuiContainer-root': {
                    p: '0',
                },
                '@media (min-width: 600px)': {
                    p: '0',
                },
                '@media (min-width: 1280px)': {
                    maxWidth: '100%',
                }
            }}
        >
            <BoxLine
                sx={{
                    m: '0 0 1.5rem 0',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}
            >
                <StyledButton
                    variant='iconeComTexto'
                    onClick={handlePrevWeek}
                >
                    <ArrowBackRoundedIcon />Voltar data
                </StyledButton>
                {weekOptions?.length > 0 ?
                    <StyledSelect
                        value={selectedWeek}
                        onChange={(event) => { handleWeekChange(event) }}
                        displayEmpty
                        renderValue={(selected) => {
                            if (!selected) {
                                return <Typography variant="body1" fontWeight={'600'} textAlign={'left'}>Selecione uma semana</Typography>;
                            }
                            const selectedOption = weekOptions.find(option => option.value === selected);
                            return <Typography variant="body1" fontWeight={'600'} textAlign={'left'} noWrap={false}>{selectedOption?.label}</Typography>;
                        }}
                    >
                        {weekOptions?.length > 0 && weekOptions?.map((option) => (
                            <MenuItem key={option?.value} value={option?.value}>
                                {option?.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                    : <span></span>
                }
                <StyledButton
                    variant='iconeComTexto'
                    onClick={handleNextWeek}
                >
                    Avançar data<ArrowForwardRoundedIcon />
                </StyledButton>
            </BoxLine>
            <TableContainer
                sx={{
                    flex: '1 1 100%',
                    borderRadius: '.5rem',
                    overflowY: 'auto',
                }}
            >
                <Table bgcolor={theme.palette.background.default} aria-label="Table">
                    <TableHead
                        sx={{
                            bgcolor: theme.palette.background.default,
                            borderRadius: '.5rem',
                            boxShadow: '0 0 8px  rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="center" width={'20%'}>Horário</TableCell>
                            <TableCell align="center" width={'10%'}>Status</TableCell>
                            <TableCell align="center" width={'15%'}>Entregador</TableCell>
                            <TableCell align="center" width={'15%'}>{tipoUsuario === 'Representante' ? 'Valor total cobrado' : 'Valor total pago'}</TableCell>
                            <TableCell align="center" width={'55%'}>ㅤ</TableCell>
                            {podeVisualizar && <TableCell align="center">ㅤ</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            height: '100%',
                        }}
                    >
                        {rows?.length > 0
                            ? rows.map((row) => {
                                const rowDate = formatedDate(row.dataInicio);
                                const novaData = rowDate !== ultimaData
                                ultimaData = rowDate

                                return (
                                    <React.Fragment key={row.pedidoID}>
                                        {novaData &&
                                            <TableRow hover>
                                                <TableCell
                                                    align="center"
                                                    colSpan={5}
                                                >
                                                    <Typography variant="body1" fontWeight={'600'} textAlign={'left'}>
                                                        {`${formateExtenseDate(rowDate)} `}
                                                        <Typography variant="span" fontWeight={'400'} color={'gray'} fontSize={'.875rem'} textTransform={'capitalize'}>
                                                            {formateDayOfWeekDate(rowDate)}
                                                        </Typography>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        }
                                        <TableRow>
                                            <TableCell align="center">
                                                {`${formatedHour(row.dataInicio)} - ${formatedHour(row.dataFim)}`}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title={(row.entregadorUsuarioID !== SEM_ENTREGADOR && row.dataChegouEstabelecimento && !row.dataFinalizado) ? 'Ativo' : 'Offline'} arrow>
                                                    <div
                                                        style={{
                                                            display: 'inline-block',
                                                            width: '14px',
                                                            height: '14px',
                                                            borderRadius: '50%',
                                                            backgroundColor: (row.entregadorUsuarioID !== SEM_ENTREGADOR && row.dataChegouEstabelecimento && !row.dataFinalizado) ? 'green' : 'gray',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.entregadorUsuario.nome}
                                            </TableCell>
                                            <TableCell align="center">
                                                {tipoUsuario === 'Representante'
                                                    ? row.taxaTotalCobrada // Aqui pode vir como 0, o que é falsy mas não é null
                                                        ? maskMoney(row.taxaTotalCobrada)
                                                        : row.taxaMinimaGarantidaCobrada
                                                            ? maskMoney(row.taxaMinimaGarantidaCobrada) + ' (Mínimo garantido)'
                                                            : row.taxaTotalCobrada != null
                                                                ? maskMoney(row.taxaTotalCobrada)
                                                                : '-'
                                                    : row.taxaTotalEntregador
                                                        ? maskMoney(row.taxaTotalEntregador)
                                                        : row.taxaMinimaGarantidaEntregador
                                                            ? maskMoney(row.taxaMinimaGarantidaEntregador) + ' (Mínimo garantido)'
                                                            : row.taxaTotalEntregador != null
                                                                ? maskMoney(row.taxaTotalEntregador)
                                                                : '-'}
                                            </TableCell>
                                            <TableCell align="right">
                                                <BoxLine sx={{ justifyContent: 'flex-end', gap: '1rem' }}>
                                                    {row.entregadorUsuarioID !== SEM_ENTREGADOR ? (
                                                        !row.dataChegouEstabelecimento ? (
                                                            <ButtonLoading
                                                                variant="filled"
                                                                onClick={() => handleIniciarVaga(row.pedidoID)}
                                                                loading={(iniciarJornada?.loading && row.pedidoID === pedidoIDAtual) ? true : false}
                                                                sx={{ bgcolor: theme.palette.green[600], borderColor: theme.palette.green[600], ...stylesBotaoSituacao }}
                                                            >
                                                                Iniciar jornada
                                                            </ButtonLoading>
                                                        ) : !row.dataFinalizado ? (
                                                            <ButtonLoading
                                                                variant="filled"
                                                                onClick={() => handleFinalizarVaga(row.pedidoID)}
                                                                loading={(finalizarJornada?.loading && row.pedidoID === pedidoIDAtual) ? true : false}
                                                                sx={{ bgcolor: '#FF9900', borderColor: '#FF9900', ...stylesBotaoSituacao }}
                                                            >
                                                                Finalizar jornada
                                                            </ButtonLoading>
                                                        ) : null
                                                    ) : !row.dataChegouEstabelecimento ? (
                                                        <ButtonLoading
                                                            variant="filled"
                                                            onClick={() => handleFecharVaga(row.pedidoID)}
                                                            loading={(excluirJornada?.loading && row.pedidoID === pedidoIDAtual) ? true : false}
                                                            color='red'
                                                            sx={{ bgcolor: theme.palette.red[600], borderColor: theme.palette.red[600], ...stylesBotaoSituacao }}
                                                        >
                                                            Fechar vaga (excluir)
                                                        </ButtonLoading>
                                                    ) : null}
                                                </BoxLine>
                                            </TableCell>
                                            {podeVisualizar &&
                                                <TableCell align="center">
                                                    <Tooltip title="Editar Escala" arrow placement='top'>
                                                        <IconButton color="primary" onClick={() => handleEditar(row.pedidoID)}>
                                                            <EditRoundedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>}
                                        </TableRow>
                                    </React.Fragment>
                                )
                            })
                            : loadingEscalas
                                ? <TableRowLoading />
                                : (escalasStore?.data?.dados?.lista?.length === 0 || errorEscalas)
                                && <TableRowNotFound />
                        }
                    </TableBody>
                </Table>
            </TableContainer >

            {/* Editar Vaga */}
            {modalCriarVaga && empresaID && pedidoIDAtual &&
                <ModalAbrirVagas
                    open={modalCriarVaga}
                    onClose={handleFecharModalCriarVaga}
                    empresaID={empresaID}
                    callback={callback}
                    editar={true}
                    pedidoID={pedidoIDAtual}
                />}

            {/* Excluir Vaga */}
            < ModalOpcoes
                open={modalFecharVaga}
                onClose={() => setModalFecharVaga(false)}
                descricao={'Deseja excluir esta vaga?'}
                acao={'Excluir Vaga'}
                opcoes={
                    [
                        { label: 'Cancelar', action: () => setModalFecharVaga(false) },
                        { label: 'Confirmar', action: handleModalFecharVaga },
                    ]
                }
            />

            {/* Finalizar Jornada */}
            <ModalOpcoes
                open={modalFinalizarVaga}
                onClose={() => setModalFinalizarVaga(false)}
                descricao={'Deseja finalizar esta vaga?'}
                acao={'Finalizar Vaga'}
                opcoes={
                    [
                        { label: 'Cancelar', action: () => setModalFinalizarVaga(false) },
                        { label: 'Confirmar', action: handleModalFinalizarVaga },
                    ]
                }
            />
        </Container >
    )
}

export default TabelaEscalas
