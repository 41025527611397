// SelectVagaPadrao.tsx
import { Autocomplete, CircularProgress, SxProps, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { VagaPadrao } from 'src/dtos/EscalasDTO';
import { RootState } from 'src/store/reducers';
import { listarVagasEmpresaSelecionadaStart } from 'src/store/reducers/escalas';

interface SelectVagaPadraoProps {
  empresaID: string;
  callback?: (vagaPadrao?: VagaPadrao | null) => void;
  sx?: SxProps
}

const SelectVagaPadrao: React.FC<SelectVagaPadraoProps> = ({ empresaID, callback, sx }) => {
  const { showError } = useSnackbarContext();
  const dispatch = useDispatch();
  const [vagaPadraoSelecionada, setVagaPadraoSelecionada] = useState<VagaPadrao | null>(null);
  const vagasPadraoEmpresa = useSelector((state: RootState) => state.escalas.listarVagasEmpresaSelecionada);

  useEffect(() => {
    dispatch(listarVagasEmpresaSelecionadaStart({ filtros: empresaID, pagina: '-1', paginacao: '-1' }));
  }, [empresaID]);

  useEffect(() => {
    if (vagasPadraoEmpresa.error) {
      showError({
        message: vagasPadraoEmpresa.error,
        duration: 5000
      });
    }
  }, [vagasPadraoEmpresa.error]);

  useEffect(() => {
    if (vagaPadraoSelecionada) {
      callback?.(vagaPadraoSelecionada);
    }
  }, [vagaPadraoSelecionada]);

  return (
    <Autocomplete
      size='small'
      options={vagasPadraoEmpresa.data?.dados.lista || []}
      getOptionLabel={(option) => option.nome}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Selecione uma vaga padrão'
          label="Vaga Padrão"
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {vagasPadraoEmpresa.loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }
          }}
        />
      )}
      onChange={(_, newValue) => {
        setVagaPadraoSelecionada(newValue);
      }}
    />
  );
};

export default SelectVagaPadrao;

