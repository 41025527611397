import { Box, TextField, Button, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { cleanInput, cleanMoneyInput, replaceDecimalSeparator } from 'src/ds/common';
import { HeaderModal, ModalBase, TextFieldDistance, TextFieldMoney } from 'src/ds';
import { liveMaskMoney, maskDecimal } from 'src/ds';
import { salvarTabelaPorKmStart } from 'src/store/reducers/tabelaCobranca';

interface DadosValores {
    kmInicial: string;
    kmFinal: string;
    valor: string;
    taxaRetorno: string;
    tabelaCobrancaKmCalculadoID: string;
}

interface ModalEditarKmCalculadoProps {
    open: boolean;
    handleClose: () => void;
    dados?: DadosValores;
}

const ModalEditarKmCalculado = ({ open, handleClose, dados }: ModalEditarKmCalculadoProps) => {
    const hookForm = useForm<DadosValores>({
        defaultValues: {
            tabelaCobrancaKmCalculadoID: '',
            kmInicial: '',
            kmFinal: '',
            valor: '',
            taxaRetorno: '',
        },
    });
    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = hookForm;
    const dispatch = useDispatch();

    useEffect(() => {
        if (dados) {
            console.log(dados);
            setValue("tabelaCobrancaKmCalculadoID", dados.tabelaCobrancaKmCalculadoID);
            setValue("kmInicial", dados.kmInicial);
            setValue("kmFinal", dados.kmFinal);
            setValue("valor", dados.valor);
            setValue("taxaRetorno", dados.taxaRetorno);
        }
    }, [dados, setValue]);


    const onSubmit = (data: DadosValores) => {
        console.log(data);
        const cleanedData = {
            ...data,
            kmInicial: String(data.kmInicial || '').replace(".", ","),
            kmFinal: String(data.kmFinal || '').replace(".", ","),
            valor: cleanMoneyInput(replaceDecimalSeparator(data.valor)),
            taxaRetorno: cleanMoneyInput(replaceDecimalSeparator(data.taxaRetorno)),
            tabelaCobrancaKmCalculadoID: data.tabelaCobrancaKmCalculadoID,
        };
        dispatch(salvarTabelaPorKmStart(cleanedData));
        handleClose();
        reset();
    };

    useEffect(() => {
        if (dados) {
            reset(dados);
        } else {
            reset({
                tabelaCobrancaKmCalculadoID: '',
                kmInicial: '',
                kmFinal: '',
                valor: '',
                taxaRetorno: '',
            });
        }
    }, [dados, reset]);

    return (
        <ModalBase open={open} onClose={() => {
            handleClose()
        }} headerModal={{ title: "Valores por KM calculado" }} size='sm'>
            <Box p={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                        <TextFieldDistance
                            hookForm={{
                                methods: hookForm,
                                name: 'kmInicial',
                                defaultValue: watch('kmInicial'),
                                rules: { required: 'Obrigatório' },
                            }}
                            label='KM Inicial'
                        />

                        <TextFieldDistance
                            hookForm={{
                                methods: hookForm,
                                name: 'kmFinal',
                                defaultValue: watch('kmFinal'),
                                rules: { required: 'Obrigatório' },
                            }}
                            label='KM Final'
                        />
                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'valor',
                                defaultValue: watch('valor'),
                            }}
                            label='Valor'
                        />
                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'taxaRetorno',
                                defaultValue: watch('taxaRetorno'),
                            }}
                            label='Taxa de retorno'
                        />
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="contained" color="secondary"
                                onClick={() => {
                                    reset()
                                    handleClose()
                                }}
                                sx={{ width: 'fit-content' }}>
                                Cancelar
                            </Button>
                            <Button variant="containedFilled" color="primary" type="submit" sx={{ width: 'fit-content' }}>
                                Salvar
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </ModalBase>
    );
};

export default ModalEditarKmCalculado;
