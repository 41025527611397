import { useState } from "react";
import { Collapse, Typography, Link, useTheme, Stack, Tooltip, Grid2, ButtonGroup, Button, CircularProgress } from "@mui/material";
import { Pedido } from "src/dtos/PedidosDTO";
import { dinheiro, telefone, usePodeAtribuir } from "src/ds/common";
import { usePedido } from "src/context/PedidoContext";
import { ReportProblemOutlined, Streetview, WhatsApp } from "@mui/icons-material";
import { AvatarWithModal, BoxLine, GridColumn, ModalOpcoes, PLANO_FREEMIUM } from "src/ds";
import { useDispatch, useSelector } from "react-redux";
import { alterarSituacaoRequest } from "src/store/reducers/config";
import { RootState } from "src/store/reducers";
import { cancelarPedidoRequest, marcarFinalizadoRequest } from "src/store/reducers/criarPedido";
import { useSnackbarContext } from "src/context/SnackbarContext";
import { setAtualizarEmAndamento } from "src/store/reducers/utils";
import PrintIcon from '@mui/icons-material/Print';
import { api } from "src/services/api";

type CardBodyProps = {
    pedido: Pedido;
    collapsed: boolean;
    abrirAtribuirPedido: () => void;
    abrirCriarOcorrencia: () => void;
}

const CardContent = ({ pedido, collapsed, abrirAtribuirPedido, abrirCriarOcorrencia }: CardBodyProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { showSnackbar, showInfo, showWarning } = useSnackbarContext();
    const [cancelarOpen, setCancelarOpen] = useState(false);
    const podeCancelar = useSelector((state: RootState) => state.config.masterPage?.empresa?.podeCancelar);
    const [confirmarPagamento, setConfirmarPagamento] = useState(false);
    const error = useSelector((state: RootState) => state.criarPedido.error);
    const usuario = useSelector((state: any) => state.login?.usuario);
    const tipoDeUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario);
    const resposta = useSelector((state: any) => state.criarPedido.resposta);
    const usuarioPlano = useSelector((state: any) => state.config.usuarioPlano);
    const podeCancelarPedido = useSelector((state: any) => state.config?.masterPage?.empresa?.podeCancelar);
    const [loadingImprimir, setLoadingImprimir] = useState(false);

    const [modalState, setModalState] = useState<{ open: boolean; descricao: string; acao: string; callback?: () => void }>({
        open: false,
        descricao: "",
        acao: "",
    });
    const handleOpenModal = (descricao: string, acao: string, callback: () => void) => {
        if (usuarioPlano === PLANO_FREEMIUM) {
            abrirAtribuirPedido();
            return
        }
        setModalState({ open: true, descricao, acao, callback });
    };
    const handleCloseModal = () => {
        setModalState({ open: false, descricao: "", acao: "" });
    };
    const handleModalConfirm = () => {
        modalState.callback?.();
        dispatch(setAtualizarEmAndamento())
        handleCloseModal();
    };

    const { selecionarPedido } = usePedido();

    const handleCopyPedido = (e: any) => {
        selecionarPedido(pedido);
        navigator.clipboard.writeText(`${window.location.origin}/rastreio/${pedido.pedidoID}`);
        showInfo({
            message: 'Código de rastreio copiado para a área de transferência.',
            duration: 5000
        })
    }

    const abrirOcorrencia = () => {
        selecionarPedido(pedido);
        abrirCriarOcorrencia();
    }

    const opcoes = [
        {
            label: 'Cancelar',
            action: () => setCancelarOpen(false)
        },
        {
            label: 'Confirmar',
            action: () => {
                dispatch(cancelarPedidoRequest(pedido.pedidoID))
                setCancelarOpen(false)
                setTimeout(() => {
                    dispatch(setAtualizarEmAndamento())
                }, 1000)
            }
        },
    ];

    const opcoesRetornando = [
        {
            label: 'Cancelar',
            action: () => setConfirmarPagamento(false)
        },
        {
            label: 'Confirmar',
            action: () => {
                marcarFinalizado();
            }
        },
    ];

    const getProximoStatus = (situacao: string) => {
        switch (situacao) {
            case 'CRIADO':
                return 'Pronto';
            case 'PRONTO':
                return 'Despachar';
            case 'DESPACHADO':
                return 'ACEITO';
            case 'RECEBIDO':
                return 'Pronto';
            case 'ACEITO':
                return 'Na origem';
            case 'NO_ESTABELECIMENTO':
                return 'Pedido coletado';
            case 'EM_ROTA':
                return 'Finalizado';
            case 'RETORNANDO':
                return 'Finalizado';
            default:
                return 'ERRO';
        }
    };

    const abrirAtribuir = () => {
        selecionarPedido(pedido);
        abrirAtribuirPedido();
    }

    const pedidoPronto = (pedidoId: string) => {
        if (!pedidoId) return
        dispatch(alterarSituacaoRequest({ pedidoID: pedido?.pedidoID, situacao: 'PRONTO' }))
    }

    const marcarFinalizado = () => {
        dispatch(marcarFinalizadoRequest(pedido.pedidoID))
        setTimeout(() => {
            dispatch(setAtualizarEmAndamento())
        }, 1000)
        showSnackbar({
            message: error ? error : resposta?.mensagem,
            severity: error ? 'error' : 'success',
            duration: 5000
        })
    }
    const handleCancelarPedido = () => {
        dispatch(cancelarPedidoRequest(pedido.pedidoID));
    };

    const podeAtribuir = usePodeAtribuir();

    const handlePrint = async () => {
        setLoadingImprimir(true);

        // Faz a requisição para obter o HTML do pedido
        const response = await api.get(`/Pedido/Imprimir?pedidoID=${pedido.pedidoID}`);
        const htmlContent = response.data;

        // Cria um iframe oculto
        const iFrame = document.createElement('iframe');
        iFrame.style.display = 'none';
        document.body.appendChild(iFrame);

        // Adiciona o conteúdo ao iframe
        const doc = iFrame.contentDocument || iFrame.contentWindow?.document;
        if (doc) {
            doc.open();
            doc.write(htmlContent);
            doc.close();

            // Aguarda o carregamento do iframe
            iFrame.addEventListener('load', function () {
                iFrame.contentWindow?.focus();
                iFrame.contentWindow?.print();

                // Remove o iframe após a impressão
                setTimeout(() => {
                    document.body.removeChild(iFrame);
                }, 500);
            });
        }
        setLoadingImprimir(false);
    };


    return (
        < Collapse in={!collapsed}>
            <Grid2
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    mb: 1
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem', }}>
                    <Typography variant="body2" > Pagamento: {pedido?.formaPagamento?.nome} </Typography>
                    <Stack direction="row" >
                        <Typography variant="body2">
                            Telefone do cliente:
                        </Typography>
                        {pedido?.clienteTelefone?.startsWith("0800") ? (
                            <Typography variant="body2">{telefone(pedido?.clienteTelefone)}</Typography>
                        ) : (
                            <Link
                                sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: theme.palette.primary.main, gap: '.1rem', fontSize: theme.sizes.fontSize.sm }}
                                href={`https://api.whatsapp.com/send?phone=55${pedido?.clienteTelefone}`}
                                target="_blank"
                            >
                                <WhatsApp sx={{ fontSize: theme.sizes.fontSize.sm }} /> {telefone(pedido?.clienteTelefone)}
                            </Link>
                        )}
                    </Stack>
                    {
                        pedido?.taxaTotalCobrada !== 0
                            ? <Typography variant="body2" > Taxa cobrada: {dinheiro(pedido?.taxaTotalCobrada)} </Typography>
                            : null
                    }
                    {tipoDeUsuario !== 'SAAS' && <Link
                        sx={
                            {
                                p: 0,
                                fontSize: theme.sizes.fontSize.sm,
                                color: theme.palette.text.primary,
                                textDecoration: theme.palette.mode === 'dark' ? 'underline' : 'none',
                            }
                        }
                        onClick={abrirOcorrencia}
                    >
                        <ReportProblemOutlined sx={{ fontSize: '12px' }} /> Gerar ocorrência
                    </Link>}
                    <Stack direction="column" gap={1} alignItems="left" >
                        <Link
                            sx={{
                                p: 0,
                                fontSize: theme.sizes.fontSize.sm,
                                color: theme.palette.text.primary,
                                textDecoration: theme.palette.mode === 'dark' ? 'underline' : 'none',
                                position: 'relative',
                            }}
                            onClick={loadingImprimir ? undefined : handlePrint}
                        >
                            <span style={{ visibility: loadingImprimir ? 'hidden' : 'visible' }}><PrintIcon sx={{ fontSize: '12px' }} /> Imprimir</span>
                            <span style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: '100%',
                                display: loadingImprimir ? 'flex' : 'none',
                                cursor: 'not-allowed',
                                justifyContent: 'center'
                            }}><CircularProgress size={12} sx={{ fontSize: '12px' }} /></span>
                        </Link>
                        <Link
                            sx={{
                                p: 0,
                                fontSize: theme.sizes.fontSize.sm,
                                color: theme.palette.text.primary,
                                textDecoration: theme.palette.mode === 'dark' ? 'underline' : 'none',
                            }}
                            onClick={handleCopyPedido}
                        >
                            <Streetview sx={{ fontSize: '12px' }} /> Gerar código de rastreio para o cliente
                        </Link>
                    </Stack>
                    {pedido?.prioridade?.nome && <Typography variant="body2" >Prioridade: <strong>{pedido?.prioridade?.nome}</strong></Typography>}
                    <Typography variant="body2" >{pedido?.empresa?.nome}</Typography>
                </div>

                <GridColumn sx={{ mr: '.3rem' }}>
                    <AvatarWithModal
                        src={pedido?.entregadorUsuario?.perfilUrl || ''}
                        alt="Foto do Entregador"
                        size={60}
                        username={pedido?.entregadorUsuario?.nome}
                    />
                    <BoxLine sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Tooltip title={pedido?.entregadorUsuario?.nome || "N/A"}>
                            <Typography sx={{ textAlign: 'center', fontSize: '12px', padding: '0rem' }}>
                                {pedido?.entregadorUsuario?.nome
                                    ? (pedido.entregadorUsuario.nome.length > 20
                                        ? `${pedido.entregadorUsuario.nome.slice(0, 20)}...`
                                        : pedido.entregadorUsuario.nome)
                                    : null}
                            </Typography>
                        </Tooltip>
                    </BoxLine>
                    {
                        pedido?.entregadorUsuario?.telefone &&
                        <Link
                            sx={{ fontSize: '12px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}
                            href={`https://api.whatsapp.com/send?phone=55${pedido?.entregadorUsuario?.telefone}`
                            }
                            target="_blank"
                        >
                            <WhatsApp sx={{ fontSize: '12px' }} /> {telefone(pedido?.entregadorUsuario?.telefone)}
                        </Link>
                    }
                </GridColumn>
            </Grid2>
            <ButtonGroup
                //@ts-expect-error
                variant="containedFilled"
                sx={{
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: theme.sizes.borderRadius.sm,
                    '& .MuiButton-root:not(:last-child)': {
                        borderRight: `1px solid ${theme.palette.getContrastText(theme.palette.primary.main)}`,
                    },
                    '& .MuiButton-root': {
                        borderRadius: 0,
                        '&:hover': {
                            borderColor: theme.palette.primary.dark,
                            bgcolor: theme.palette.primary.dark,
                            color: theme.palette.primary.contrastText
                        }
                    },
                }}
            >
                {
                    (pedido?.situacao === 'RECEBIDO' || pedido?.situacao === 'PRONTO') && podeCancelar && podeCancelarPedido &&
                    <Button onClick={() =>
                        handleOpenModal(
                            "Tem certeza que deseja cancelar o pedido #" + pedido?.codigoQuatro + " de " + pedido?.clienteNome + "?",
                            "Cancelar pedido",
                            handleCancelarPedido
                        )
                    }> Cancelar </Button>
                }
                {
                    pedido?.situacao === 'NO_ESTABELECIMENTO' &&
                    <Button onClick={() => handleOpenModal(
                        `Confirma que o pedido #${pedido?.codigoQuatro} de ${pedido?.clienteNome} foi coletado?`,
                        "Confirmar coleta",
                        () => dispatch(
                            alterarSituacaoRequest({ pedidoID: pedido.pedidoID, situacao: "EM_ROTA" }
                            ))
                    )}> Coletado </Button>
                }
                {
                    pedido?.situacao === 'RECEBIDO' &&
                    <Button onClick={() =>
                        handleOpenModal(
                            `Confirma a troca de situação para ${getProximoStatus(pedido?.situacao)} no pedido #${pedido?.codigoQuatro} de ${pedido?.clienteNome}?`,
                            "Confirmar",
                            () => dispatch(
                                alterarSituacaoRequest({ pedidoID: pedido.pedidoID, situacao: "PRONTO" }
                                ))
                        )

                    }> {getProximoStatus(pedido?.situacao)} </Button>
                }
                {
                    ((pedido?.situacao === 'RECEBIDO' || pedido?.situacao === 'PRONTO') && tipoDeUsuario !== "REPRESENTANTE" && podeAtribuir) &&
                    <Button onClick={abrirAtribuir}> Atribuir </Button>
                }
                {
                    (pedido?.situacao !== 'RECEBIDO' && pedido?.situacao !== 'PRONTO' && pedido?.situacao !== 'EM_ROTA' && pedido?.situacao !== 'RETORNANDO') && podeAtribuir &&
                    <Button onClick={abrirAtribuir}> Reatribuir </Button>
                }
                {false &&
                    <Button onClick={() => handleOpenModal(
                        "Confirma que o pedido #" + pedido?.codigoQuatro + " de " + pedido?.clienteNome + " foi entregue?",
                        "Confirmar entrega",
                        () => dispatch(
                            alterarSituacaoRequest({ pedidoID: pedido.pedidoID, situacao: "FINALIZADO" }
                            ))
                    )
                    }> Entregue </Button>
                }
                {pedido?.situacao === 'RETORNANDO' &&
                    <Button onClick={() => setConfirmarPagamento(true)}> Pagamento entregue </Button>
                }
            </ButtonGroup>
            <ModalOpcoes
                open={modalState.open}
                onClose={handleCloseModal}
                descricao={modalState.descricao}
                acao={modalState.acao}
                opcoes={[
                    { label: "Cancelar", action: handleCloseModal },
                    { label: "Confirmar", action: handleModalConfirm },
                ]}
            />
            < ModalOpcoes
                open={cancelarOpen}
                onClose={() => setCancelarOpen(false)}
                descricao="Tem certeza que deseja cancelar este pedido?"
                acao="Cancelar pedido"
                opcoes={opcoes}

            />
            <ModalOpcoes
                open={confirmarPagamento}
                onClose={() => setConfirmarPagamento(false)}
                descricao={`Você confirma que recebeu o valor ${dinheiro(pedido?.valor)} do entregador ${pedido?.entregadorUsuarioNome}?`}
                acao="Confirmar pedido"
                opcoes={opcoesRetornando}
            />
        </Collapse>
    )
};

export default CardContent;
