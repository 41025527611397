import { Chip, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { maskMoney, ModalOpcoes, TableRowLoading, TableRowNotFound, TableRowOrdenacao } from 'src/ds';
import { RootState } from 'src/store/reducers';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import BusinessIcon from '@mui/icons-material/Business';
import { vagaPadraoExcluirClear, vagaPadraoExcluirStart } from 'src/store/reducers/escalas';
import ModalHandleVagaPadrao from './ModalHandleVagaPadrao';
import { VagaPadrao } from 'src/dtos/EscalasDTO';
import ModalEmpresasVinculadas from './ModalEmpresasVinculadas';

interface TabelaVagaPadraoProps {
    onSubmit?: () => void;
    callback: () => void;
    hookForm: UseFormReturn<any>;
    fezRequisicao?: boolean
}

const TabelaVagaPadrao: React.FC<TabelaVagaPadraoProps> = ({ onSubmit, hookForm, fezRequisicao, callback }) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext()
    const vagaPadrao = useSelector((state: RootState) => state.escalas.vagaPadrao);
    const loading = useSelector((state: RootState) => state.escalas.vagaPadrao.loading);

    const vagaPadraoExcluir = useSelector((state: RootState) => state.escalas.vagaPadraoExcluir);

    const [vagaPadraoSelecionada, setVagaPadraoSelecionada] = useState<{
        tipo: 'editar' | 'excluir' | 'atribuir',
        id: string,
        nome: string;
        modelo?: VagaPadrao
    } | null>(null);

    function handleConfirmarExcluir(id: string) {
        dispatch(vagaPadraoExcluirStart({ id }));
    }

    useEffect(() => {
        if (vagaPadraoExcluir.data) {
            showSuccess({
                message: vagaPadraoExcluir.data.mensagem,
                duration: 5000
            });
            callback();
            setVagaPadraoSelecionada(null);
        } else if (vagaPadraoExcluir.error) {
            showError({
                message: vagaPadraoExcluir.error,
                duration: 5000
            });
        }
        return () => {
            dispatch(vagaPadraoExcluirClear());
        }
    }, [vagaPadraoExcluir.data, vagaPadraoExcluir.error])

    useEffect(() => {
        if (vagaPadrao.error) {
            showError({
                message: vagaPadrao.error,
                duration: 5000
            });
        }
    }, [vagaPadrao.error])

    const toggleSortDirection = (column: string, direction: string) => {
        hookForm.setValue('ordenacaoOrdem', direction);
        hookForm.setValue('ordenacaoCampo', column);
        onSubmit && onSubmit();
    };

    const cells = React.useMemo(() => [
        { label: 'Situação' },
        { label: 'Nome', name: 'nome' },
        { label: 'Tipo de veículo', name: 'tipoVeiculo.nome' },
        { label: 'Hora de início', name: 'horaInicio' },
        { label: 'Hora de fim', name: 'horaFim' },
        { label: 'Taxa total cobrada' },
        { label: 'Taxa total entregador' },
        { label: 'Quantidade pedidos mínimo garantido' },
        { label: 'Empresas vinculadas' },
        { label: 'Ações' },
    ], []);

    return (
        <>
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                }}
            >
                <Table>
                    <TableHead>
                        <TableRowOrdenacao
                            onSortChange={toggleSortDirection}
                            cells={cells}
                        />
                    </TableHead>
                    <TableBody>
                        {loading
                            ? <TableRowLoading />
                            : vagaPadrao?.data?.dados?.lista && vagaPadrao?.data?.dados?.lista.length > 0
                                ? vagaPadrao?.data?.dados?.lista.map(item => (
                                    <TableRow>
                                        <TableCell align='center'>
                                            <Chip
                                                label={item.ativo ? 'Ativo' : 'Inativo'}
                                                color={item.ativo ? 'success' : 'error'}
                                            />
                                        </TableCell>
                                        <TableCell align='center'>
                                            {item.nome || '-'}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {item.tipoVeiculo?.nome}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {item.horaInicio}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {item.horaFim}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {item.taxaTotalCobrada // Aqui pode vir como 0, o que é falsy mas não é null
                                                ? maskMoney(item.taxaTotalCobrada)
                                                : item.taxaMinimaGarantidaCobrada
                                                    ? maskMoney(item.taxaMinimaGarantidaCobrada) + ' (Mínimo garantido)'
                                                    : item.taxaTotalCobrada != null
                                                        ? maskMoney(item.taxaTotalCobrada)
                                                        : '-' //Apenas para garantir que não seja exibido 'null'
                                            }
                                        </TableCell>
                                        <TableCell align='center'>
                                            {item.taxaTotalEntregador
                                                ? maskMoney(item.taxaTotalEntregador)
                                                : item.taxaMinimaGarantidaEntregador
                                                    ? maskMoney(item.taxaMinimaGarantidaEntregador) + ' (Mínimo garantido)'
                                                    : item.taxaTotalEntregador != null
                                                        ? maskMoney(item.taxaTotalEntregador)
                                                        : '-'}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {item.qtdPedidosMinimoGarantidoCobrado}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {item.empresasVinculadas?.length > 0 ? item.empresasVinculadas?.map((empresa) => empresa.nome).join(', ') : '-'}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Stack direction='row' justifyContent={'center'}>
                                                <Tooltip title='Vincular empresas' arrow placement='top'>
                                                    <IconButton
                                                        onClick={() => setVagaPadraoSelecionada({ tipo: 'atribuir', id: item.vagaPadraoID, nome: item.nome })}
                                                        size='small'
                                                        color='success'
                                                    >
                                                        <BusinessIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Editar' arrow placement='top'>
                                                    <IconButton
                                                        onClick={() => setVagaPadraoSelecionada({ tipo: 'editar', id: item.vagaPadraoID, nome: item.nome, modelo: item })}
                                                        size='small'
                                                        color='primary'
                                                    >
                                                        <EditRoundedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Excluir' arrow placement='top'>
                                                    <IconButton
                                                        size='small'
                                                        color='error'
                                                        onClick={() => setVagaPadraoSelecionada({ tipo: 'excluir', id: item.vagaPadraoID, nome: item.nome })}
                                                    >
                                                        <DeleteRoundedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))
                                : (
                                    <TableRowNotFound fezRequisicao={fezRequisicao} />
                                )}
                    </TableBody>
                </Table>
            </TableContainer>
            {vagaPadraoSelecionada?.tipo === 'excluir' && (
                <ModalOpcoes
                    acao='Excluir vaga padrão'
                    descricao={<Typography>Tem certeza que deseja excluir a vaga padrão <strong>{vagaPadraoSelecionada.nome}</strong>?</Typography>}
                    open={!!vagaPadraoSelecionada?.tipo}
                    onClose={() => setVagaPadraoSelecionada(null)}
                    opcoes={[
                        {
                            label: 'Cancelar',
                            action: () => setVagaPadraoSelecionada(null),
                        },
                        {
                            label: 'Confirmar',
                            action: () => handleConfirmarExcluir(vagaPadraoSelecionada.id),
                            loading: vagaPadraoExcluir.loading,
                        },
                    ]}
                />
            )}
            {(vagaPadraoSelecionada?.tipo === 'editar' && vagaPadraoSelecionada?.modelo) && (
                <ModalHandleVagaPadrao
                    callback={callback}
                    onClose={() => setVagaPadraoSelecionada(null)}
                    open={!!vagaPadraoSelecionada?.tipo}
                    modelo={vagaPadraoSelecionada.modelo}
                />
            )}

            {(vagaPadraoSelecionada?.tipo === 'atribuir' && vagaPadraoSelecionada.id) && (
                <ModalEmpresasVinculadas
                    callback={callback}
                    onClose={() => setVagaPadraoSelecionada(null)}
                    open={!!vagaPadraoSelecionada?.tipo}
                    vagaPadraoID={vagaPadraoSelecionada.id}
                />
            )}
        </>
    )
}

export default TabelaVagaPadrao
