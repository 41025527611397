import { createSlice } from "@reduxjs/toolkit"
import { desatribuir } from "../sagas/criarPedido";

const initialState = {
    loading: false,
    error: null,
    modelo: {},
    resposta: null,
    valores: null,
    respostaDespacho: {
        loading: false,
        error: null,
        data: null
    },
    atribuir: {
        loading: false,
        error: null,
        data: null,
    },
    desatribuir: {
        loading: false,
        error: null,
        data: null
    },
    ocorrencia: {
        loading: false,
        error: null,
        data: null,
    },
    cancelar: {
        loading: false,
        error: null,
        data: null
    },
    fazerPedido: {
        loading: false,
        error: null,
        data: null
    },
    limparRota: false,
    loadingValores: false
}

export const criarPedidoSlice = createSlice({
    name: 'criarPedido',
    initialState: initialState,
    reducers: {
        buscarPorCodigo: (state, action) => {
            state.loading = true;
            const { empresaID, codigo } = action.payload
        },
        buscarPorCodigoSuccess: (state, action) => {
            state.modelo = { ...action.payload }
            state.loading = false;
        },
        buscarPorCodigoFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        limparModelo: (state, action) => {
            state.modelo = {};
        },
        buscarPorTelefone: (state, action) => {
            const { empresaID, telefone } = action.payload
            state.loading = true;
        },
        buscarPorTelefoneSuccess: (state, action) => {
            state.loading = false;
            state.modelo = {
                ...state.modelo,
                ...action.payload
            }
        },
        buscarPorTelefoneFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        fazerPedido: (state, action) => {
            const form = action.payload;
            state.fazerPedido.loading = true;
            state.fazerPedido.error = null
            state.fazerPedido.resposta = null
        },
        fazerPedidoSuccess: (state, action) => {
            state.fazerPedido.loading = false;
            state.fazerPedido.resposta = action.payload
        },
        fazerPedidoFailure: (state, action) => {
            state.fazerPedido.loading = false;
            state.fazerPedido.error = action.payload;
        },
        calcularValores: (state, action) => {
            state.loadingValores = true;
            state.error = null
            state.resposta = null
        },
        calcularValoresSuccess: (state, action) => {
            state.loadingValores = false;
            state.valores = action.payload
        },
        calcularValoresFailure: (state, action) => {
            state.loadingValores = false;
            state.error = action.payload;
        },
        cancelarPedidoRequest: (state, action) => {
            state.cancelar.loading = true;
            state.cancelar.data = null;
        },
        cancelarPedidoSuccess: (state, action) => {
            state.cancelar.loading = false;
            state.cancelar.data = action.payload
        },
        cancelarPedidoError: (state, action) => {
            state.cancelar.loading = false;
            state.cancelar.error = action.payload;
        },
        marcarFinalizadoRequest: (state, action) => {
            const pedidoID = action.payload;
            state.loading = true
            state.resposta = null
            state.error = null
        },
        marcarFinalizadoSuccess: (state, action) => {
            state.loading = false
            state.resposta = action.payload
        },
        marcarFinalizadoFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        limparMensagens: (state, action) => {
            state.resposta = null
            state.error = null
        },
        desatribuirRequest: (state, action) => {
            state.desatribuir.loading = true
            state.desatribuir.resposta = null
            state.desatribuir.error = null
            const pedidoID = action.payload
        },
        desatribuirSuccess: (state, action) => {
            state.desatribuir.loading = false
            state.desatribuir.resposta = action.payload
        },
        desatribuirFailure: (state, action) => {
            state.desatribuir.loading = false
            state.desatribuir.error = action.payload
        },
        despacharRequest: (state, action) => {
            state.atribuir.loading = true;
            state.atribuir.data = null;
            state.atribuir.error = null;
        },
        despacharSuccess: (state, action) => {
            state.limparRota = true
            state.atribuir.loading = false
            state.atribuir.data = action.payload
            state.atribuir.error = null
        },
        despacharFailure: (state, action) => {
            state.limparRota = true
            state.atribuir.loading = false;
            state.atribuir.data = null;
            state.atribuir.error = action.payload;
        },
        limparDespachar: (state) => {
            state.atribuir = { ...initialState.atribuir }
        },
        resetRota: (state) => {
            state.limparRota = false
        },
        limparCriarPedido: (state, action) => {
            state.loading = false
            state.error = null
            state.modelo = {}
            state.respostaDespacho = null
            state.valores = null
            state.resposta = null
            state.fazerPedido = { ...initialState.fazerPedido }
        },
        registrarOcorrencia: (state, action) => {
            state.ocorrencia.loading = true;
            state.ocorrencia.data = null;
            state.ocorrencia.error = null;
        },
        registrarOcorrenciaSuccess: (state, action) => {
            state.ocorrencia.loading = false;
            state.ocorrencia.data = action.payload;
            state.ocorrencia.error = null;
        },
        registrarOcorrenciaFailure: (state, action) => {
            state.ocorrencia.loading = false;
            state.ocorrencia.data = null;
            state.ocorrencia.error = action.payload;
        },
        registrarOcorrenciaClean: (state) => {
            state.ocorrencia = { ...initialState.ocorrencia }
        },
        limparDesatribuir: (state) => {
            state.desatribuir = { ...initialState.desatribuir }
        },
    }
})

export const {
    buscarPorCodigo,
    buscarPorCodigoSuccess,
    buscarPorCodigoFailure,
    limparModelo,
    buscarPorTelefone,
    buscarPorTelefoneSuccess,
    buscarPorTelefoneFailure,
    fazerPedido,
    fazerPedidoSuccess,
    fazerPedidoFailure,
    calcularValores,
    calcularValoresSuccess,
    calcularValoresFailure,
    cancelarPedidoRequest,
    cancelarPedidoSuccess,
    cancelarPedidoError,
    marcarFinalizadoRequest,
    marcarFinalizadoSuccess,
    marcarFinalizadoFailure,
    limparMensagens,
    desatribuirRequest,
    desatribuirSuccess,
    desatribuirFailure,
    limparDespachar,
    despacharRequest,
    despacharSuccess,
    despacharFailure,
    limparCriarPedido,
    registrarOcorrencia,
    registrarOcorrenciaSuccess,
    registrarOcorrenciaFailure,
    registrarOcorrenciaClean,
    resetRota,
    limparDesatribuir
} = criarPedidoSlice.actions;
export default criarPedidoSlice;