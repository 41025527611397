import { Box, Card, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { BoxTableMinHeight, ListCardTabelaTotal, MenuTabelas, TableNavigation, formatTime } from "src/ds";
import { CardFormFiltros, CardFormFiltrosRef, Field } from "src/ds/components/CardFormFiltros";
import { RootState } from "src/store/reducers";
import timer from "../../assets/icons/time.svg";
import motorcycle from "../../assets/icons/motorcycle.svg";
import bag from "../../assets/icons/bag.svg";
import TabelaPerformance from "src/components/PerformanceEntregador/TabelaPerformance";
import { listaEntregadoresStart } from "src/store/reducers/entregador";
import formatTimeFromMinutes from "src/ds/utils/formatMinutes";

const PerformanceEntregador = () => {
    const cardRef = useRef<CardFormFiltrosRef>(null);
    const theme = useTheme();
    const data = useSelector((state: RootState) => state.entregadores.listarPerformanceEntregador.data?.dados);
    const exportarPerformanceEntregadores = useSelector((state: RootState) => state.entregadores.exportarPerformanceEntregador.data);
    const loading = useSelector((state: RootState) => state.entregadores.listarPerformanceEntregador.loading)
    const loadingExportar = useSelector((state: RootState) => state.entregadores.exportarPerformanceEntregador.loading)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listaEntregadoresStart({
            pagina: 1,
            paginacao: -1,
            filtros: ``,
        }))
    }, [])

    const formFields: Field[] = [
        { label: 'Entregador', name: 'usuarioID', type: 'entregador' },
        { label: 'Data inicial', name: 'dtInicial', type: 'dateTime', rules: { required: 'Data inicial é obrigatória' } },
        { label: 'Data final', name: 'dtFinal', type: 'dateTime', rules: { required: 'Data final é obrigatória' } },
    ]

    const hookForm = useForm({
        defaultValues: {
            dtInicial: dayjs().subtract(1, 'day').format('DD/MM/YYYY 00:00'),
            dtFinal: dayjs().format('DD/MM/YYYY 00:00'),
            usuarioID: '',
        },
    })

    const menuOptions = [{
        label: 'Exportar',
        action: () => {
            dispatch(exportarPerformanceEntregadores(hookForm.getValues()))
        }
    }]

    return (
        <Box sx={{ p: 2, backgroundColor: theme.palette.background.paperDark }}>
            <CardFormFiltros
                ref={cardRef}
                fields={formFields}
                formMethods={hookForm}
                dispatchMethods={{ actionName: 'listarPerformanceEntregadorStart' }}
                loading={loading}
            />
            <ListCardTabelaTotal
                cardData={[
                    {
                        srcIcon: bag,
                        altIcon: "Ícone quantidade total pedidos",
                        bgcolor: theme.palette.blue[600],
                        texts: {
                            title1: "QUANTIDADE TOTAL DE PEDIDOS",
                            subtitle1: data?.totalPedidos ? data?.totalPedidos : "0",
                        },
                    },
                    {
                        srcIcon: timer,
                        altIcon: "Ícone quantidade total tempo médio",
                        bgcolor: theme.palette.grey[600],
                        texts: {
                            title1: "QUANTIDADE TOTAL TEMPO MÉDIO",
                            subtitle1: data?.mediaTempoOnline ? formatTimeFromMinutes(data?.mediaTempoOnline) : "0",
                        },
                    },
                    {
                        srcIcon: motorcycle,
                        altIcon: "Quantidade total entregadores",
                        bgcolor: theme.palette.red[600],
                        texts: {
                            title1: "QUANTIDADE TOTAL ENTREGADORES",
                            subtitle1: data?.totalEntregadores ?? "0",
                        },
                    },
                ]}
            />
            <Card elevation={1} sx={{ mt: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, alignItems: "center" }}>
                    {data?.ultimaPagina > 0 && (
                        <>
                            <TableNavigation
                                indiceInicial={data?.indiceInicial}
                                indiceFinal={data?.indiceFinal}
                                indiceTotal={data?.total}
                                ultimaPagina={data?.ultimaPagina}
                                hookForm={hookForm}
                                podeTodos={false}
                            />
                            <MenuTabelas options={menuOptions} loading={loadingExportar} />
                        </>
                    )}
                </Box>
                <BoxTableMinHeight>
                    <TabelaPerformance
                        data={data?.lista}
                        loading={loading}
                        hookForm={hookForm}
                    />
                </BoxTableMinHeight>
            </Card>
        </Box>
    );
};

export default PerformanceEntregador;
