import React, { useEffect, useState } from 'react';
import BarraDePesquisa from './BarraDePesquisa';
import ListaVazia from './ListaVazia';
import MenuFooter from '../MenuFooter';
import ItemListaPedidos from './ItemListaPedidos';
import { Box, Button, Chip, Container, IconButton, Stack, Typography } from '@mui/material';
import { ContainerLoading, GridPainelLateral, ModalOpcoes } from 'src/ds';
import { usePedido } from 'src/context/PedidoContext';
import { Pedido } from 'src/dtos/PedidosDTO';
import { useDispatch, useSelector } from 'react-redux';
import { marcarFinalizadoRequest } from 'src/store/reducers/criarPedido';
import { emAndamentoStart } from 'src/store/reducers/config';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { RootState } from 'src/store/reducers';
import { finalizarVariosStart, limparFinalizarVarios } from 'src/store/reducers/pedidos';
import { AddCircleOutline, GifBox } from '@mui/icons-material';
import { FaGift } from 'react-icons/fa';
import { setAtualizarEmAndamento } from 'src/store/reducers/utils';
import MapIcon from '@mui/icons-material/Map';
import { usePodeAtribuir } from 'src/ds/common';



interface ListaPedidosProps {
    groupedPedidos: Record<string, any[]>;
    empresas: string[];
    expandedGroups: Record<string, boolean>;
    toggleGroup: (groupName: string) => void;
    abrir: (action: string, item?: any) => void;
    loadingXpick: boolean;
    showEmAndamento: boolean;
    searchTerm: string;
    handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    filtrosValidos: number;
    abrirMapa: (abrirMapa: boolean) => void;
    isMobile: boolean;
    filteredPedidos: any[];
    mensagensNaoLidas: number;
    hasMultipleEmpresas: boolean;
}

const ListaPedidos: React.FC<ListaPedidosProps> = ({
    groupedPedidos,
    empresas,
    expandedGroups,
    toggleGroup,
    abrir,
    loadingXpick,
    showEmAndamento,
    searchTerm,
    handleSearchChange,
    filtrosValidos,
    abrirMapa,
    isMobile,
    filteredPedidos,
    mensagensNaoLidas,
    hasMultipleEmpresas
}) => {
    const { novaListaPedidos, limparNovaLista, adicionandoPedidos } = usePedido()
    const { showSnackbar } = useSnackbarContext()
    const dispatch = useDispatch()
    const usuario = useSelector((state: any) => state.login.usuario)
    const resposta = useSelector((state: any) => state.pedidos?.finalizarVarios?.data);
    const [finalizarOpen, setFinalizarOpen] = useState(false);
    const podeAtribuir = usePodeAtribuir();

    const handleFinalizar = () => {
        if (novaListaPedidos?.length > 0) {
            const pedidos = novaListaPedidos.map((item: Pedido) => {
                return item.pedidoID
            })

            dispatch(finalizarVariosStart(pedidos))


            setTimeout(() => {
                dispatch(setAtualizarEmAndamento())
            }, 1000)

            limparNovaLista()
            setFinalizarOpen(false)
        }
    }

    useEffect(() => {
        if (resposta?.sucesso) {
            showSnackbar({ message: resposta.mensagem, severity: 'success', duration: 5000 })
        } else if (resposta?.sucesso === false) {
            showSnackbar({ message: resposta.mensagem, severity: 'error', duration: 5000 })
        }
        setTimeout(() => {
            dispatch(limparFinalizarVarios())
        }, 1000)
    }, [resposta])

    const opcoes = [
        {
            label: 'Cancelar',
            action: () => setFinalizarOpen(false)
        },
        {
            label: 'Confirmar',
            action: () => {
                handleFinalizar()
            }
        },
    ];

    return (
        <GridPainelLateral container sx={{ minHeight: showEmAndamento ? '100%' : '0' }}>
            <BarraDePesquisa
                searchTerm={searchTerm}
                onSearchChange={handleSearchChange}
                filtrosValidos={filtrosValidos}
                onFilterClick={() => abrir('addFiltros')}
            />
            {adicionandoPedidos &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.1rem', width: '98%' }}>
                    <Stack direction="row" alignItems="center" width={"100%"} justifyContent={novaListaPedidos?.length > 0 ? 'normal' : 'center'} flexWrap={'wrap'} gap={1}>
                        <Typography variant="body2">
                            {novaListaPedidos?.length > 0 ? <strong>Selecionados:</strong> : <Typography color='grey' variant="inherit" sx={{ display: 'flex', alignItems: 'center', gap: '.2rem', justifyContent: 'center', }} ><AddCircleOutline /> Selecione um pedido</Typography>}
                        </Typography>
                        {novaListaPedidos && novaListaPedidos?.map((item: Pedido) => (
                            <Chip
                                key={item.pedidoID}
                                label={`#${item.codigoQuatro}`}
                                sx={{ margin: '0 4px', borderRadius: '4px' }}
                            />
                        ))}
                    </Stack>
                    {novaListaPedidos?.length > 0 &&
                        <Stack direction="row" spacing={1} alignItems={'flex-start'}>
                            {podeAtribuir &&
                                novaListaPedidos.every((pedido: Pedido) => pedido.situacao === 'PRONTO' || pedido.situacao === 'RECEBIDO' || pedido.situacao === 'PRONTO') && (
                                    <Button variant="containedFilled" onClick={() => abrir('atribuirPedido')}>
                                        Atribuir
                                    </Button>
                                )
                            }
                            <Button variant="contained" onClick={() => setFinalizarOpen(true)}>Finalizar</Button>
                        </Stack>}
                </Box>
            }
            {
                isMobile &&
                /* @ts-ignore */
                <IconButton
                    variant="containedFilled"
                    color="primary"
                    size="small"
                    sx={{ width: '96%', borderRadius: '6px', gap: 2 }}
                    onClick={() => abrir('abrirMapa', true)}>
                    Ver mapa
                    <MapIcon />
                </IconButton>
            }

            <Container
                sx={{
                    flexGrow: '1',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    '&.MuiContainer-root': {
                        padding: '.1rem 0 0 0',
                    },
                }}
            >
                {loadingXpick ? (
                    <ContainerLoading />
                ) : filteredPedidos?.length > 0 ? (
                    <ItemListaPedidos
                        groupedPedidos={groupedPedidos}
                        empresas={empresas}
                        expandedGroups={expandedGroups}
                        toggleGroup={toggleGroup}
                        abrir={abrir}
                        hasMultipleEmpresas={hasMultipleEmpresas}
                    />
                ) : (
                    <ListaVazia onCreatePedido={() => abrir('criarPedido', true)} />
                )}
            </Container>
            <MenuFooter
                abrirChat={() => abrir('abrirChatLateral')}
                abrirAvaliar={() => abrir('abrirAvaliar')}
                abrirTempoMedio={() => abrir('abrirTempoMedio')}
                abrirFinanceiro={() => abrir('abrirFinanceiro')}
                abrirRobo={() => abrir('abrirRobo')}
                abrirTaxaExtra={() => abrir('abrirTaxaExtra')}
                abrirFreemium={() => abrir('abrirFreemium')}
                msgsNaoLidas={mensagensNaoLidas}
            />
            <ModalOpcoes
                open={finalizarOpen}
                onClose={() => setFinalizarOpen(false)}
                descricao={
                    <Typography variant="body1">
                        Tem certeza que deseja finalizar os pedidos{' '}
                        <Typography component="span" fontWeight="bold" sx={{ display: 'inline' }}>
                            {novaListaPedidos?.map((item: Pedido) => `#${item?.codigoQuatro}`).join(', ')}
                        </Typography>?
                    </Typography>}
                acao="Finalizar pedidos"
                opcoes={opcoes}

            />
        </GridPainelLateral >
    )
};

export default ListaPedidos;
