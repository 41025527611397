import React, { useCallback, useEffect, useRef, useState } from 'react';
import mapboxgl, { Projection } from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import { cancelarPedidoRequest, despacharRequest, marcarFinalizadoRequest, resetRota } from '../../../store/reducers/criarPedido';
import { usePedido } from '../../../context/PedidoContext';
import './styles.css';
import { Box, Button, CircularProgress, Collapse, IconButton, styled, useTheme } from '@mui/material';
import { Route } from '@mui/icons-material';
import { telefone } from '../../../ds/common';
import { CloseOutlined } from "@mui/icons-material";
import ModalCalculos from '../ModalCalculos';
import { BoxLoading, createMarkerEntregadorElement, createMarkerPedidoElement, ModalOpcoes, sanitizeSituacao, useResponsive } from '../../../ds';
import ModalPesquisarEntregador from 'src/components/common/ModalPesquisarEntregador';
import { RootState } from 'src/store/reducers';
import OcorrenciaPorEntregador from '../CriarOcorrencia/OcorrenciaPorEntregador';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { Pedido } from 'src/dtos/PedidosDTO';
import { Entregador } from 'src/dtos/EntregadoresDTO';
import { Empresa } from 'src/dtos/EmpresasDTO';
import ModalSelectEntregador from '../ModalSelecionarEntregador';

const MapContainer = styled('div')`
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
`;

interface MapHomeProps {
  abrirTrocarStatus: () => void;
  abrirAtribuirPedido: () => void;
  abrirChatLateral: () => void;
  styleProps?: React.CSSProperties | null;
}

interface CustomMarker extends mapboxgl.Marker {
  pedido?: Pedido;
  entregador?: Entregador;
  empresa?: Empresa;
  popup?: mapboxgl.Popup;
}

interface CustomMap extends mapboxgl.Map {
  markers?: CustomMarker[];
}


function MapHome({
  abrirTrocarStatus,
  abrirAtribuirPedido,
  abrirChatLateral,
  styleProps = null
}: MapHomeProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isMobile } = useResponsive();
  const { showSuccess, showError } = useSnackbarContext();
  const { selecionarPedido, selecionarMarker, selecionarMarkers, cardPedidoSelecionado, rotaAtual, selecionarRota, roteando, iniciarRota, pararRota, novaListaPedidos, adicionandoPedidos, limparNovaLista, selecionarCardPedido, removerPedidoDaLista, adicionarPedidoNaLista } = usePedido();
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const infoboxContainerRef = useRef(null);
  const mapRef = useRef<CustomMap | null>(null);
  const pickSelecionada = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada);
  const [currentCenter, setCurrentCenter] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [selectedSituacao, setSelectedSituacao] = useState('ALL');
  const [selectedEntregador, setSelectedEntregador] = useState('ALL');
  const [openEntregadorModal, setOpenEntregadorModal] = useState(false);
  const [openEntregadorModal1, setOpenEntregadorModal1] = useState(false);
  const [cancelarOpen, setCancelarOpen] = useState(false);
  const [pedidoID, setPedidoID] = useState<string | null>(null);
  const [selectedPedidos, setSelectedPedidos] = useState<Pedido[]>([]);
  const [selectedPedido, setSelectedPedido] = useState<Pedido | null>(null);
  const [isRouting, setIsRouting] = useState(false);
  const [showExtraButtons, setShowExtraButtons] = useState(false);
  const [openCalculos, setOpenCalculos] = useState(false);
  const [dadosOcorrencia, setDadosOcorrencia] = useState({});
  const [openOcorrencia, setOpenOcorrencia] = useState(false);
  const entregadores = useSelector((state: RootState) => state.config.entregadores);
  const emAndamento = useSelector((state: RootState) => state.config?.emAndamento?.dados?.lista);
  const empresas = useSelector((state: RootState) => state.config?.empresasPick?.dados?.lista);
  const filtros = useSelector((state: RootState) => state.utils.filtros);
  const colorMode = useSelector((state: RootState) => state.utils.colorMode);
  const podeAtribuirRota = useSelector((state: RootState) => state.config.masterPage?.empresa?.podeGerarRota);
  const podeKmTotal = useSelector((state: RootState) => state.config.masterPage?.empresa?.rotaCobrarKmTotal);
  const podeEntrePontos1 = useSelector((state: RootState) => state.config.masterPage?.empresa?.rotaCobrarEntrePontos);
  const limparRota = useSelector((state: RootState) => state.criarPedido?.limparRota);
  const tipoDeUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario);
  const template = useSelector((state: RootState) => state.config?.template);
  const empresa = useSelector((state: RootState) => state.config?.masterPage?.empresa);
  const [payloadzin, setPayloadzin] = useState({});
  const podeRota = false;
  const [styleLoading, setStyleLoading] = useState(true);

  const podeRotear1 = useSelector((state: RootState) => state.config.masterPage?.empresa?.podeRoterizarEntregador);
  const podeRotear = tipoDeUsuario === 'SAAS' ? true : podeRotear1;
  const podeEntrePontos = podeKmTotal ? false : podeEntrePontos1;

  const toggleExtraButtons = () => {
    setShowExtraButtons(prevState => !prevState);
    setIsRouting(prevState => {
      const newRoutingState = !prevState;
      if (!newRoutingState) {
        setTimeout(() => {
          if (mapRef.current && mapRef.current.getSource('route')) {
            mapRef.current.removeLayer('route');
            mapRef.current.removeSource('route');
            setSelectedPedidos([]);
          }
        }, 0);
      }

      return newRoutingState;
    });
  };



  useEffect(() => {
    if (isRouting) {
      iniciarRota();
    } else {
      pararRota();
    }
  }, [isRouting])


  const handleSetStyleMap = () => {
    setStyleLoading(true);
    if (colorMode === 'light') {
      mapRef.current?.setStyle(template?.mapa || 'mapbox://styles/pickngo-demo/clabx63al002z14qpcdwwsq40');
    } else if (colorMode === 'dark') {
      mapRef.current?.setStyle('mapbox://styles/pickngo-demo/clkbehgyk03kl01ns9k0t2o39');
    }
  }

  const flyToMarker = (markerID: string) => {
    if (isRouting) return
    // @ts-expect-error
    const marker = mapRef.current?.markers?.filter(marker => marker._element.id === markerID);
    if (!marker) return
    mapRef.current?.flyTo({
      center: marker[0]?.getLngLat(),
      speed: 1.5,
      curve: 1.5,
      essential: true
    }
    );
    setTimeout(() => {
      marker[0]?.togglePopup();
    }, 500);
    marker[0]?.togglePopup();
    console.log('flyToMarker', markerID);
  }

  const limparRotas = () => {
    if (mapRef.current && mapRef.current.getSource('route')) {
      mapRef.current.removeLayer('route');
      mapRef.current.removeSource('route');
      setSelectedPedidos([]);
    }
  }
  const [mostarDashed, setMostarDashed] = useState(false);
  useEffect(() => {
    setMostarDashed(novaListaPedidos.some((p: Pedido) => p.pedidoID === selectedPedido?.pedidoID));
  }, [novaListaPedidos, selectedPedido?.pedidoID]);

  let pedidosParaRota: Pedido[] = [];

  const handlePedidoClick = (pedido: Pedido) => {
    if (!adicionandoPedidos) {
      selecionarCardPedido(pedido);
      return;
    }

    if (mostarDashed) {
      removerPedidoDaLista(pedido.pedidoID);
      pedidosParaRota = pedidosParaRota.filter(p => p.pedidoID !== pedido.pedidoID);
    } else {
      adicionarPedidoNaLista(pedido);
      if (!pedidosParaRota.some(p => p.pedidoID === pedido.pedidoID)) {
        pedidosParaRota.push(pedido);
      }
    }

    if (roteando) {
      selecionarMarkers(pedido.pedidoID);
    }
    selecionarMarker(pedido.pedidoID);

    flyToMarker(pedido.pedidoID);
  };


  const atribuirRota = useCallback(() => {
    try {
      if (selectedPedidos.length === 0) {
        showError({
          message: "A lista de pedidos não pode estar vazia.",
          duration: 5000
        })
        return;
      }

      const pedidosUnicos = new Set();
      let novosPedidosSelecionados = [...selectedPedidos];

      novosPedidosSelecionados = novosPedidosSelecionados.filter((pedido) => {
        if (pedidosUnicos.has(pedido.pedidoID)) {
          showError({
            message: "Não é permitido adicionar itens repetidos.",
            duration: 5000
          })
          return false; // Remove pedido repetido
        }
        pedidosUnicos.add(pedido.pedidoID);

        return true;
      });

      setSelectedPedidos(novosPedidosSelecionados);
      selecionarRota(novosPedidosSelecionados);
      abrirAtribuirPedido();
      toggleExtraButtons();
    } catch (error) {
      window.alert(error);
    }
  }, [selectedPedidos, selecionarRota, abrirAtribuirPedido]);

  const calcularPorKM = (entregador: Entregador) => {
    const payload = {
      pedidoID: selectedPedidos.map(p => p.pedidoID),
      usuarioID: entregador.usuarioID,
      rota: true,
      cobrarKm: true,
      orcamento: true
    };
    setPayloadzin(payload);
    dispatch(despacharRequest(payload))
    setOpenCalculos(true);
    limparNovaLista();
  }

  const calcularEntrePontos = (entregador: Entregador) => {
    const payload = {
      pedidoID: selectedPedidos.map(p => p.pedidoID),
      usuarioID: entregador.usuarioID,
      rota: true,
      cobrarKm: false,
      orcamento: true
    };
    setPayloadzin(payload);
    dispatch(despacharRequest(payload))
    setOpenCalculos(true);
    limparNovaLista();
  }

  const drawRoute = (coordinates: number[][]) => {
    if (mapRef.current?.getSource('route')) {
      mapRef.current.removeLayer('route');
      mapRef.current.removeSource('route');
    }

    if (mapRef.current !== null && !mapRef.current.getSource('route')) {
      mapRef.current.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: coordinates
          }
        }
      });

      mapRef.current.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': theme.palette.primary.main,
          'line-width': 3
        }
      });
    }
  };

  const handleOpenChat = () => {
    abrirChatLateral();
  };

  const handleOcorrencia = () => {
    setOpenOcorrencia(true);
  };

  const handleZap = (zap?: string | null, texto?: string) => {
    if (!zap) return;
    window.open(`https://api.whatsapp.com/send?phone=55${zap}&text=${texto}`, '_blank');
  };

  // Cria um novo mapa caso o mesmo não exista, adiciona eventos de resize quando currentCenter muda
  useEffect(() => {
    if (!currentCenter) return;
    if (!mapRef.current && mapContainerRef.current) {
      if (!mapboxgl.supported) {
        alert('Mapbox não está disponível');
        return
      }
      mapboxgl.accessToken = 'pk.eyJ1IjoicGlja25nby1kZW1vIiwiYSI6ImNsOXlxNXpxNzA3Y20zb2x4djBodmh0dHIifQ.F4C8zTSN6X257GKlrB5nWQ';
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        center: currentCenter || undefined,
        zoom: 12,
        attributionControl: false,
        logoPosition: 'bottom-left',
        projection: 'globe' as unknown as Projection,
      });

      handleSetStyleMap()

      mapRef.current.on('style.load', () => {
        mapRef.current?.touchZoomRotate.disableRotation();
        mapRef.current?.dragRotate.disable();
        setStyleLoading(false);
      });

      mapRef.current.on('moveend', () => {
        const center = mapRef.current?.getCenter();
        if (center) setCurrentCenter({ lng: center.lng, lat: center.lat });
      });
    }
    const mapContainer = mapContainerRef.current;
    if (mapContainer) {
      const resizeObserver = new ResizeObserver(() => {
        if (mapRef.current) { mapRef.current.resize() }
      });

      resizeObserver.observe(mapContainer);

      return () => {
        if (mapContainer) { resizeObserver.unobserve(mapContainer) }
      };
    }
  }, [currentCenter, mapContainerRef.current]);

  useEffect(() => {
    const latlngPick: null | { lat: number; lng: number } = pickSelecionada &&
      !isNaN(pickSelecionada?.latitude) &&
      !isNaN(pickSelecionada?.longitude)
      ? { lat: pickSelecionada?.latitude, lng: pickSelecionada?.longitude }
      : null;

    const latlngEmpresa: null | { lat: number; lng: number } = empresa && (!!empresa?.latitude && !!empresa?.longitude) && ({
      lat: empresa?.latitude,
      lng: empresa?.longitude
    })

    if (latlngPick) {
      setCurrentCenter(latlngPick);
    } else if (latlngEmpresa) {
      setCurrentCenter(latlngEmpresa);
    }
  }, [pickSelecionada, empresa]);

  // Use Effect para trocar o tema do Mapa
  useEffect(() => {
    handleSetStyleMap()
  }, [colorMode])

  useEffect(() => {
    if (filtros?.status) {
      setSelectedSituacao(filtros.status);
    } else {
      setSelectedSituacao('ALL');
    }
  }, [filtros?.status]);

  useEffect(() => {
    if (filtros?.entregador) {
      setSelectedEntregador(filtros.entregador);
    } else {
      setSelectedEntregador('ALL');
    }
  }, [filtros?.entregador]);


  // Use Effect para resolver bug de resize no modal mobile 
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [styleProps]);

  useEffect(() => {
    if (!mapRef.current) return;

    const handleMarkerClick = (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (!adicionandoPedidos) return;

      const target = event.originalEvent.target as HTMLElement;

      const markerElement = target.closest('.mapboxgl-marker');
      if (markerElement) {

        const marker = mapRef.current?.markers && mapRef.current?.markers.find((m: any) => m.getElement() === markerElement);

        if (marker && marker && marker.popup) {
          marker.setPopup(undefined);

        }
      }
    };

    mapRef.current.on('click', handleMarkerClick);

    return () => {
      mapRef.current?.off('click', handleMarkerClick);
    };
  }, [adicionandoPedidos, mapRef]);


  const addMarkerWithPopup = (
    position: { latitude: number; longitude: number },
    color: string | null,
    customElement: HTMLElement | null | undefined = undefined,
    popup?: mapboxgl.Popup | null,
    onClick?: () => void,
    tipoMarker?: 'pedido' | 'empresa'
  ) => {
    if (!position || !position.latitude || !position.longitude) {
      return;
    }

    if (!mapRef.current) {
      return;
    }

    const markerOffset =
      tipoMarker === 'pedido' ? [0, -5]
        : tipoMarker === 'empresa' ? [0, 6]
          : [0, 0];

    const marker = new mapboxgl.Marker({
      // @ts-expect-error
      element: customElement || undefined,
      color,
      anchor: 'bottom',
      offset: markerOffset,
    }).setLngLat([position.longitude, position.latitude]);

    if (popup instanceof mapboxgl.Popup) {
      marker.setPopup(popup); // Associate the popup with the marker
      (marker as any).popup = popup; // Add a reference to the popup in the marker
    }

    marker.addTo(mapRef.current);

    // Initialize the markers array if it doesn't exist
    if (!mapRef.current.markers) {
      mapRef.current.markers = []; // Initialize the array
    }

    mapRef.current.markers.push(marker);

    marker.getElement().addEventListener('click', () => {
      if (!adicionandoPedidos && popup) {
        popup.setLngLat([position.longitude, position.latitude]).addTo(mapRef.current as mapboxgl.Map);
      }
      onClick && onClick();
    });
  };


  useEffect(() => {
    const clearMarkers = () => {
      if (mapRef.current?.markers) {
        mapRef.current.markers.forEach(marker => marker.remove());
        mapRef.current.markers = [];
      }
    };
    clearMarkers();

    if (pickSelecionada && pickSelecionada.latitude && pickSelecionada?.longitude) {
      const popupContentEmpresa = document.createElement('div');
      popupContentEmpresa.innerHTML = `Nome da empresa: <b>${pickSelecionada?.nome}</b><br>Localização: <b>${pickSelecionada?.enderecoStr}</b>`;
      popupContentEmpresa.style.color = 'black';

      const popUpPick = new mapboxgl.Popup({ offset: [0, 0] })

    }
    entregadores?.lista?.forEach((entregador: Entregador) => {
      if (selectedEntregador == 'ALL' || entregador.id === selectedEntregador) {

        const entregadorElement = createMarkerEntregadorElement(entregador);

        const popupContent = document.createElement('div');
        popupContent.className = 'popupContent';
        popupContent.style.zIndex = '9999';

        const tituloDiv = document.createElement('div');
        tituloDiv.innerHTML = `${entregador.nome}`;
        tituloDiv.className = 'tituloDiv';

        const dataDiv = document.createElement('div');
        // @ts-expect-error
        dataDiv.innerHTML = `${entregador.dataUltimaLocalizacaoStr}`;
        dataDiv.className = 'dataDiv';

        const tipoVeiculoDiv = document.createElement('div');
        // @ts-expect-error
        tipoVeiculoDiv.innerHTML = `Tipo de veículo: <b>${entregador.tipoVeiculoNome}</b>`;
        tipoVeiculoDiv.className = 'tipoVeiculoDiv';

        const ultimoPedidoDiv = document.createElement('div');
        // @ts-expect-error
        ultimoPedidoDiv.innerHTML = `Último pedido: <b>${entregador.dataUltimoPedidoStr}</b>`;
        ultimoPedidoDiv.className = 'ultimoPedidoDiv';

        const bateriaDiv = document.createElement('div');
        bateriaDiv.className = 'bateriaDiv';

        const bateriaPercentual = document.createElement('b');
        // @ts-expect-error
        bateriaPercentual.innerText = `${entregador.percentualBateria}%`;
        // @ts-expect-error
        if (entregador.percentualBateria > 60) {
          bateriaPercentual.className = 'bateriaAlta';
          // @ts-expect-error
        } else if (entregador.percentualBateria >= 30) {
          bateriaPercentual.className = 'bateriaMedia';
        } else {
          bateriaPercentual.className = 'bateriaBaixa';
        }

        bateriaDiv.innerHTML = 'Bateria: ';
        bateriaDiv.appendChild(bateriaPercentual);

        const contatoDiv = document.createElement('div');
        contatoDiv.innerHTML = `Contato: <a><b>${telefone(entregador.telefone)}</b></a>`;
        contatoDiv.className = 'contatoDiv';

        const buttonsDiv = document.createElement('div');
        buttonsDiv.className = 'buttonsDiv';

        const button1 = document.createElement('button');
        button1.className = 'button';
        button1.style.width = '90%';
        button1.innerHTML = 'Abrir chat';
        button1.addEventListener('click', handleOpenChat);

        const button2 = document.createElement('button');
        button2.className = 'button';
        button2.innerHTML = 'Ocorrência';
        button2.addEventListener('click', handleOcorrencia);

        const button3 = document.createElement('button');
        button3.className = 'button button3';
        button3.addEventListener('click', () => { handleZap(entregador.telefone, '') });

        const whatsappIcon = document.createElement('img');
        whatsappIcon.src = 'https://i.ibb.co/qdy3Wx3/3c43d9252fbd1cae5e071c62e0dd9d6f-removebg-preview.png';
        whatsappIcon.className = 'whatsappIcon';

        //contatoDiv.appendChild(whatsappIcon);

        button3.appendChild(whatsappIcon);
        tipoDeUsuario !== 'Representante' && buttonsDiv.appendChild(button1);
        tipoDeUsuario === 'Representante' && buttonsDiv.appendChild(button2);
        buttonsDiv.appendChild(button3);

        popupContent.appendChild(tituloDiv);
        popupContent.appendChild(dataDiv);
        popupContent.appendChild(tipoVeiculoDiv);
        popupContent.appendChild(ultimoPedidoDiv);
        popupContent.appendChild(bateriaDiv);
        popupContent.appendChild(contatoDiv);
        popupContent.appendChild(buttonsDiv);

        const popup = new mapboxgl.Popup({ offset: 50 }).setDOMContent(popupContent);
        // @ts-expect-error
        addMarkerWithPopup({ latitude: entregador.latitude, longitude: entregador?.longitude }, null, entregadorElement, popup);
      }
    });

    const pedidosFiltrados = emAndamento?.filter((pedido: Pedido) => {
      const filtrarPorSituacao = selectedSituacao === 'ALL' || pedido.situacao === selectedSituacao;
      const filtrarPorEntregador = selectedEntregador === 'ALL' || pedido.entregadorUsuarioID === selectedEntregador;
      return filtrarPorSituacao && filtrarPorEntregador;
    }) || [];

    pedidosFiltrados?.forEach((pedido: Pedido) => {
      {
        const popupContent = document.createElement('div');
        popupContent.className = 'popupContent';

        const tituloDiv = document.createElement('div');
        tituloDiv.innerHTML = `${pedido?.empresa?.nome} - <b>#${pedido.codigoQuatro}</b>`;
        tituloDiv.className = 'tituloDiv';

        const dataCriacaoDiv = document.createElement('div');
        // @ts-expect-error
        dataCriacaoDiv.innerText = `${pedido.dataCadastroStr}(${pedido.tempoDecorridoCadastro})`;
        dataCriacaoDiv.className = 'dataCriacaoDiv';

        const rastreio = `${window.location.origin}/rastreio/${pedido.pedidoID}`;

        const botaoZapCliente = document.createElement('button');
        botaoZapCliente.className = 'button button3';
        botaoZapCliente.addEventListener('click', () => {
          handleZap(pedido.clienteTelefone, `Olá, ${pedido.clienteNome}! Seu pedido está a caminho, segue link de rastreio: ${rastreio}`);
        });
        const whatsappIcon = document.createElement('img');
        whatsappIcon.src = 'https://i.ibb.co/qdy3Wx3/3c43d9252fbd1cae5e071c62e0dd9d6f-removebg-preview.png';
        whatsappIcon.className = 'whatsappIcon';

        botaoZapCliente.appendChild(whatsappIcon);

        const nomeDiv = document.createElement('div');
        nomeDiv.innerHTML = `Cliente: <b>${pedido.clienteNome}</b>`;
        nomeDiv.className = 'nomeDiv';

        nomeDiv.appendChild(botaoZapCliente);

        const enderecoDiv = document.createElement('div');
        // @ts-expect-error
        enderecoDiv.innerHTML = `Destino: <b>${pedido.entregaEnderecoLogradouro}</b>`;
        enderecoDiv.className = 'enderecoDiv';

        const pagamentoDiv = document.createElement('div');
        pagamentoDiv.className = 'pagamentoDiv';
        pagamentoDiv.innerHTML = `Forma de pagamento: <b>${pedido.formaPagamento?.nome}</b>`;

        const botoesDiv = document.createElement('div');
        botoesDiv.className = 'botoesDiv';

        const botaoAtribuir = document.createElement('button');
        botaoAtribuir.innerText = (pedido.situacao === 'RECEBIDO' || pedido.situacao === 'PRONTO') ? 'Atribuir' : 'Reatribuir';
        botaoAtribuir.className = 'botao';
        botaoAtribuir.addEventListener('click', () => {
          selecionarPedido(pedido);
          abrirAtribuirPedido();
        });

        const botaoSituacao = document.createElement('button');
        botaoSituacao.innerText = 'Alterar situação';
        botaoSituacao.className = 'botao';
        botaoSituacao.addEventListener('click', () => {
          abrirTrocarStatus(/* pedido */);
        });

        const botaoEntregue = document.createElement('button');
        botaoEntregue.innerText = 'Entregue';
        botaoEntregue.className = 'botao';
        botaoEntregue.addEventListener('click', () => {
          dispatch(marcarFinalizadoRequest(pedido.pedidoID));
        });

        const botaoColetado = document.createElement('button');
        botaoColetado.innerText = 'Coletado';
        botaoColetado.className = 'botao';
        botaoColetado.addEventListener('click', () => {
          console.log('Coletado');
        });

        const botaoRetornando = document.createElement('button');
        botaoRetornando.innerText = 'Pagamento Entregue';
        botaoRetornando.className = 'botao';
        botaoRetornando.addEventListener('click', () => {
          dispatch(marcarFinalizadoRequest(pedido.pedidoID));
        });

        const botaoCancelar = document.createElement('button');
        botaoCancelar.innerText = 'Cancelar';
        botaoCancelar.className = 'botao';
        botaoCancelar.addEventListener('click', () => {
          setPedidoID(pedido.pedidoID);
          setCancelarOpen(true);
      });

        switch (pedido.situacao) {
          case 'RECEBIDO':
            botoesDiv.appendChild(botaoCancelar);
            botoesDiv.appendChild(botaoAtribuir);
            //botoesDiv.appendChild(botaoSituacao);
            break;
          case 'DESPACHADO':
            botoesDiv.appendChild(botaoAtribuir);
            botoesDiv.appendChild(botaoCancelar);
            break;
          case 'ACEITO':
            botoesDiv.appendChild(botaoAtribuir);
            botoesDiv.appendChild(botaoZapCliente);
            break;
          case 'NO_ESTABELECIMENTO':
            botoesDiv.appendChild(botaoAtribuir);
            botoesDiv.appendChild(botaoZapCliente);

            break;
          case 'PRONTO':
            botoesDiv.appendChild(botaoAtribuir);
            botoesDiv.appendChild(botaoZapCliente);

            break;
          case 'EM_ROTA':
            botoesDiv.appendChild(botaoEntregue);
            botoesDiv.appendChild(botaoZapCliente);

            break;
          case 'RETORNANDO':
            botoesDiv.appendChild(botaoRetornando);
            break;
          default:
            break;
        }

        popupContent.appendChild(tituloDiv);
        popupContent.appendChild(dataCriacaoDiv);
        popupContent.appendChild(nomeDiv);
        popupContent.appendChild(pagamentoDiv);
        popupContent.appendChild(enderecoDiv);
        { tipoDeUsuario !== 'Representante' && popupContent.appendChild(botoesDiv); }

        const popup = new mapboxgl.Popup({ offset: [0, -30] }).setDOMContent(popupContent);
        const pedidoElement = createMarkerPedidoElement(pedido, theme);
        // @ts-expect-error
        addMarkerWithPopup({ latitude: pedido.latitude, longitude: pedido?.longitude }, null, pedidoElement, popup, () => handlePedidoClick(pedido), 'pedido');
        // @ts-expect-error
        if (pedido.origemEnderecoLatitude !== null && pedido.origemEnderecoLongitude !== null) {
          const origemElement = document.createElement('div');
          origemElement.className = 'origemElement';
          origemElement.style.backgroundColor = 'black';
          origemElement.style.color = 'white';
          origemElement.style.padding = '2px 5px';
          origemElement.style.borderRadius = '3px';
          origemElement.innerText = `C^${pedido.codigoQuatro}`;

          const origemPopupContent = popupContent.cloneNode(true);

          const origemPopup = new mapboxgl.Popup({ offset: [0, 0] }).setDOMContent(origemPopupContent);
          // @ts-expect-error
          addMarkerWithPopup({ latitude: pedido?.origemEnderecoLatitude, longitude: pedido?.origemEnderecoLongitude }, 'black', origemElement, origemPopup);
        }
      }
    });
    empresas?.forEach((empresa: Empresa) => {
      if (empresa.ehBase) return
      if (empresa?.longitude && empresa?.latitude) {
        const popupContentEmpresa = document.createElement('div');
        popupContentEmpresa.innerHTML = `Nome da empresa: <b>${empresa?.nome}</b><br>Localização: <b>${empresa?.enderecoStr}</b>`;
        popupContentEmpresa.style.color = 'black';

        const popUpEmpresa = new mapboxgl.Popup({ offset: [0, -40] }).setDOMContent(popupContentEmpresa);

        addMarkerWithPopup(
          { latitude: empresa?.latitude, longitude: empresa?.longitude },
          theme.palette.primary.main,
          null,
          popUpEmpresa,
          () => { },
          'empresa'
        )
      }
    })
  }, [entregadores, emAndamento, filtros?.status, selectedSituacao, selectedEntregador, isRouting, selectedPedidos, cardPedidoSelecionado]);


  const opcoes = [
    { label: 'Cancelar', action: () => setCancelarOpen(false) },
    {
      label: 'Confirmar', action: () => {
        dispatch(cancelarPedidoRequest(pedidoID))
        setCancelarOpen(false)
      }
    },
  ]
  const processarRotaParaPedidos = (pedidos: Pedido[]) => {
    if (pedidos.length === 0) return;

    const primeiraEmpresa = empresas?.find(
      (e: Empresa) => e.empresaID === pedidos[0].empresaID
    );

    if (!primeiraEmpresa) {
      showError({
        message: 'Empresa inválida. Não é possível determinar a origem.',
        duration: 5000,
      });
      return;
    }

    const todasMesmaOrigem = pedidos.every(
      (pedido) => pedido.empresaID === primeiraEmpresa.empresaID
    );

    if (!todasMesmaOrigem) {
      showError({
        message: 'Todos os pedidos devem ter a mesma origem para traçar a rota.',
        duration: 5000,
      });
      return;
    }

    const routeCoordinates = [
      [primeiraEmpresa.longitude, primeiraEmpresa.latitude],
      ...pedidos.map((pedido) => [pedido.longitude, pedido.latitude]),
    ];

    drawRoute(routeCoordinates);
  };

  useEffect(() => {
    if (cardPedidoSelecionado && !adicionandoPedidos) {
      flyToMarker(cardPedidoSelecionado.pedidoID)
      // const empresa = empresas.find(e => e.empresaID === cardPedidoSelecionado.empresaID)
      if (isRouting && mapRef.current && mapRef.current.isStyleLoaded()) {
        handlePedidoClick(cardPedidoSelecionado)
      }
    }
  }, [cardPedidoSelecionado]);

  useEffect(() => {
    adicionandoPedidos ? setShowExtraButtons(true) : setShowExtraButtons(false);
    adicionandoPedidos ? setIsRouting(true) : setIsRouting(false);
    !adicionandoPedidos && limparRotas();
    if (novaListaPedidos.length > 0 && adicionandoPedidos && novaListaPedidos.every((pedido: Pedido) => pedido.situacao === 'PRONTO' || pedido.situacao === 'RECEBIDO')) {
      if (novaListaPedidos.length > 0 && adicionandoPedidos) {
        const pedidosValidos = novaListaPedidos.filter(
          (pedido: Pedido) => pedido.situacao === 'PRONTO' || pedido.situacao === 'RECEBIDO'
        );

        if (pedidosValidos.length === novaListaPedidos.length) {
          processarRotaParaPedidos(pedidosValidos);
        } else {
          limparRotas();
          showError({
            message: "Apenas pedidos com a situação 'PRONTO', 'RECEBIDO' ou 'ACEITO' podem ser selecionados para rota.",
            duration: 5000,
          });
        }
      }
      novaListaPedidos.map((pedido: Pedido) => {
        handlePedidoClick(pedido);
      });
    }
    if ((novaListaPedidos.length === 0 && adicionandoPedidos) || novaListaPedidos.some((pedido: Pedido) => pedido.situacao !== 'PRONTO' && pedido.situacao !== 'RECEBIDO')) {
      limparRotas();
      if (novaListaPedidos.some((pedido: Pedido) => pedido.situacao !== 'PRONTO' && pedido.situacao !== 'RECEBIDO')) {
        showError({
          message: "Apenas pedidos com a situação 'PRONTO', 'RECEBIDO' ou 'ACEITO' podem ser selecionados para rota.",
          duration: 5000
        });
      }
    }
  }, [novaListaPedidos, adicionandoPedidos]);

  if (!mapboxgl.supported) return <Box />
  return (
    <>
      <BoxLoading loading={!currentCenter || styleLoading} >
        <MapContainer
          ref={mapContainerRef}
          style={{ height: '100%', width: '100%', ...styleProps }}
        />
      </BoxLoading>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          position: 'absolute',
          top: isMobile ? '1rem' : '.5rem',
          left: isMobile ? '1rem' : '.5rem',
          gap: '.5rem',
          ...isMobile && { padding: '.5rem 0 0' },
        }}
      >
        {podeRota && podeRotear &&
          <>
            {styleLoading
              ? <CircularProgress size={24} />
              : (
                showExtraButtons
                  ? (
                    // @ts-expect-error
                    <IconButton
                      variant={"containedFilled"}
                      color='primary'
                      size='small'
                      onClick={toggleExtraButtons}
                      sx={{ borderRadius: '6px', width: isMobile ? 'fit-content' : 'auto' }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  ) : (
                    // @ts-expect-error
                    <IconButton
                      variant={"containedFilled"}
                      color='primary'
                      size='small'
                      onClick={toggleExtraButtons}
                      sx={{
                        borderRadius: '6px', width: isMobile ? 'fit-content' : 'auto',
                      }}
                    >
                      <Route />
                    </IconButton>
                  )
              )
            }
          </>
        }
        <Collapse in={showExtraButtons} orientation={isMobile ? 'vertical' : 'horizontal'} timeout={300}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: '.5rem'
            }}
          >
            {/* <Button variant='containedFilled' sx={{ width: 'fit-content', height: '40px' }} onClick={limparRotas}>Limpar rotas</Button>
            {!isMobile && <Button variant='containedFilled' sx={{ width: 'fit-content' }} onClick={atribuirRota}>Atribuir</Button>} */}
            {podeKmTotal && <Button variant='containedFilled' sx={{ width: 'max-content', flexShrink: 0 }} onClick={() => { setOpenEntregadorModal(true) }}>Calcular por KM total</Button>}
            {podeEntrePontos && <Button variant='containedFilled' sx={{ width: 'max-content', flexShrink: 0 }} onClick={() => { setOpenEntregadorModal1(true) }}>Calcular entre pontos</Button>}
          </Box>
        </Collapse >
      </Box>
      <ModalOpcoes
        open={cancelarOpen}
        onClose={() => setCancelarOpen(false)}
        acao="Cancelar pedido"
        descricao="Tem certeza que deseja cancelar este pedido?"
        opcoes={opcoes}
      />
      <ModalSelectEntregador
        open={openEntregadorModal}
        handleClose={() => setOpenEntregadorModal(false)}
        callback={calcularPorKM}
      />
      <ModalSelectEntregador
        open={openEntregadorModal1}
        handleClose={() => setOpenEntregadorModal1(false)}
        callback={calcularEntrePontos}
      />
      <ModalCalculos open={openCalculos} handleClose={() => setOpenCalculos(false)} payloadzin={payloadzin} />
      <ModalCalculos open={openCalculos} handleClose={() => setOpenCalculos(false)} payloadzin={payloadzin} />
      <OcorrenciaPorEntregador dados={dadosOcorrencia} show={openOcorrencia} setShow={setOpenOcorrencia} />
    </>
  );
}

export default MapHome;
