import React, { useEffect, useState, ForwardedRef } from 'react';
import { Autocomplete, TextField, AutocompleteProps, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { forwardRef } from 'react';
import { RootState } from 'src/store/reducers';
import { listarCidades, listarestados } from 'src/store/reducers/utils';
import { GOOGLE_API_KEY } from '..';
import { Search } from '@mui/icons-material';

interface AutocompleteMapProps extends Omit<AutocompleteProps<any, false, false, true>, 'renderInput' | 'onChange' | 'inputValue' | 'value' | 'options'> {
    size?: 'small' | 'medium';
    setPosition: (position: { latitude: number; longitude: number }) => void;
    setFormState: React.Dispatch<React.SetStateAction<any>>;
    isModelLoaded?: boolean;
    InputProps?: any;
    valueController?: string | null;
    onValueChangeController?: (value: string) => void;
}

/**
 * Componente de autocomplete baseado no Google Maps para endereços, 
 * suportando manipulação de localizações personalizadas, renderização de predições 
 * e atualizações de estado.
 * 
 * @component
 * 
 * @param {AutocompleteMapProps} props - Propriedades do componente.
 * @param {'small' | 'medium'} [props.size] - Tamanho do campo de entrada.
 * @param {function} props.setPosition - Função de callback para definir as coordenadas geográficas com base no endereço selecionado.
 * @param {function} props.setFormState - Função para atualizar o estado do formulário com os dados do endereço selecionado.
 * @param {boolean} [props.isModelLoaded] - Indica se o modelo do Google Maps foi carregado.
 * @param {object} [props.InputProps] - Propriedades adicionais para o campo de entrada.
 * @param {string | null} [props.valueController] - Valor controlado do input.
 * @param {function} [props.onValueChangeController] - Função chamada quando o valor do input muda.
 * 
 * @returns {JSX.Element} - Elemento do componente Autocomplete.
 */
const AutocompleteMap = forwardRef(({
    size,
    setPosition,
    setFormState,
    isModelLoaded,
    InputProps,
    valueController = '',
    onValueChangeController = () => { },
    ...props
}: AutocompleteMapProps, ref: ForwardedRef<HTMLDivElement>) => {
    const dispatch = useDispatch();
    const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
    const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const [autocompleteOptions, setAutocompleteOptions] = useState<{ label: string; id: number }[]>([]);
    const [finalValue, setFinalValue] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const lat = useSelector((state: any) => state.config?.masterPage.empresa?.latitude || state.config?.masterPage.pickSelecionada?.latitude || null);
    const lng = useSelector((state: any) => state.config?.masterPage.empresa?.longitude || state.config?.masterPage.pickSelecionada?.longitude || null);
    const distanciaMaximaBuscaMapa = useSelector((state: any) => {
        const distancia = state.config?.masterPage.empresa?.distanciaMaximaBuscaMapa || state.config?.masterPage.pickSelecionada?.distanciaMaximaBuscaMapa || null;
        return distancia === 0 || distancia === null ? 2 : distancia;
    });

    const estadosStore = useSelector((state: RootState) => state.utils.estados);
    const cidadesStore = useSelector((state: RootState) => state.utils.cidades);

    useEffect(() => {
        if (!estadosStore || !cidadesStore) {
            dispatch(listarCidades())
            dispatch(listarestados())
        }
    }, [estadosStore, cidadesStore]);

    useEffect(() => {
        const loadGoogleMaps = () => {
            if (!window.google) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);

                script.onload = () => {
                    initializeAutocompleteService();
                };

                return () => {
                    document.head.removeChild(script);
                };
            } else {
                initializeAutocompleteService();
            }
        };

        const initializeAutocompleteService = () => {
            setAutocompleteService(new window.google.maps.places.AutocompleteService());
        };

        loadGoogleMaps();
    }, []);

    const autocompleteForms = (addressComponents: google.maps.GeocoderAddressComponent[] | null, value: string | null) => {
        const placeInfo: any = { logradouro: value || '' };
        addressComponents?.forEach(component => {
            switch (component.types[0]) {
                case 'subpremise':
                    placeInfo.complemento = component.long_name;
                    break;
                case 'political':
                    placeInfo.bairro = component.long_name;
                    break;
                case 'postal_code':
                    placeInfo.cep = component.long_name;
                    break;
                case 'street_number':
                    placeInfo.numero = component.long_name;
                    break;
                case 'route':
                    placeInfo.logradouro = component.long_name;
                    break;
                case 'administrative_area_level_2':
                    placeInfo.cidade = component.long_name;
                    break;
                case 'administrative_area_level_1':
                    placeInfo.estado = component.short_name;
                    break;
                default:
                    break;
            }
        });

        let estadoID = undefined;
        let cidadeID = undefined;

        if (placeInfo?.estado) {
            const estado = estadosStore?.find((estado) => estado.estadoUF === placeInfo?.estado);
            if (estado) estadoID = estado.estadoID;
        }

        if (placeInfo?.cidade && estadoID) {
            const cidadesPorEstado = cidadesStore?.filter((cidade) => cidade.estadoID === estadoID);
            const cidade = cidadesPorEstado?.find((cidade) => cidade.cidadeNome === placeInfo?.cidade);
            if (cidade) cidadeID = cidade.cidadeID;
        }

        setFormState((prevState: any) => ({
            ...prevState,
            complemento: placeInfo?.complemento || prevState?.complemento || '',
            bairro: placeInfo?.bairro || prevState?.bairro || '',
            cep: placeInfo?.cep || prevState?.cep || '',
            numero: placeInfo?.numero || prevState?.numero || '',
            logradouro: placeInfo?.logradouro || prevState?.logradouro || '',
            cidade: placeInfo?.cidade || prevState?.cidade || '',
            estado: placeInfo?.estado || prevState?.estado || '',
            estadoID: estadoID || prevState?.estadoID || undefined,
            cidadeID: cidadeID || prevState?.cidadeID || undefined,
        }));
    };

    const getLatLng = async (placeDescription: string) => {
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(placeDescription)}&key=${GOOGLE_API_KEY}`;

        try {
            const response = await fetch(apiUrl);
            const data = await response.json();

            if (data.status === 'OK' && data.results.length > 0) {
                autocompleteForms(data.results[0].address_components, null);

                const location = data.results[0].geometry.location;
                return { latitude: location.lat, longitude: location.lng };
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };
    const [manualTrigger, setManualTrigger] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        if (autocompleteService && inputValue && !manualTrigger) {
            const bounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(lat - distanciaMaximaBuscaMapa, lng - distanciaMaximaBuscaMapa),
                new window.google.maps.LatLng(lat + distanciaMaximaBuscaMapa, lng + distanciaMaximaBuscaMapa)
            );

            setLoading(true);
            timer = setTimeout(() => {
                autocompleteService.getPlacePredictions(
                    {
                        input: inputValue,
                        language: 'pt-BR',
                        locationRestriction: lat && lng && bounds,
                        componentRestrictions: { country: 'BR' },
                    },
                    (predictions, status) => {
                        setLoading(false);
                        if (status === 'OK' && predictions) {
                            setPredictions(predictions);
                        } else {
                            setPredictions([]);
                        }
                    }
                );
            }, 3000);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [inputValue, autocompleteService, lat, lng, manualTrigger]);

    const triggerSearch = () => {
        console.log('triggerSearch');
        setManualTrigger(true);
        const bounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(lat - distanciaMaximaBuscaMapa, lng - distanciaMaximaBuscaMapa),
            new window.google.maps.LatLng(lat + distanciaMaximaBuscaMapa, lng + distanciaMaximaBuscaMapa)
        );

        setLoading(true);
        autocompleteService?.getPlacePredictions(
            {
                input: inputValue,
                language: 'pt-BR',
                locationRestriction: lat && lng && bounds,
                componentRestrictions: { country: 'BR' },
            },
            (predictions, status) => {
                setLoading(false);
                if (status === 'OK' && predictions) {
                    console.log(predictions);
                    setPredictions(predictions);

                } else {
                    setPredictions([]);
                }
                setManualTrigger(false);
            }
        );
    };



    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            triggerSearch();

            const inputElement = event.target as HTMLInputElement;
            inputElement.focus();
        }
    };


    useEffect(() => {
        setAutocompleteOptions(
            predictions.map((prediction, index) => ({ label: prediction.description, id: index }))
        );
    }, [predictions]);

    const handleChange = (event: React.ChangeEvent<{}>, value: { label: string } | null) => {
        if (value?.label) {
            setFinalValue(value.label);
            setInputValue(value.label);
            onValueChangeController(value.label);
        }
    };

    const handleInputChange = async (event: React.ChangeEvent<{}>, value: string) => {
        const keywords = ['avenida', 'rua', 'br', 'travessia', 'praça', 'sqs', 'sqn', 'scln', 'sclrn', 'sclrs'];

        const normalize = (text: string) =>
            text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

        const normalizedKeywords = keywords.map(normalize);

        const formattedValue = value.includes(',')
            ? value.replace(/\s+,/, ',')
            : value.replace(/(\D+)\s+(\d+)(?=\s*$)/, (_, words, number) => {
                const normalizedWords = normalize(words.trim());

                if (normalizedKeywords.some((keyword) => normalizedWords.endsWith(keyword))) {
                    return `${words} ${number}`;
                }

                return `${words}, ${number}`;
            });

        setInputValue(value || '');
        onValueChangeController(value || '');

        if (!value) {
            setFinalValue(null);
        }

        setFormState((prevState: any) => ({
            ...prevState,
            logradouro: formattedValue,
        }));
    };


    useEffect(() => {
        const fetchData = async () => {
            if (predictions.some(prediction => prediction.description === finalValue)) {
                const finalLocation = await getLatLng(finalValue!);
                if (finalLocation) {
                    setPosition(finalLocation);
                }
            }
        };
        fetchData();
    }, [finalValue]);

    useEffect(() => {
        if (inputValue) {
            valueController = inputValue;
            autocompleteForms(null, inputValue);
        }
    }, [inputValue]);

    return (
        <Autocomplete
            {...props}
            ref={ref}
            freeSolo
            options={autocompleteOptions}
            value={finalValue}
            loading={loading}
            loadingText="Carregando..."
            onChange={handleChange}
            inputValue={inputValue || (valueController ?? undefined)}
            onInputChange={handleInputChange}
            filterOptions={() => autocompleteOptions}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...InputProps}
                    fullWidth
                    size={size}
                    label="Endereço, n°"
                    variant="outlined"
                    name="endereco"
                    error={InputProps?.error}
                    helperText={InputProps?.helperText}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                sx={{
                                    width: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    right: '10px',
                                }}
                            >
                                {loading ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <IconButton onClick={triggerSearch} aria-label="Buscar">
                                        <Search />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />

    );

});

export default AutocompleteMap;
