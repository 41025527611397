import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper, IconButton, Theme, useTheme, Avatar, Typography, Collapse, Button, Chip } from "@mui/material";
import { ArrowDownward, ArrowUpward, Delete, Edit, KeyboardArrowDown, KeyboardArrowUp, Payments, Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import editar from "../../assets/icons/editar.png";
import deleteIcon from "../../assets/icons/delete.png";
import { TableRowLoading, TableRowNotFound, ButtonTableOrder, ChipSituacao } from "src/ds"; // Adicione ButtonTableOrder aqui
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
import ModalQrCode from "../common/ModalQrCode";
import { dinheiro, formatCnpj } from "src/ds/common";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "src/context/PermissionsContext";
import { ButtonChip } from "src/ds/components/ButtonChip";
import { RootState } from "src/store/reducers";
import dayjs from "dayjs";
import ModalCobrancas from "./Modal";
import { listarMinhasStart, listarMinhasTransacoesStart, salvarTransacaoStart } from "src/store/reducers/faturas";
import Detalhes from "../MinhasFaturas/Detalhes";
import { AssinaturaFatura, FaturaRepresentante } from "src/dtos/FaturasRepresentante";


interface TabelaProps {
    data: FaturaRepresentante[] | undefined;
    loading: boolean;
    fezRequisicao?: boolean;
}

const Tabela = ({ data, loading, fezRequisicao }: TabelaProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        nome: 'asc',
        codigoIdentificador: 'asc',
        dataCadastro: 'asc',
        cnpj: 'asc',
    });
    const [row, setRow] = useState<FaturaRepresentante | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<AssinaturaFatura | null>(null);
    const masterPage = useSelector((state: any) => state.config?.masterPage);
    const usuario = useSelector((state: any) => state.login?.usuario);
    const empresasPick = useSelector((state: any) => state.config?.empresasPick?.dados?.lista[0]);

    useEffect(() => {
        if (data && data.length > 0) {
            dispatch(salvarTransacaoStart({
                dados: {
                    assinaturaFaturaID: data[0].faturaID
                }
            }));
        }
    })

    const podeEditarRow = useSelector((state: any) => state.config?.masterPage?.usuario?.['row.editar']);

    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        setSortDirection((prevState) => ({
            ...prevState,
            [column]: newSortDirection,
        }));

        const payload = {
            paginacao: 20,
            pagina: 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
        };
    };

    const permissions = usePermissions();

    const [isAllOpen, setIsAllOpen] = useState(false);

    const [openRow, setOpenRow] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        if (data && data.length > 0) {
            const initialOpenRow = data.reduce((acc, item, index) => ({
                ...acc,
                [item.faturaID]: index === 0, 
            }), {});
            setOpenRow(initialOpenRow);
            handleSelect(data[0]);

            dispatch(salvarTransacaoStart({
                dados: {
                    assinaturaFaturaID: data[0].faturaID
                }
            }));
        }
    }, [data, dispatch]);


    const handleToggle = useCallback((faturaID: string) => {

        setOpenRow(prev => {
            const newState: Record<string, boolean> = {};
            Object.keys(prev).forEach(key => {
                newState[key] = false;
            });
            newState[faturaID] = !prev[faturaID];
            return newState;
        });
    }, []);

    const handleSelect = useCallback((row: FaturaRepresentante) => {

        const payload: AssinaturaFatura = {
            codigo: row.codigo,
            dataCadastro: row.dataCadastro,
            dataFechamento: row.dataFechamento,
            descricao: row.descricao,
            mensagemCliente: row.mensagemCliente,
            valor: row.valorNota,
            usuarioResponsavel: masterPage?.usuario?.nome,
            telefoneResponsavel: masterPage?.usuario?.telefone,
            emailFinanceiroResponsavel: masterPage?.usuario?.email,
            assinaturaFaturaID: row.faturaID,
            endereco: {
                CEP: 'faltando',
                logradouro: empresasPick?.enderecoStr,
                cidadeNome: usuario.cidadeNome,
                uf: usuario.uf,
            },
            itens: [
                {
                    descricao: row.descricao,
                    valor: row.valorNota,
                    assinaturaFaturaItemID: 'faltando',
                    dataCadastro: 'faltando',
                }
            ],
            formaPagamento: {
                nome: 'Gateway',

            }

        }
        setSelectedItem(payload);

    }, [])

    const handleToggleAll = useCallback(() => {
        setOpenRow(prev => {
            const updated = { ...prev };
            data?.forEach(item => {
                updated[item.faturaID] = !isAllOpen;
            });
            return updated;
        });
        setIsAllOpen(prev => !prev);
    }, [data, isAllOpen]);

    const template = useSelector((state: RootState) => state.config?.template?.template);

    return (
        <TableContainer component={Paper} sx={{ width: "100%", overflowY: "auto" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Empresa</TableCell>
                        <TableCell align="center">Código</TableCell>
                        <TableCell align="center">Data de cadastro</TableCell>
                        <TableCell align="center">Total faturado</TableCell>
                        <TableCell align="center">Total cobrado</TableCell>
                        <TableCell align="center">Total pago</TableCell>
                        <TableCell align="center">Saldo</TableCell>
                        <TableCell align="center">Itens</TableCell>
                        <TableCell align="center">Situação</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : data && data.length > 0 ? (
                        data?.map((row: FaturaRepresentante, index) => (
                            <>
                                <TableRow key={row.faturaID}>
                                    <TableCell align='center'>{row.empresa?.nome}</TableCell>
                                    <TableCell align='center'><Chip label={row.codigo} color='primary' /></TableCell>
                                    <TableCell align='center'>{dayjs(row.dataCadastro, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')}</TableCell>
                                    <TableCell align="center">{dinheiro(row.totalFaturado)}</TableCell>
                                    <TableCell align="center">{dinheiro(row.totalCobrado)}</TableCell>
                                    <TableCell align="center">{dinheiro(row.totalPago)}</TableCell>
                                    <TableCell align="center">{dinheiro(row.totalAReceber)}</TableCell>
                                    <TableCell align="center">{row.itens.map(item => {
                                        return (
                                            <Typography variant="body2">{item.descricao}({dinheiro(item.valor)})</Typography>
                                        )
                                    })}</TableCell>
                                    <TableCell align="center">
                                        <Chip label={row?.totalAReceber === 0 ? 'Pago' : 'Atrasada'} color={row?.totalAReceber === 0 ? 'success' : 'error'} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip arrow placement='top' title={openRow[row.faturaID] ? "Minimizar detalhes" : "Expandir detalhes"}>
                                            <IconButton onClick={() => {
                                                handleToggle(row.faturaID)
                                                handleSelect(row)
                                            }}>
                                                {openRow[row.faturaID] ? <VisibilityOff color='secondary' /> : <Visibility color='primary' />}
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip arrow placement='top' title={"Visualizar cobranças"}>
                                            <IconButton
                                                onClick={() => {
                                                    dispatch(listarMinhasTransacoesStart({
                                                        empresaID: row.empresaID,
                                                        paginacao: 20,
                                                        pagina: 1,
                                                        filtros: `faturaID.toString().Equals("${row.faturaID}")`
                                                    }));
                                                    setRow(row);
                                                    setModalOpen(true)
                                                }}>
                                                <Payments color={'success'} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={12} sx={{ p: 0 }}>
                                        <Collapse in={openRow[row.faturaID]} timeout="auto" unmountOnExit>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                                <Detalhes selectedItem={selectedItem} />
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))
                    ) : (<TableRowNotFound />)}
                </TableBody>
            </Table>
            <ModalCobrancas open={modalOpen} onClose={() => setModalOpen(false)} row={row} />
        </TableContainer >
    );
}

export default Tabela;
