import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from 'react-router-dom';

import { Provider } from 'react-redux';
import { store, persistor } from './store/index.js';
import { PersistGate } from 'redux-persist/integration/react';

import CustomThemeProvider from './styles/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Login from './pages/Login';
import Painel from './pages/Painel/index';
import NotFound from './routes/NotFound/index';
import DesignSystem from './pages/DesignSystem/index';
import Rastreio from './pages/Rastreio/index';
import FinalizarRastreio from './pages/FinalizarRastreio/index';
import PrimeiroAcesso from './pages/PrimeiroAcesso/PrimeiroAcesso';
import Redirect from './pages/PrimeiroAcesso/Redirect';
import DespachoAutomatico from './pages/DespachoAutomatico';
import Empresas from './pages/Empresas';
import Clientes from './pages/Clientes';
import Entregadores from './pages/Entregadores';
import TabelaCobrancaPage from './pages/TabelaCobranca';
import TabelaPagamentoPage from './pages/TabelaPagamento';
import TaxaExtra from './pages/TaxaExtra';
import FinanceiroPorEntregador from './pages/FinanceiroPorEntregador';
import PerformancePedido from './pages/PerformancePedido';
import RelatorioGeral from './pages/RelatorioGeral';
import Bots from './pages/Bots';
import AbastecerWallet from './pages/AbastecerWallet';

import TabsEntregadores from './components/Entregadores/Tabs';
import Tabs from './components/TaxaExtra/Tabs';
import TabsDa from './components/DespachoAutomatico/Tabs';
import TabsTabelaCobranca from './components/TabelaCobranca/Tabs';
import TabsTabelaPagamento from './components/TabelaPagamento/Tabs';
import TabsPedido from './components/Pedidos/TabsPedido';

import ProtectedRoute from './routes/ProtectedRoute/index';
import ErrorBoundary from './routes/ErrorBoundary';

import { PedidoProvider } from './context/PedidoContext.js';
import { PaginaProvider } from './context/PaginaContext.js';
import { PermissionsProvider } from './context/PermissionsContext';
import { HubspotConversationsProvider } from './context/HubspotConversationsContext';
import TimeoutError from './routes/Timeout';
import PerformanceEntregador from './pages/PerformanceEntregador';
import MinhasFaturas from './pages/MinhasFaturas';
import FaturasRepresentante from './pages/FaturasRepresentante';
import { GoogleMapsLoaderProvider } from './context/GoogleMapsLoaderContext';
import RootRedirect from './routes/RootRedirect';
import TabsClientes from './components/Clientes/Tabs';
import SnackbarProvider from './context/SnackbarContext';
import TesteTabela from './pages/TesteTabela';
import AbastecerRepresentante from './pages/AbastecerWallet/abastecerRepresentante';
import Pontuacao from './pages/Pontuacao';
import VagaPadrao from './pages/VagaPadrao';

const routes = createRoutesFromElements(
  <Route errorElement={<ErrorBoundary />}>
    <Route path="/" element={<RootRedirect />} />
    <Route path="/Login" element={<Login />} />
    <Route path="/PrimeiroAcesso/:cupom?" element={<PrimeiroAcesso />} />
    <Route path="/Redirect" element={<Redirect />} />
    <Route path="/Painel" element={<ProtectedRoute><Painel /></ProtectedRoute>} />
    <Route path="/EmpresaRepresentante/PainelRepresentante" element={<Navigate to="/Painel" replace />} />
    <Route path="/EmpresaRepresentante/PainelRepresentantePerformance" element={<Navigate to="/Painel" replace />} />
    <Route path="/Home/Dashboard" element={<ProtectedRoute><RelatorioGeral openDash={true} /></ProtectedRoute>} />
    <Route path="/Pedido/PainelPerformance" element={<Navigate to="/Painel" replace />} />
    <Route path="/Pedido/IndexNovo" element={<Navigate to="/Pedido/Index" replace />} />

    <Route path='/EmpresaRepresentante/Pedidos' element={<Navigate to="/Pedido/Index" replace />} />
    <Route path="/TabelaDespachoAutomatico/Index" element={<ProtectedRoute><DespachoAutomatico /></ProtectedRoute>} />
    <Route path="/TabelaDespachoAutomatico/:action/:id" element={<ProtectedRoute><TabsDa /></ProtectedRoute>} />
    <Route path="/TabelaDespachoAutomatico/Inserir" element={<ProtectedRoute><TabsDa /></ProtectedRoute>} />
    <Route path="/Usuario/Empresas" element={<ProtectedRoute><Empresas /></ProtectedRoute>} />
    <Route path="/Empresa/Index" element={<Navigate to="/Usuario/Empresas" replace />} />

    <Route path="/EmpresaCliente/Index" element={<ProtectedRoute><Clientes /></ProtectedRoute>} />
    <Route path="/EmpresaCliente/Index/:id" element={<ProtectedRoute><TabsClientes /></ProtectedRoute>} />

    <Route path="/Usuario/Entregadores" element={<ProtectedRoute><Entregadores /></ProtectedRoute>} />
    <Route path="/Usuario/Entregadores/:id" element={<ProtectedRoute><TabsEntregadores /></ProtectedRoute>} />

    <Route path="/TabelaCobranca/Index" element={<ProtectedRoute><TabelaCobrancaPage /></ProtectedRoute>} />
    {/* @ts-expect-error */}
    <Route path="/TabelaCobranca/Index/:id" element={<ProtectedRoute><TabsTabelaCobranca /></ProtectedRoute>} />
    <Route path="/TabelaValor/Index" element={<ProtectedRoute><TabelaPagamentoPage /></ProtectedRoute>} />
    {/* @ts-expect-error */}
    <Route path="/TabelaValor/Index/:id" element={<ProtectedRoute><TabsTabelaPagamento /></ProtectedRoute>} />
    <Route path="/Pick/Bots" element={<ProtectedRoute><Bots /></ProtectedRoute>} />
    <Route path="/DS" element={<DesignSystem />} />
    <Route path="/Rastreio/:idParam" element={<Rastreio />} />
    <Route path="/Rastreio/Finalizar" element={<FinalizarRastreio />} />
    <Route path="/TaxaExtra/Index" element={<ProtectedRoute><TaxaExtra /></ProtectedRoute>} />
    <Route path="/TaxaExtra/Index/Cadastro" element={<ProtectedRoute><Tabs /></ProtectedRoute>} />
    <Route path="/TaxaExtra/Index/:action/:id" element={<ProtectedRoute><Tabs /></ProtectedRoute>} />

    <Route path="/Relatorio/FinanceiroPorEntregador" element={<ProtectedRoute><FinanceiroPorEntregador /></ProtectedRoute>} />
    <Route path="/Relatorio/PerformancePedido" element={<ProtectedRoute><PerformancePedido /></ProtectedRoute>} />
    <Route path="/Relatorio/PerformanceEntregador" element={<ProtectedRoute><PerformanceEntregador /></ProtectedRoute>} />
    <Route path="/Pedido/Index" element={<ProtectedRoute><RelatorioGeral /></ProtectedRoute>} />

    <Route path="/Fatura/MinhasFaturas" element={<ProtectedRoute><MinhasFaturas /></ProtectedRoute>} />
    <Route path="/EmpresaRepresentante/Financeiro" element={<ProtectedRoute><FaturasRepresentante /></ProtectedRoute>} />
    {/* @ts-expect-error */}
    <Route path="/Pedido/Index/:id" element={<ProtectedRoute><TabsPedido /></ProtectedRoute>} />
    <Route path="/Pick/AbastecerWallet" element={<ProtectedRoute><AbastecerWallet /></ProtectedRoute>} />
    <Route path="/Pick/AbastecerRepresentante" element={<ProtectedRoute><AbastecerRepresentante /></ProtectedRoute>} />

    <Route path="/Pontuacao/Index" element={<ProtectedRoute><Pontuacao /></ProtectedRoute>} />

    <Route path="/VagaPadrao/Index" element={<ProtectedRoute><VagaPadrao /></ProtectedRoute>} />

    <Route path='/Segredo/TesteTabela' element={<ProtectedRoute><TesteTabela /></ProtectedRoute>} />

    <Route path="/timeout" element={<TimeoutError />} />
    <Route path="*" element={<NotFound />} />
  </Route>
);

const router = createBrowserRouter(routes);

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <CustomThemeProvider>
        <SnackbarProvider>
          <PaginaProvider>
            <PedidoProvider>
              <PermissionsProvider>
                <PersistGate loading={null} persistor={persistor}>
                  <HubspotConversationsProvider>
                    <GoogleMapsLoaderProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <RouterProvider router={router} >
                        </RouterProvider>
                      </LocalizationProvider>
                    </GoogleMapsLoaderProvider>
                  </HubspotConversationsProvider>
                </PersistGate>
              </PermissionsProvider>
            </PedidoProvider>
          </PaginaProvider>
        </SnackbarProvider>
      </CustomThemeProvider>
    </Provider>
  </React.StrictMode>
);