import { Box, useTheme, Typography, Stack, Checkbox, FormGroup, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { BoxLoading } from 'src/ds';
import { RootState } from 'src/store/reducers';
import { listarLogClear, listarLogStart } from 'src/store/reducers/pedidos'
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';

const LogPedido: React.FC = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { showError } = useSnackbarContext();
    const logsPedido = useSelector((state: RootState) => state.pedidos.log)
    const pedidoID = useSelector((state: RootState) => state.pedidos.pedidoModelo.data?.modelo?.pedidoID);
    const [verbose, setVerbose] = React.useState(false);

    useEffect(() => {
        if (!pedidoID) return
        dispatch(listarLogStart({
            verbose: false,
            storage: true,
            pedidoID
        }))
    }, [pedidoID])

    useEffect(() => {
        if (logsPedido.error) {
            showError({
                message: logsPedido.error,
                duration: 5000
            });
        }

        return () => {
            dispatch(listarLogClear())
        }
    }, [logsPedido.error])

    const handleVerbose = () => {
        if (!pedidoID) return
        dispatch(listarLogStart({
            verbose: !verbose,
            storage: true,
            pedidoID
        }))
        setVerbose(!verbose)
    }

    const handleReload = () => {
        if (!pedidoID) return
        dispatch(listarLogStart({
            verbose,
            storage: true,
            pedidoID
        }))
    }

    return (
        <>
            <BoxLoading
                loading={logsPedido.loading}
                sx={{
                    minHeight: '50vh',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    {logsPedido.data?.logs && logsPedido.data?.logs.length > 0
                        ? logsPedido.data?.logs.map((log, index) => (
                            <Box
                                key={index}
                                sx={{
                                    p: theme.sizes.padding.md,
                                    mb: 2,
                                    borderRadius: 2,
                                    overflow: 'auto',
                                    bgcolor: theme.palette.background.paperDark,
                                }}
                            >
                                <Stack
                                    direction={"row"}
                                    sx={{
                                        justifyContent: "space-between",
                                        gap: 2,
                                    }}
                                >
                                    <Stack direction="row" gap={1} alignItems="center" justifyContent={"center"} sx={{ mb: 1 }}>
                                        <Typography variant="h6">{log.titulo}</Typography>
                                        <Tooltip title="Recarregar" arrow placement='top'>
                                            <IconButton
                                                onClick={handleReload}
                                            >
                                                <ReplayRoundedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={verbose}
                                                    onChange={handleVerbose}
                                                />
                                            }
                                            label="Verbose"
                                        />
                                    </FormGroup>
                                </Stack>
                                <Stack
                                    direction="column"
                                    spacing={1}
                                    sx={{
                                        borderRadius: 2,
                                        p: 1,
                                        bgcolor: theme.palette.grey[200],
                                        ...theme.applyStyles('dark', {
                                            bgcolor: theme.palette.grey[900]
                                        }),
                                    }}
                                >
                                    {log.log.map((l, i) => (
                                        <Typography
                                            variant="monospaced"
                                            sx={{
                                                fontSize: theme.sizes.fontSize.sm,
                                            }}
                                            key={i}
                                        >
                                            {l}
                                        </Typography>
                                    ))}
                                </Stack>
                            </Box>
                        ))
                        : logsPedido.loading
                            ? null
                            : <Typography align='center' variant='notFound'>Sem logs</Typography>}
                </Box>
            </BoxLoading>
        </>
    )
}

export default LogPedido
