// DynamicDataContainer.tsx
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CardFormFiltros, CardFormFiltrosProps, CardFormFiltrosRef } from './CardFormFiltros';
import ListCardTabelaTotal, { ListCardTabelaTotalProps } from './ListCardTabelaTotal';
import TableNavigation, { TableNavigationProps } from './TableNavigation';
import { Box, Card, Stack, Tab, Tabs, useTheme } from '@mui/material';
import MenuTabelas, { MenuTabelasProps } from './MenuTabelas';
import { BoxTableMinHeight } from './styledBoxes';
import { UseFormReturn } from 'react-hook-form';
import { ButtonProps } from '@mui/material/Button/Button';
import ButtonLoading from './ButtonLoading';

interface ExtraButtons {
  label: string;
  action: () => void,
  color?: ButtonProps['color'];
  disabled?: boolean,
  loading?: boolean
}

interface Section {
  label: string;
  component: React.ReactNode;
  updateOnExchange?: boolean;
  tableNavigation?: Omit<TableNavigationProps, 'hookForm'>;
  menu?: MenuTabelasProps;
  extraActions?: React.ReactNode[];
  extraButtons?: ExtraButtons[];
};

interface DynamicDataContainerProps {
  hookForm: UseFormReturn<any>;
  cardFormFiltrosProps?: Omit<CardFormFiltrosProps, 'formMethods'>;
  cardTabelaTotalProps?: ListCardTabelaTotalProps;
  sections?: Section[];
  namePagina?: string;
  namePaginacao?: string;
};

export type DynamicDataContainerRef = CardFormFiltrosRef & {
  changeSection: (section: string) => void
}

const DynamicDataContainer = forwardRef<DynamicDataContainerRef | null, DynamicDataContainerProps>(({
  cardFormFiltrosProps,
  cardTabelaTotalProps,
  sections,
  namePagina = 'pagina',
  namePaginacao = 'paginacao',
  hookForm,
}, ref) => {
  const theme = useTheme();
  const firstRender = useRef(true);

  const cardFormFiltrosRef = React.useRef<DynamicDataContainerRef | null>(null);
  const { watch } = hookForm;

  const pagina = watch(namePagina)
  const paginacao = watch(namePaginacao)

  const [activeSection, setActiveSection] = useState((sections && sections.length > 0) ? sections[0].label.toLowerCase() : '');

  const handleSectionChange = (section: string | null) => {
    if (section === null) return;
    setActiveSection(section);
  };

  useEffect(() => {
    hookForm?.trigger(); // Dispara validação inicial para todos os campos
  }, [hookForm]);

  useEffect(() => {
    const currentSection = sections?.find(section => section.label.toLowerCase() === activeSection)
    if (currentSection?.updateOnExchange) {
      cardFormFiltrosRef.current?.onSubmit()
    }
  }, [activeSection]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return;
    }

    const getValid = async () => {
      const isValid = await hookForm.trigger();
      return isValid
    }

    const handleSubmitIfValid = async () => {
      const isValid = await getValid();
      if (isValid) cardFormFiltrosRef.current?.onSubmit();
    }
    handleSubmitIfValid();
  }, [pagina, paginacao, hookForm]);

  useImperativeHandle(ref, () => ({
    onSubmit: cardFormFiltrosRef.current?.onSubmit ?? (() => { }),
    atualizarDispatch: cardFormFiltrosRef.current?.atualizarDispatch ?? false,
    fezRequisicao: cardFormFiltrosRef.current?.fezRequisicao ?? false,
    changeSection: (section: string) => handleSectionChange(section),
  }));

  return (
    <Box sx={{ p: 2, backgroundColor: theme.palette.background.paperDark }}>
      {cardFormFiltrosProps && <CardFormFiltros {...{ formMethods: hookForm, ...cardFormFiltrosProps }} ref={cardFormFiltrosRef} />}
      {cardTabelaTotalProps && <ListCardTabelaTotal {...cardTabelaTotalProps} />}
      {sections && sections.length > 1 && (
        <Stack sx={{ mt: 4, gap: 2, flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }} direction="row">
          <Tabs
            value={activeSection}
            onChange={(_, value) => handleSectionChange(value)}
            textColor="inherit"
            variant="fullWidth"
          >
            {sections && sections.map((section) => <Tab label={section.label} value={section.label.toLowerCase()} />)}
          </Tabs>
        </Stack>
      )}
      {sections && sections.length > 0 && sections.map((section) => (
        section.label.toLowerCase() === activeSection ? (
          <Card key={section.label} elevation={1} sx={{ mt: 2, borderRadius: 2 }}>
            {(section.menu || section.tableNavigation) ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                {section.extraButtons && (
                  <Stack direction="row" useFlexGap sx={{ flex: '1', flexWrap: 'wrap', justifyContent: 'flex-start', gap: 1 }}>
                    {section.extraButtons.map((button, index) => (
                      <ButtonLoading
                        sx={{ width: 'min-content' }}
                        key={button.label}
                        color={button.color ?? index % 2 === 0 ? 'primary' : 'secondary'}
                        onClick={button.action}
                        loading={button.loading}
                        variant='containedFilled'
                      >
                        {button.label}
                      </ButtonLoading>
                    ))}
                  </Stack>
                )}
                {section.tableNavigation && <Box><TableNavigation {...{ hookForm, ...section.tableNavigation }} /></Box>}
                {section.extraActions && <Stack direction="row" spacing={1}>{section.extraActions}</Stack>}
                {section.menu && <MenuTabelas options={section.menu.options} loading={section.menu.loading} />}
              </Box>
            ) : null}
            <BoxTableMinHeight>
              {section.component}
            </BoxTableMinHeight>
          </Card>
        ) : null
      ))}
    </Box>
  );
})

export default DynamicDataContainer;
