const sanitizeSituacao = (pedido?: { situacao?: string | null } | null) => {
  switch (pedido?.situacao) {
    case "CRIADO":
      return "Criado";
    case "PRONTO":
      return "Pronto";
    case "DESPACHADO":
      return "Despachado";
    case "RECEBIDO":
      return "Recebido";
    case "EM_ROTA":
      return "Em rota";
    case "ACEITO":
      return "Aceito";
    case "NO_ESTABELECIMENTO":
      return "Na origem";
    case "RETORNANDO":
      return "Retornando";
  }
  return pedido?.situacao?.toString() || "";
};

export default sanitizeSituacao;
