import { useForm } from "react-hook-form";
import TabelaEntregadores from "../../components/Entregadores/Tabela";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { listarTipoDeVeiculoStart } from "src/store/reducers/config";
import { DynamicDataContainer } from "src/ds";
import { exportarTabelaEntregadores, listaEntregadoresStart } from "src/store/reducers/entregador";
import { DynamicDataContainerRef } from "src/ds/components/DynamicDataContainer";

const Entregadores = () => {
    const entregadoresDados = useSelector((state: any) => state.entregadores.listaEntregadores?.data?.dados);
    const entregadores = entregadoresDados?.lista;
    const loadingEntregadores = useSelector((state: any) => state.entregadores.listaEntregadores.loading);
    const tiposDeVeiculo = useSelector((state: any) => state.config?.tiposDeVeiculos?.dados?.lista);
    const dispatch = useDispatch();
    const cardRef = useRef<DynamicDataContainerRef>(null);
    const primeiraRenderizacao = useRef(true);

    const hookForm = useForm({
        defaultValues: {
            nome: '',
            cpf: '',
            dtInicial: '',
            dtFinal: '',
            tagsFiltro: '',
            tipoVeiculoID: '',
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '(entregador || dataEntregadorSolicitacao != null)',
            titulos: '',
            variaveis: '',
            formato: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const { watch, getValues } = hookForm;

    useEffect(() => {
        if (primeiraRenderizacao.current === true) {
            cardRef.current?.onSubmit();
            primeiraRenderizacao.current = false
            return;
        }
    }, [primeiraRenderizacao]);

    useEffect(() => {
        dispatch(listaEntregadoresStart({
            ...hookForm.getValues(),
        }));
    }, [watch("paginacao"), watch("pagina")]);

    useEffect(() => {
        dispatch(listarTipoDeVeiculoStart({
            paginacao: -1,
            pagina: 1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }));
    }, []);

    const menuOptions = [
        {
            label: 'Exportar',
            action: () => {
                dispatch(exportarTabelaEntregadores(getValues()))
            }
        },
    ];

    return (
        <DynamicDataContainer
            ref={cardRef}
            hookForm={hookForm}
            cardFormFiltrosProps={{
                dispatchMethods: {
                    actionName: 'listaEntregadoresStart'
                },
                fields: [
                    { label: "Nome do entregador", name: "nome" },
                    { label: "Data inicial", type: "date", name: "dticial" },
                    { label: "Data final", type: "date", name: "dtFinal" },
                    {
                        label: "Tipo de veículo", name: "tipoVeiculoID",
                        type: "customSelect",
                        options: tiposDeVeiculo,
                        valueKey: 'tipoVeiculoID',
                        labelKey: 'nome',
                    },
                    { label: "CPF", name: "cpf" },
                    {
                        label: "Tags",
                        name: "tagsFiltro",
                        type: "tags",
                        filtroInline: true,
                        fullWidth: true,
                    },
                ],
                loading: loadingEntregadores
            }}
            sections={[
                {
                    label: 'Entregadores',
                    tableNavigation: {
                        indiceInicial: entregadoresDados?.indiceInicial,
                        indiceFinal: entregadoresDados?.indiceFinal,
                        indiceTotal: entregadoresDados?.total,
                        ultimaPagina: entregadoresDados?.ultimaPagina || 0
                    },
                    component:
                        <TabelaEntregadores entregadores={entregadores} loading={loadingEntregadores} fezRequisicao={cardRef.current?.fezRequisicao} />
                }
            ]}
        />
    );
};

export default Entregadores;
