import { Box, Button, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalBase, { ModalBaseProps } from 'src/ds/components/ModalBase'
import { ContainerBodyModal, TableNavigation, TableRowLoading, TableRowNotFound } from 'src/ds';
import { RootState } from 'src/store/reducers';
import { listarEntregadoresStart } from 'src/store/reducers/escalas';
import { maskCpf } from 'src/ds';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { Entregador } from 'src/dtos/EntregadoresDTO';

interface ModalPesquisarEntregadorProps {
    callback: (entregador: Entregador) => void;
    modalProps: ModalBaseProps;
}

const ModalPesquisarEntregador = ({
    modalProps,
    callback,
}: ModalPesquisarEntregadorProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const listarEntregadores = useSelector((state: RootState) => state.escalas.listarEntregadores.data);
    const loading = useSelector((state: RootState) => state.escalas.listarEntregadores.loading);

    const hookForm = useForm({
        defaultValues: {
            username: '',
            cpf: '',
            paginacao: 20,
            pagina: 1,
        }
    });
    const {
        handleSubmit,
        watch,
        register,
        setValue,
        formState: { errors },
    } = hookForm;

    const username = watch('username');
    const filtrosRef = useRef('');

    useEffect(() => {
        const form = {
            paginacao: watch('paginacao'),
            filtros: '',
            pagina: watch('pagina'),
        };
        dispatch(listarEntregadoresStart(form));
    }, [])

    const handleFiltros = useCallback(() => {
        let cpf = watch('cpf');

        const filtrosArray = [];
        if (username) { filtrosArray.push(`nome.Contains("${username}")`); }
        if (cpf) { filtrosArray.push(`cpf.Equals("${cpf.replace(/\D/g, '')}")`); }
        const filtrosString = filtrosArray.join(' && ');
        filtrosRef.current = filtrosString;
        const form = {
            paginacao: watch('paginacao'),
            pagina: watch('pagina'),
            filtros: filtrosString || '',
        };
        dispatch(listarEntregadoresStart(form));
    }, [username, watch('cpf')]);

    const onSubmit = () => { handleFiltros() };

    const selecionarEntregador = (entregador: Entregador) => {
        callback(entregador);
        modalProps.onClose();
    }

    useEffect(() => {
        const subscribe = watch((_, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                onSubmit();
            }
        })
        return () => subscribe.unsubscribe();
    }, [watch])

    return (
        <ModalBase
            {...modalProps}
            headerModal={{ title: 'Entregadores' }}
        >
            <ContainerBodyModal>
                <form
                    id="formEntregadores"
                    style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack direction='row' useFlexGap sx={{ gap: 2, width: '100%', flexWrap: 'wrap' }} >
                        <TextField
                            {...register('username')}
                            label='Nome'
                            fullWidth
                            size='small'
                            sx={{ flex: '1 1 250px' }}
                        />
                        <TextField
                            {...register('cpf', { maxLength: 14 })}
                            error={!!errors.cpf}
                            helperText={errors.cpf?.message || ''}
                            value={maskCpf(watch('cpf'))}
                            onChange={(e) => {
                                const valorLimpo = e.target.value.replace(/\D/g, '');
                                if (valorLimpo.length <= 11) { setValue('cpf', valorLimpo); }
                            }}
                            label={'CPF'}
                            fullWidth
                            slotProps={{ htmlInput: { maxLength: 14 } }}
                            size='small'
                            sx={{ flex: '1 1 250px' }}
                        />
                    </Stack>
                    <Stack>
                        <Button type="submit" variant='iconeComTextoFilled' sx={{ width: 'fit-content', placeSelf: 'flex-end' }}>
                            Filtrar
                        </Button>
                    </Stack>
                </form>
                {listarEntregadores?.dados?.lista &&
                    <TableNavigation
                        indiceInicial={listarEntregadores?.dados?.indiceInicial}
                        indiceFinal={listarEntregadores?.dados?.indiceInicial}
                        indiceTotal={listarEntregadores?.dados?.total}
                        ultimaPagina={listarEntregadores?.dados?.ultimaPagina}
                        podeTodos={false}
                        hookForm={hookForm}
                    />
                }
                <Box sx={{ height: '100%', width: '100%' }}>
                    <TableContainer
                        sx={{
                            borderRadius: '.5rem',
                            overflowY: 'auto',
                        }}
                    >
                        <Table aria-label="Table" stickyHeader>
                            <TableHead
                                sx={{
                                    bgcolor: theme.palette.background.default,
                                    borderRadius: '.5rem',
                                    boxShadow: '0 0 8px  rgba(0, 0, 0, 0.2)',
                                }}
                            >
                                <TableRow>
                                    <TableCell align="center">CPF</TableCell>
                                    <TableCell align="center">Nome</TableCell>
                                    <TableCell align="center">Ação</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody
                                sx={{
                                    '& tr': {
                                        height: '100%',
                                    },
                                }}
                            >
                                {loading
                                    ? <TableRowLoading />
                                    : listarEntregadores?.dados?.lista && listarEntregadores.dados.lista.length > 0
                                        // @ts-expect-error 
                                        ? listarEntregadores?.dados?.lista.map((row) => (
                                            <TableRow hover key={row.id}>
                                                <TableCell align="center">{row.cpf ? maskCpf(row.cpf) : '-'}</TableCell>
                                                <TableCell align="center">{row.nome}</TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Selecionar Entregador" arrow placement='top'>
                                                        <IconButton
                                                            onClick={() => { selecionarEntregador(row) }}
                                                            color='primary'
                                                        >
                                                            <CheckCircleOutlineRoundedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        : <TableRowNotFound />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </ContainerBodyModal>
        </ModalBase >
    )
}

export default ModalPesquisarEntregador
