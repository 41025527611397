import { Box, Button, Typography, Grid, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ModalBase } from "src/ds";
import { listarEntregadoresRequest } from "src/store/reducers/config";
import { Entregador } from "src/dtos/EntregadoresDTO";
import { select } from "redux-saga/effects";

interface ModalSelectEntregadorProps {
    open: boolean;
    handleClose: () => void;
    callback: any;
}

const ModalSelectEntregador: React.FC<ModalSelectEntregadorProps> = ({ open, handleClose, callback }) => {
    const dispatch = useDispatch();
    const usuario = useSelector((state: any) => state.login.usuario);
    const entregadores = useSelector((state: any) => state.config.entregadores.lista);
    const [selectedEntregador, setSelectedEntregador] = useState<Entregador | null>(null);
    useEffect(() => {
        if (usuario?.id) {
            dispatch(listarEntregadoresRequest([usuario.id]));
        }
    }, [usuario?.id]);

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const selectedEntregador = entregadores.find(
            (entregador: Entregador) => entregador.usuarioID === event.target.value
        );
        setSelectedEntregador(selectedEntregador);
    };

    return (
        <ModalBase
            open={open}
            onClose={handleClose}
            size="sm"
            headerModal={{ title: "Selecione um entregador", onClose: handleClose }}
        >
            <Box m={2}>
                <Box py={1} flex={1}>
                    <Select
                        fullWidth
                        defaultValue=""
                        size="small"
                        onChange={handleSelectChange}
                        displayEmpty
                        inputProps={{
                            name: "entregador",
                            id: "entregador",
                        }}
                    >
                        <MenuItem value="" disabled>
                            Selecione
                        </MenuItem>
                        {entregadores?.map((entregador: Entregador) => (
                            <MenuItem key={entregador.usuarioID} value={entregador.usuarioID}>
                                {entregador.nome}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="containedFilled"
                            color="primary"
                            onClick={() => {
                                callback(selectedEntregador)
                                handleClose();
                            }}
                        >
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </ModalBase>
    );
};

export default ModalSelectEntregador;
