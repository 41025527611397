import React, { useState, MouseEvent } from "react";
import { CircularProgress, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface MenuOption {
  label: string;
  action: () => void;
  hasDivider?: boolean;
}

export interface MenuTabelasProps {
  options: MenuOption[];
  loading?: boolean
}

const MenuTabelas: React.FC<MenuTabelasProps> = ({ options, loading }) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen} disabled={loading}>
        {loading
          ? <CircularProgress size={24} />
          : <MoreVertIcon color="primary" />}
      </IconButton>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            minWidth: "150px",
          },
        }}
      >
        {options.map((option, index) => (
          <>
            {option.hasDivider && <Divider />}
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuClose();
                if (option.action) option.action();
              }}
              sx={{
                justifyContent: "flex-start",
                textAlign: "left",
                padding: "8px 16px",
              }}
            >
              {option.label}
            </MenuItem>
          </>
        ))}
      </Menu>
    </>
  );
};

export default MenuTabelas;
