import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AlterarDataDTO,
  CriarVagaDTO,
  DesassociarEntregadorDTO,
  EmpresasVinculadasDTO,
  EscalaDTO,
  ExcluirVagaDTO,
  ListarVagasEmpresaSelecionadaDTO,
  ListarVagasEmpresaSelecionadaPayload,
  PaginaEscalaDTO,
  VagaPadraoDTO,
  VagaPadraoExcluirDTO,
  VagaPadraoSalvarDTO,
  VagaPadraoSalvarPayload,
} from "../../dtos/EscalasDTO";
import { DefaultAsyncState } from ".";
import { TipoVeiculoDTO } from "src/dtos/TipoVeiculosDTO";
import { ExcluirEmpresaVinculadaDTO, SalvarEmpresaVinculadaDTO } from "src/dtos/BotsDTO";

interface EscalasState {
  listaEscalas: DefaultAsyncState<EscalaDTO>;
  paginaEscala: DefaultAsyncState<PaginaEscalaDTO>;
  iniciarJornada: DefaultAsyncState<any>;
  finalizarJornada: DefaultAsyncState<any>;
  excluirJornada: DefaultAsyncState<ExcluirVagaDTO>;
  listarVeiculos: DefaultAsyncState<TipoVeiculoDTO>;
  listarEntregadores: DefaultAsyncState<any>;
  criarVaga: DefaultAsyncState<CriarVagaDTO>;
  desassociarEntregador: DefaultAsyncState<DesassociarEntregadorDTO>;
  alterarData: DefaultAsyncState<AlterarDataDTO>;
  vagaPadrao: DefaultAsyncState<VagaPadraoDTO>;
  vagaPadraoSalvar: DefaultAsyncState<VagaPadraoSalvarDTO>;
  vagaPadraoExcluir: DefaultAsyncState<VagaPadraoExcluirDTO>;
  empresasVinculadas: DefaultAsyncState<EmpresasVinculadasDTO>;
  excluirEmpresaVinculada: DefaultAsyncState<ExcluirEmpresaVinculadaDTO>;
  salvarEmpresaVinculada: DefaultAsyncState<SalvarEmpresaVinculadaDTO>;
  listarVagasEmpresaSelecionada: DefaultAsyncState<ListarVagasEmpresaSelecionadaDTO>;
}

const partialInitialState = {
  loading: false,
  data: null,
  error: null,
};

const initialState: EscalasState = {
  listaEscalas: { ...partialInitialState },
  paginaEscala: { ...partialInitialState },
  iniciarJornada: { ...partialInitialState },
  finalizarJornada: { ...partialInitialState },
  excluirJornada: { ...partialInitialState },
  listarVeiculos: { ...partialInitialState },
  listarEntregadores: { ...partialInitialState },
  criarVaga: { ...partialInitialState },
  desassociarEntregador: { ...partialInitialState },
  alterarData: { ...partialInitialState },
  vagaPadrao: { ...partialInitialState },
  vagaPadraoSalvar: { ...partialInitialState },
  vagaPadraoExcluir: { ...partialInitialState },
  empresasVinculadas: { ...partialInitialState },
  excluirEmpresaVinculada: { ...partialInitialState },
  salvarEmpresaVinculada: { ...partialInitialState },
  listarVagasEmpresaSelecionada: { ...partialInitialState },
  // generic: { ...partialInitialState },
};

export const escalasSlice = createSlice({
  name: "escalas",
  initialState: initialState,
  reducers: {
    escalasStart: (state, action) => {
      state.listaEscalas.loading = true;
      state.listaEscalas.data = null;
      state.listaEscalas.error = null;
    },
    escalasSuccess: (state, action) => {
      state.listaEscalas.loading = false;
      state.listaEscalas.data = action.payload;
      state.listaEscalas.error = null;
    },
    escalasFailure: (state, action) => {
      state.listaEscalas.loading = false;
      state.listaEscalas.data = null;
      state.listaEscalas.error = action.payload;
    },
    paginaEscalaStart: (state, action) => {
      state.paginaEscala.loading = true;
      state.paginaEscala.data = null;
      state.paginaEscala.error = null;
    },
    paginaEscalaSuccess: (state, action) => {
      state.paginaEscala.loading = false;
      state.paginaEscala.data = action.payload;
      state.paginaEscala.error = null;
    },
    paginaEscalaFailure: (state, action) => {
      state.paginaEscala.loading = false;
      state.paginaEscala.data = null;
      state.paginaEscala.error = action.payload;
    },
    paginaEscalaClear: (state) => {
      state.paginaEscala.loading = false;
      state.paginaEscala.data = null;
      state.paginaEscala.error = null;
    },
    iniciarJornadaStart: (state, action) => {
      state.iniciarJornada.loading = true;
      state.iniciarJornada.data = null;
      state.iniciarJornada.error = null;
    },
    iniciarJornadaSuccess: (state, action) => {
      state.iniciarJornada.loading = false;
      state.iniciarJornada.data = action.payload;
      state.iniciarJornada.error = null;
    },
    iniciarJornadaFailure: (state, action) => {
      state.iniciarJornada.loading = false;
      state.iniciarJornada.data = null;
      state.iniciarJornada.error = action.payload;
    },
    iniciarJornadaClear: (state) => {
      state.iniciarJornada.loading = false;
      state.iniciarJornada.data = null;
      state.iniciarJornada.error = null;
    },
    finalizarJornadaStart: (state, action) => {
      state.finalizarJornada.loading = true;
      state.finalizarJornada.data = null;
      state.finalizarJornada.error = null;
    },
    finalizarJornadaSuccess: (state, action) => {
      state.finalizarJornada.loading = false;
      state.finalizarJornada.data = action.payload;
      state.finalizarJornada.error = null;
    },
    finalizarJornadaFailure: (state, action) => {
      state.finalizarJornada.loading = false;
      state.finalizarJornada.data = null;
      state.finalizarJornada.error = action.payload;
    },
    finalizarJornadaClear: (state) => {
      state.finalizarJornada.loading = false;
      state.finalizarJornada.data = null;
      state.finalizarJornada.error = null;
    },
    excluirJornadaStart: (state, action) => {
      state.excluirJornada.loading = true;
      state.excluirJornada.data = null;
      state.excluirJornada.error = null;
    },
    excluirJornadaSuccess: (state, action) => {
      state.excluirJornada.loading = false;
      state.excluirJornada.data = action.payload;
      state.excluirJornada.error = null;
    },
    excluirJornadaFailure: (state, action) => {
      state.excluirJornada.loading = false;
      state.excluirJornada.data = null;
      state.excluirJornada.error = action.payload;
    },
    excluirJornadaClear: (state) => {
      state.excluirJornada = { ...initialState.excluirJornada };
    },
    limparEscalas: (state) => {
      state.listaEscalas.data = null;
      state.iniciarJornada.data = null;
      state.finalizarJornada.data = null;
      state.excluirJornada.data = null;
    },
    listarVeiculosStart: (state) => {
      state.listarVeiculos.loading = true;
      state.listarVeiculos.data = null;
      state.listarVeiculos.error = null;
    },
    listarVeiculosSuccess: (state, action) => {
      state.listarVeiculos.loading = false;
      state.listarVeiculos.data = action.payload;
      state.listarVeiculos.error = null;
    },
    listarVeiculosFailure: (state, action) => {
      state.listarVeiculos.loading = false;
      state.listarVeiculos.data = null;
      state.listarVeiculos.error = action.payload;
    },
    listarEntregadoresStart: (state, action) => {
      state.listarEntregadores.loading = true;
      state.listarEntregadores.data = null;
      state.listarEntregadores.error = null;
    },
    listarEntregadoresSuccess: (state, action) => {
      state.listarEntregadores.loading = false;
      state.listarEntregadores.data = action.payload;
      state.listarEntregadores.error = null;
    },
    listarEntregadoresFailure: (state, action) => {
      state.listarEntregadores.loading = false;
      state.listarEntregadores.data = null;
      state.listarEntregadores.error = action.payload;
    },
    criarVagaStart: (state, action) => {
      state.criarVaga.loading = true;
      state.criarVaga.data = null;
      state.criarVaga.error = null;
    },
    criarVagaSuccess: (state, action) => {
      state.criarVaga.loading = false;
      state.criarVaga.data = action.payload;
      state.criarVaga.error = null;
    },
    criarVagaFailure: (state, action) => {
      state.criarVaga.loading = false;
      state.criarVaga.data = null;
      state.criarVaga.error = action.payload;
    },
    limparCriarVaga: (state) => {
      state.criarVaga = { ...initialState.criarVaga };
    },
    desassociarEntregadorStart: (
      state,
      action: PayloadAction<{ pedidoID: string }>
    ) => {
      state.desassociarEntregador.loading = true;
      state.desassociarEntregador.data = null;
      state.desassociarEntregador.error = null;
    },
    desassociarEntregadorFailure: (state, action) => {
      state.desassociarEntregador.loading = false;
      state.desassociarEntregador.data = null;
      state.desassociarEntregador.error = action.payload;
    },
    desassociarEntregadorSuccess: (state, action) => {
      state.desassociarEntregador.loading = false;
      state.desassociarEntregador.data = action.payload;
      state.desassociarEntregador.error = null;
    },
    desassociarEntregadorClear: (state) => {
      state.desassociarEntregador = { ...initialState.desassociarEntregador };
    },
    alterarDataStart: (
      state,
      action: PayloadAction<{
        pedidoID: string;
        dataAgendamento: string;
        dataConcluido: string;
      }>
    ) => {
      state.alterarData.loading = true;
      state.alterarData.data = null;
      state.alterarData.error = null;
    },
    alterarDataSuccess: (state, action) => {
      state.alterarData.loading = false;
      state.alterarData.data = action.payload;
      state.alterarData.error = null;
    },
    alterarDataFailure: (state, action) => {
      state.alterarData.loading = false;
      state.alterarData.data = null;
      state.alterarData.error = action.payload;
    },
    alterarDataClear: (state) => {
      state.alterarData = { ...initialState.alterarData };
    },
    vagaPadraoStart: (state, action: PayloadAction<any>) => {
      state.vagaPadrao.loading = true;
      state.vagaPadrao.data = null;
      state.vagaPadrao.error = null;
    },
    vagaPadraoFailure: (state, action) => {
      state.vagaPadrao.loading = false;
      state.vagaPadrao.data = null;
      state.vagaPadrao.error = action.payload;
    },
    vagaPadraoSuccess: (state, action) => {
      state.vagaPadrao.loading = false;
      state.vagaPadrao.data = action.payload;
      state.vagaPadrao.error = null;
    },
    vagaPadraoSalvarStart: (state, action: PayloadAction<VagaPadraoSalvarPayload>) => {
      state.vagaPadraoSalvar.loading = true;
      state.vagaPadraoSalvar.data = null;
      state.vagaPadraoSalvar.error = null;
    },
    vagaPadraoSalvarFailure: (state, action) => {
      state.vagaPadraoSalvar.loading = false;
      state.vagaPadraoSalvar.data = null;
      state.vagaPadraoSalvar.error = action.payload;
    },
    vagaPadraoSalvarSuccess: (state, action) => {
      state.vagaPadraoSalvar.loading = false;
      state.vagaPadraoSalvar.data = action.payload;
      state.vagaPadraoSalvar.error = null;
    },
    vagaPadraoSalvarClear: (state) => {
      state.vagaPadraoSalvar.loading = false;
      state.vagaPadraoSalvar.data = null;
      state.vagaPadraoSalvar.error = null;
    },
    vagaPadraoExcluirStart: (state, action: PayloadAction<any>) => {
      state.vagaPadraoExcluir.loading = true;
      state.vagaPadraoExcluir.data = null;
      state.vagaPadraoExcluir.error = null;
    },
    vagaPadraoExcluirFailure: (state, action) => {
      state.vagaPadraoExcluir.loading = false;
      state.vagaPadraoExcluir.data = null;
      state.vagaPadraoExcluir.error = action.payload;
    },
    vagaPadraoExcluirSuccess: (state, action) => {
      state.vagaPadraoExcluir.loading = false;
      state.vagaPadraoExcluir.data = action.payload;
      state.vagaPadraoExcluir.error = null;
    },
    vagaPadraoExcluirClear: (state) => {
      state.vagaPadraoExcluir.loading = false;
      state.vagaPadraoExcluir.data = null;
      state.vagaPadraoExcluir.error = null;
    },
    empresasVinculadasStart: (state, action) => {
      state.empresasVinculadas.loading = true;
      state.empresasVinculadas.data = null;
      state.empresasVinculadas.error = null;
    },
    empresasVinculadasFailure: (state, action) => {
      state.empresasVinculadas.loading = false;
      state.empresasVinculadas.data = null;
      state.empresasVinculadas.error = action.payload;
    },
    empresasVinculadasSuccess: (state, action) => {
      state.empresasVinculadas.loading = false;
      state.empresasVinculadas.data = action.payload;
      state.empresasVinculadas.error = null;
    },
    excluirEmpresaVinculadaStart: (state, action: PayloadAction<any>) => {
      state.excluirEmpresaVinculada.loading = true;
      state.excluirEmpresaVinculada.data = null;
      state.excluirEmpresaVinculada.error = null;
    },
    excluirEmpresaVinculadaFailure: (state, action) => {
      state.excluirEmpresaVinculada.loading = false;
      state.excluirEmpresaVinculada.data = null;
      state.excluirEmpresaVinculada.error = action.payload;
    },
    excluirEmpresaVinculadaSuccess: (state, action) => {
      state.excluirEmpresaVinculada.loading = false;
      state.excluirEmpresaVinculada.data = action.payload;
      state.excluirEmpresaVinculada.error = null;
    },
    excluirEmpresaVinculadaClear: (state) => {
      state.excluirEmpresaVinculada.loading = false;
      state.excluirEmpresaVinculada.data = null;
      state.excluirEmpresaVinculada.error = null;
    },
    salvarEmpresaVinculadaStart: (state, action: PayloadAction<any>) => {
      state.salvarEmpresaVinculada.loading = true;
      state.salvarEmpresaVinculada.data = null;
      state.salvarEmpresaVinculada.error = null;
    },
    salvarEmpresaVinculadaFailure: (state, action) => {
      state.salvarEmpresaVinculada.loading = false;
      state.salvarEmpresaVinculada.data = null;
      state.salvarEmpresaVinculada.error = action.payload;
    },
    salvarEmpresaVinculadaSuccess: (state, action) => {
      state.salvarEmpresaVinculada.loading = false;
      state.salvarEmpresaVinculada.data = action.payload;
      state.salvarEmpresaVinculada.error = null;
    },
    salvarEmpresaVinculadaClear: (state) => {
      state.salvarEmpresaVinculada.loading = false;
      state.salvarEmpresaVinculada.data = null;
      state.salvarEmpresaVinculada.error = null;
    },
    listarVagasEmpresaSelecionadaStart: (state, action: PayloadAction<ListarVagasEmpresaSelecionadaPayload>) => {
      state.listarVagasEmpresaSelecionada.loading = true;
      state.listarVagasEmpresaSelecionada.data = null;
      state.listarVagasEmpresaSelecionada.error = null;
    },
    listarVagasEmpresaSelecionadaFailure: (state, action) => {
      state.listarVagasEmpresaSelecionada.loading = false;
      state.listarVagasEmpresaSelecionada.data = null;
      state.listarVagasEmpresaSelecionada.error = action.payload;
    },
    listarVagasEmpresaSelecionadaSuccess: (state, action) => {
      state.listarVagasEmpresaSelecionada.loading = false;
      state.listarVagasEmpresaSelecionada.data = action.payload;
      state.listarVagasEmpresaSelecionada.error = null;
    },
    listarVagasEmpresaSelecionadaClear: (state) => {
      state.listarVagasEmpresaSelecionada.loading = false;
      state.listarVagasEmpresaSelecionada.data = null;
      state.listarVagasEmpresaSelecionada.error = null;
    },
    // genericStart: (state, action: PayloadAction<any>) => {
    //   state.generic.loading = true;
    //   state.generic.data = null;
    //   state.generic.error = null;
    // },
    // genericFailure: (state, action) => {
    //   state.generic.loading = false;
    //   state.generic.data = null;
    //   state.generic.error = action.payload;
    // },
    // genericSuccess: (state, action) => {
    //   state.generic.loading = false;
    //   state.generic.data = action.payload;
    //   state.generic.error = null;
    // },
    // genericClear: (state) => {
    //   state.generic.loading = false;
    //   state.generic.data = null;
    //   state.generic.error = null;
    // },
  },
});


export const {
  escalasStart,
  escalasSuccess,
  escalasFailure,
  paginaEscalaStart,
  paginaEscalaSuccess,
  paginaEscalaFailure,
  paginaEscalaClear,
  iniciarJornadaStart,
  iniciarJornadaSuccess,
  iniciarJornadaFailure,
  iniciarJornadaClear,
  finalizarJornadaStart,
  finalizarJornadaSuccess,
  finalizarJornadaFailure,
  finalizarJornadaClear,
  excluirJornadaStart,
  excluirJornadaSuccess,
  excluirJornadaFailure,
  excluirJornadaClear,
  limparEscalas,
  listarVeiculosStart,
  listarVeiculosSuccess,
  listarVeiculosFailure,
  listarEntregadoresStart,
  listarEntregadoresSuccess,
  listarEntregadoresFailure,
  criarVagaStart,
  criarVagaSuccess,
  criarVagaFailure,
  limparCriarVaga,
  desassociarEntregadorStart,
  desassociarEntregadorSuccess,
  desassociarEntregadorFailure,
  desassociarEntregadorClear,
  alterarDataStart,
  alterarDataSuccess,
  alterarDataFailure,
  alterarDataClear,
  vagaPadraoStart,
  vagaPadraoSuccess,
  vagaPadraoFailure,
  vagaPadraoSalvarStart,
  vagaPadraoSalvarSuccess,
  vagaPadraoSalvarFailure,
  vagaPadraoSalvarClear,
  vagaPadraoExcluirStart,
  vagaPadraoExcluirSuccess,
  vagaPadraoExcluirFailure,
  vagaPadraoExcluirClear,
  empresasVinculadasStart,
  empresasVinculadasSuccess,
  empresasVinculadasFailure,
  excluirEmpresaVinculadaStart,
  excluirEmpresaVinculadaSuccess,
  excluirEmpresaVinculadaFailure,
  excluirEmpresaVinculadaClear,
  salvarEmpresaVinculadaStart,
  salvarEmpresaVinculadaSuccess,
  salvarEmpresaVinculadaFailure,
  salvarEmpresaVinculadaClear,
  listarVagasEmpresaSelecionadaStart,
  listarVagasEmpresaSelecionadaSuccess,
  listarVagasEmpresaSelecionadaFailure,
  listarVagasEmpresaSelecionadaClear,
  // genericStart,
  // genericSuccess,
  // genericFailure,
  // genericClear,
} = escalasSlice.actions;
export default escalasSlice;
