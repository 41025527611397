// @ts-nocheck
import { useState, useEffect } from "react";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { formatarCEP } from "../../../ds/common";
import { MapWithMarker } from "src/ds";
import { AutocompleteMap, CardPedidoContent } from "src/ds";

const EnderecoDeEntrega = ({
    setPosition, position,
    register, errors, watch, setValue, getValues, formState, control
}) => {
    // entregaEndereco
    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const [autocompleteService, setAutocompleteService] = useState(null);
    const [dados, setDados] = useState(getValues());

    useEffect(() => {
        if (dados?.entregaEndereco) {
            if (dados.entregaEndereco.latitude && dados.entregaEndereco.longitude) {
                setPosition({
                    latitude: dados.entregaEndereco.latitude,
                    longitude: dados.entregaEndereco.longitude
                });
            }
            setIsModelLoaded(true);
        }
    }, [dados]);

    useEffect(() => {
        if (position.latitude && position.longitude) {
            setValue('entregaEndereco.latitude', position.latitude)
            setValue('entregaEndereco.longitude', position.longitude)
        }
    }, [position]);

    useEffect(() => {
        if (autocompleteService) {
            setValue('entregaEndereco.CEP', autocompleteService.cep)
            setValue('entregaEndereco.complemento', autocompleteService.complemento)
            setValue('entregaEndereco.logradouro', autocompleteService.logradouro)
            setValue('entregaEndereco.bairro', autocompleteService.bairro)
        }
    }, [autocompleteService]);




    return (
        <CardPedidoContent>
            <Typography variant="h6">Endereço de entrega</Typography>
            <div style={{ marginTop: '1rem' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="entregaEndereco.logradouro"
                            rules={{ required: 'Logradouro é obrigatório' }}
                            render={({ field: { onChange, value, ref } }) => (
                                <AutocompleteMap
                                    setPosition={setPosition}
                                    setFormState={setAutocompleteService}
                                    isModelLoaded={isModelLoaded}
                                    size="small"
                                    InputProps={{
                                        error: !!errors.entregaEndereco?.logradouro,
                                        helperText: errors.entregaEndereco?.logradouro?.message,
                                    }}
                                    valueController={value}
                                    onValueChangeController={onChange}
                                    inputRef={ref}
                                />
                            )}
                        />


                    </Grid>
                    <Grid item xs={12} >
                        <Stack direction={'row'} spacing={2} flexWrap={'wrap'} useFlexGap>
                            <TextField
                                label="CEP"
                                variant="outlined"
                                sx={{ flex: '1 1 150px' }}
                                size="small"
                                {...register('entregaEndereco.CEP')}
                                value={formatarCEP(watch('entregaEndereco.CEP'))}
                                InputLabelProps={{ shrink: (watch('entregaEndereco.CEP') || '').trim() !== '' }}
                                inputProps={{ maxLength: 8 }}
                            />
                            <TextField
                                label="Complemento"
                                variant="outlined"
                                size="small"
                                {...register('entregaEndereco.complemento')}
                                sx={{ flex: '1 1 200px' }}
                                InputLabelProps={{ shrink: (watch('entregaEndereco.complemento') || '').trim() !== '' }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Ponto de referência"
                            variant="outlined"
                            size="small"
                            {...register('entregaEndereco.pontoReferencia')}
                            fullWidth
                            InputLabelProps={{ shrink: (watch('entregaEndereco.pontoReferencia') || '').trim() !== '' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Bairro"
                            variant="outlined"
                            size="small"
                            {...register('entregaEndereco.bairro')}
                            fullWidth
                            InputLabelProps={{ shrink: (watch('entregaEndereco.bairro') || '').trim() !== '' }}
                        />
                    </Grid>
                </Grid>
            </div>
            <Typography variant="body1" mt={2}>Coordenadas</Typography>
            <Grid container spacing={0} style={{ marginTop: '1rem' }}>
                <MapWithMarker position={position} setPosition={setPosition} />
            </Grid>
        </CardPedidoContent>
    );
}

export default EnderecoDeEntrega;
