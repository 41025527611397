import { Box, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteEmpresas, BoxLoading, ButtonLoading, ContainerBodyModal, ModalBase, ModalOpcoes, TableRowLoading, TableRowNotFound, TableRowOrdenacao } from 'src/ds';
import { RootState } from 'src/store/reducers';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { empresasVinculadasStart, excluirEmpresaVinculadaClear, excluirEmpresaVinculadaStart, salvarEmpresaVinculadaClear, salvarEmpresaVinculadaStart } from 'src/store/reducers/escalas';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { useForm } from 'react-hook-form';

interface ModalEmpresasVinculadasProps {
    vagaPadraoID: string;
    open: boolean;
    onClose: () => void;
    callback?: () => void;
}

const ModalEmpresasVinculadas: React.FC<ModalEmpresasVinculadasProps> = ({
    vagaPadraoID,
    open,
    onClose,
    callback
}) => {
    const hookForm = useForm();
    const { showSuccess, showError } = useSnackbarContext()
    const dispatch = useDispatch();
    const empresasVinculadas = useSelector((state: RootState) => state.escalas.empresasVinculadas);
    const excluirEmpresaVinculada = useSelector((state: RootState) => state.escalas.excluirEmpresaVinculada);
    const salvarEmpresaVinculada = useSelector((state: RootState) => state.escalas.salvarEmpresaVinculada);
    const [empresaSelecionada, setEmpresaSelecionada] = useState<null | { id: string, nome: string }>(null);
    const [modalSalvarEmpresa, setModalSalvarEmpresa] = useState(false);

    const [ordenacaoOrdem, setOrdenacaoOrdem] = useState<string>('');
    const [ordenacaoCampo, setOrdenacaoCampo] = useState<string>('');

    function handleSubmit() {
        dispatch(empresasVinculadasStart({ ordenacaoOrdem, ordenacaoCampo, vagaPadraoID }));
    }

    useEffect(() => {
        setOrdenacaoOrdem('');
        setOrdenacaoCampo('');
        handleSubmit();
    }, [vagaPadraoID, dispatch]);

    const handleSortChange = (column: string, direction: string) => {
        setOrdenacaoOrdem(direction);
        setOrdenacaoCampo(column);
        handleSubmit()
    };

    useEffect(() => {
        if (empresasVinculadas.error) {
            showError({
                message: empresasVinculadas.error,
                duration: 5000
            });
        }
    }, [empresasVinculadas.error]);

    useEffect(() => {
        if (excluirEmpresaVinculada.data) {
            showSuccess({
                message: excluirEmpresaVinculada.data.mensagem,
                duration: 5000
            })
            handleSubmit();
            callback?.()
            setEmpresaSelecionada(null);
        } else if (excluirEmpresaVinculada.error) {
            showError({
                message: excluirEmpresaVinculada.error,
                duration: 5000
            });
        }
        return () => {
            dispatch(excluirEmpresaVinculadaClear());
        }
    }, [excluirEmpresaVinculada.error, excluirEmpresaVinculada.data]);

    useEffect(() => {
        if (salvarEmpresaVinculada.data) {
            showSuccess({
                message: salvarEmpresaVinculada.data.mensagem,
                duration: 5000
            })
            handleSubmit();
            setModalSalvarEmpresa(false);
            callback?.()
        } else if (salvarEmpresaVinculada.error) {
            showError({
                message: salvarEmpresaVinculada.error,
                duration: 5000
            });
        }
        return () => {
            dispatch(salvarEmpresaVinculadaClear());
        }
    }, [salvarEmpresaVinculada.error, salvarEmpresaVinculada.data]);

    const cells = React.useMemo(() => [
        { label: 'Nome', name: 'nome' },
        { label: 'Ações' },
    ], []);

    return (
        <>
            <ModalBase
                onClose={onClose}
                open={open}
                headerModal={{ title: 'Empresas vinculadas' }}
            >
                <ContainerBodyModal>
                    <ButtonLoading
                        color='primary'
                        variant='containedFilled'
                        onClick={() => { setModalSalvarEmpresa(true) }}
                    >
                        Adicionar Empresa
                    </ButtonLoading>
                    <Table>
                        <TableHead>
                            <TableRowOrdenacao
                                onSortChange={handleSortChange}
                                cells={cells}
                            />
                        </TableHead>
                        <TableBody>
                            {empresasVinculadas.loading
                                ? <TableRowLoading />
                                : empresasVinculadas?.data?.dados?.lista && empresasVinculadas?.data?.dados?.lista.length > 0
                                    ? empresasVinculadas?.data?.dados?.lista.map(item => (
                                        <TableRow key={item.empresaID}>
                                            <TableCell align='center'>
                                                {item.nome}
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Stack direction='row' justifyContent={'center'}>
                                                    <Tooltip title='Excluir' arrow placement='top'>
                                                        <IconButton
                                                            size='small'
                                                            color='error'
                                                            onClick={() => { setEmpresaSelecionada({ id: item.empresaID, nome: item.nome }) }}
                                                        >
                                                            <DeleteRoundedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    : (
                                        <TableRowNotFound />
                                    )}
                        </TableBody>
                    </Table>
                </ContainerBodyModal>
            </ModalBase>
            {empresaSelecionada?.id && (
                <ModalOpcoes
                    open={empresaSelecionada?.id !== null}
                    onClose={() => setEmpresaSelecionada(null)}
                    acao='Desvincular empresa'
                    descricao={<Typography>Deseja realmente desvincular a empresa <strong>{empresaSelecionada?.nome}</strong> ?</Typography>}
                    opcoes={[
                        {
                            label: 'Cancelar',
                            action() { setEmpresaSelecionada(null) }
                        }, {
                            label: 'Confirmar',
                            action() {
                                dispatch(excluirEmpresaVinculadaStart({ empresaID: empresaSelecionada?.id, vagaPadraoID }));
                            },
                            loading: excluirEmpresaVinculada.loading
                        }
                    ]}
                />
            )}
            {modalSalvarEmpresa &&
                <ModalOpcoes
                    open={modalSalvarEmpresa}
                    onClose={() => setModalSalvarEmpresa(false)}
                    acao='Vincular empresa'
                    descricao={(
                        <Box sx={{ mt: 2 }}>
                            <AutocompleteEmpresas
                                hookForm={{
                                    methods: hookForm,
                                    name: 'empresaID',
                                    rules: { required: 'Campo obrigatório' },
                                }}
                                sx={{ width: '100%' }}
                                label='Empresa'
                            />
                        </Box>
                    )}
                    opcoes={[
                        {
                            label: 'Cancelar',
                            action: () => setModalSalvarEmpresa(false)
                        },
                        {
                            label: 'Confirmar',
                            action() {
                                dispatch(salvarEmpresaVinculadaStart({ empresaID: hookForm.watch('empresaID'), vagaPadraoID }));
                            },
                            disabled: !hookForm.watch('empresaID'),
                            loading: salvarEmpresaVinculada.loading
                        },
                    ]}
                />
            }
        </>
    );
};

export default ModalEmpresasVinculadas;
