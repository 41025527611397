// SnackbarAlertContext.tsx
import { Alert, AlertTitle, Box, IconButton, LinearProgress, Portal, Slide, Snackbar, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';

interface SnackbarAlertContextProps {
  open: boolean;
  onClose: () => void;
  message?: string;
  duration?: number;
  severity: 'success' | 'info' | 'warning' | 'error';
  title?: string;
  containerRef?: React.RefObject<HTMLDivElement | null>;
}

const SnackbarAlertContext = ({
  message = '',
  open,
  duration = 0,
  severity,
  title,
  onClose,
  containerRef,
}: SnackbarAlertContextProps) => {
  const [progress, setProgress] = useState(100);
  const theme = useTheme();

  useEffect(() => {
    if (!open || duration === 0) {
      setProgress(100);
      return;
    }

    const interval = 100;
    const step = (interval / duration) * 100;

    const timer = setInterval(() => {
      setProgress((prev) => {
        const newProgress = Math.max(prev - step, 0)
        if (newProgress <= 0) setTimeout(() => { onClose() }, 500)
        //* Quando snackbar em hover, as vezes o onClose por timeout não ocorria.
        //* Timeout de 500ms para LinearProgress desaparecer bonitinho.
        return newProgress
      });
    }, interval);

    return () => clearInterval(timer);
  }, [open, duration]);

  const onCloseAlert = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason && reason === 'clickaway') return;
    onClose()
  };

  return (
    <Portal container={containerRef?.current || document.body}>
      <Snackbar
        open={open}
        {...(duration !== 0 && { autoHideDuration: duration })}
        onClose={(event, reason) => onCloseAlert(event, reason)}
        sx={{
          position: 'static',
          maxWidth: '100%',
          boxSizing: 'border-box',
        }}
        TransitionComponent={Slide}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => onClose()}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert
          onClose={onClose}
          severity={severity}
          sx={{ width: '100%', boxSizing: 'border-box', position: 'relative', overflow: 'hidden', }}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {typeof message === 'object' ? 'Object' : message}
          {duration !== 0 && (
            <Box
              sx={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                marginTop: 1
              }}
            >
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  width: '100%',
                  height: '4px',
                  bgcolor: theme.palette[severity].light,
                  '& .MuiLinearProgress-bar': {
                    bgcolor: theme.palette[severity].dark
                  }
                }}
              />
            </Box>
          )}
        </Alert>
      </Snackbar>
    </Portal>
  );
}

export default SnackbarAlertContext
