import { Avatar, Box, Button, Chip, Container, InputAdornment, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowForward, Search } from "@mui/icons-material";
import { usePedido } from "../../../context/PedidoContext";
import { desatribuirRequest, despacharRequest, limparDespachar } from "../../../store/reducers/criarPedido";
import { telefone } from "../../../ds/common";
import { emAndamentoStart, listarEntregadoresRequest } from "../../../store/reducers/config";
import { RootState } from "src/store/reducers";
import { AvatarWithModal, ButtonLoading, CardPrincipal, GridColumn, GridPainelLateral, HeaderCard, IconButtonFechar, ModalOpcoes } from "src/ds";
import { Entregador } from "src/dtos/EntregadoresDTO";
import { taxaExtraStart } from "src/store/reducers/taxaExtra";
import ModalTaxaExtra from "./ModalTaxaExtra";
import ModalConfigCobranca from "./ModalConfigCobranca";
import { useSnackbarContext } from "src/context/SnackbarContext";
import { setAtualizarEmAndamento } from "src/store/reducers/utils";

interface AtribuirProps {
    close: () => void;
}

interface ErroDespachar {
    bairro: string;
    mensagem: string;
    sucesso: boolean;
    tabelaCobrancaID: string | null;
    tabelaValorID: string | null;
}

const AtribuirPedido = ({ close }: AtribuirProps) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext();
    const entregadores = useSelector((state: RootState) => state.config.entregadores.lista);
    const [filterText, setFilterText] = useState(""); // Estado para o filtro
    const [shouldClose, setShouldClose] = useState(false);
    const { pedidoAtual, rotaAtual, novaListaPedidos, limparNovaLista } = usePedido();
    const atribuirStore = useSelector((state: any) => state.criarPedido?.atribuir);
    const desatribuirStore = useSelector((state: any) => state.criarPedido?.desatribuir);
    const taxasExtra = useSelector((state: RootState) => state.taxaExtra?.lista.data?.dados?.lista);
    const manuais = taxasExtra?.filter(t => t.aplicarAutomaticamente === false) || [];
    const [entregadorSelecionado, setEntregadorSelecionado] = useState<Entregador | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [erroDespachar, setErroDespachar] = useState<ErroDespachar | null>(null);
    const [modalConfigCobranca, setModalConfigCobranca] = useState(false);
    const tabelaCobranca = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.tipoCobranca);
    const tabelaPagamento = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.tipoPagamento);
    const [openDesatribuir, setOpenDesatribuir] = useState(false);

    const handleDesatribuir = () => {
        console.log(pedidoAtual);
        dispatch(desatribuirRequest({
            pedidoID: rotaAtual ? rotaAtual.map((p: any) => p.pedidoID) : pedidoAtual?.pedidoID
        }));
    }

    const handleAtribuir = (entregador: Entregador | null, direto: boolean) => {
        setEntregadorSelecionado(entregador);
        if (!entregador) return;
        let payload
        if (novaListaPedidos?.length > 0) {
            payload = {
                pedidoID: novaListaPedidos.map((p: any) => p.pedidoID),
                usuarioID: entregador.usuarioID,
                rota: true
            }
        } else {
            payload = {
                pedidoID: rotaAtual ? rotaAtual.map((p: any) => p.pedidoID) : pedidoAtual?.pedidoID,
                usuarioID: entregador.usuarioID,
                rota: !!rotaAtual,
            };
        }

        if (manuais.length > 0 && !direto) {
            setOpenModal(true);
        } else {
            dispatch(despacharRequest(payload));
            if (novaListaPedidos) {
                limparNovaLista();
            }
        }

        setShouldClose(false);
    };

    const opcoesDesatribuir = [
        {
            label: 'Cancelar',
            action: () => setOpenDesatribuir(false)
        },
        {
            label: 'Confirmar',
            action: () => {
                handleDesatribuir()
                setOpenDesatribuir(false)
            }
        }
    ]

    useEffect(() => {
        console.log('atribuirStore', atribuirStore);
        if (atribuirStore.error) {
            showError({
                message: atribuirStore.error?.mensagem || "Erro ao atribuir entregador.",
                duration: 5000
            });
            setErroDespachar(atribuirStore.error);
        } else if (atribuirStore.data) {
            dispatch(setAtualizarEmAndamento())
            showSuccess({
                message: atribuirStore.data?.mensagem || "Entregador atribuído com sucesso.",
                duration: 5000
            });
            setShouldClose(true);
        }
        if (desatribuirStore?.resposta?.sucesso === false) {
            showError({
                message: desatribuirStore.mensagem || "Erro ao desatribuir entregador.",
                duration: 5000
            });

        } else if (desatribuirStore?.resposta?.sucesso === true) {
            dispatch(setAtualizarEmAndamento())
            showSuccess({
                message: desatribuirStore.data?.mensagem || "Entregador desatribuído com sucesso.",
                duration: 5000
            });
            setShouldClose(true);
        }
        return () => { dispatch(limparDespachar()); };

    }, [atribuirStore.data, atribuirStore.error, desatribuirStore?.resposta]);

    useEffect(() => {
        if (erroDespachar && tabelaPagamento == 2 && tabelaCobranca == 2) {
            setModalConfigCobranca(true);
        }
    }, [erroDespachar]);

    const handleCloseConfigCobranca = () => {
        setErroDespachar(null);
        setModalConfigCobranca(false);
    };

    useEffect(() => {
        if (shouldClose) {
            close();
        }
    }, [shouldClose]);

    useEffect(() => {
        dispatch(taxaExtraStart({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }));
    }, []);

    useEffect(() => {
        const empresaID = pedidoAtual?.empresaID || (rotaAtual && rotaAtual[0]?.empresaID);

        if (empresaID) {
            dispatch(listarEntregadoresRequest({ empresaID }));
            dispatch(emAndamentoStart({ empresaID }));
        }
        setShouldClose(false);
    }, [pedidoAtual, rotaAtual]);

    const filteredAndSortedEntregadores = entregadores
        .filter((entregador: any) =>
            entregador.nome.toLowerCase().includes(filterText.toLowerCase())
        )
        .sort((a: any, b: any) => {
            const pedidosA = a.pedidosEmAndamento?.length || 0;
            const pedidosB = b.pedidosEmAndamento?.length || 0;

            if (pedidosA !== pedidosB) {
                return pedidosA - pedidosB;
            }

            return a.nome.localeCompare(b.nome);
        });


    const temNovaLista = novaListaPedidos.length > 0;
    const temRotaAtual = !!rotaAtual;

    const agruparPorCliente = (pedidos: any[]) => {
        return pedidos.reduce((agrupado: any, pedido: any) => {
            const chave = pedido.clienteNome;
            if (!agrupado[chave]) agrupado[chave] = [];
            agrupado[chave].push(pedido.codigoQuatro);
            return agrupado;
        }, {});
    };

    let textoNovaLista = "";
    if (temNovaLista) {
        const agrupado = agruparPorCliente(novaListaPedidos);
        textoNovaLista = Object.entries(agrupado)
            .map(([clienteNome, codigos]: any) =>
                `#${codigos.join(", #")} de ${clienteNome}`
            )
            .join("; ");
    }

    let textoRotaOuPedido = "";
    if (!temRotaAtual) {
        textoRotaOuPedido = `pedido #${pedidoAtual?.codigoQuatro} de ${pedidoAtual?.clienteNome}`;
    } else {
        const agrupado = agruparPorCliente(rotaAtual);
        textoRotaOuPedido = Object.entries(agrupado)
            .map(([clienteNome, codigos]: any) =>
                `#${codigos.join(", #")} de ${clienteNome}`
            )
            .join("; ");
    }

    const texto = temNovaLista
        ? `Atribuir ${textoNovaLista}`
        : `Atribuir ${textoRotaOuPedido}`;

    console.log('entregadores', pedidoAtual);

    return (
        <>
            <GridPainelLateral container sx={{ padding: '.5rem', justifyContent: 'stretch' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        width: '100%',
                        mb: "1rem",
                        minHeight: '3rem',
                        height: 'auto',
                    }}
                >
                    <IconButtonFechar
                        onClick={close}
                        sx={{ position: "absolute", left: 0 }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 500,
                            textAlign: "center",
                            px: "3rem",
                            width: "100%",
                            overflowWrap: "break-word",
                            whiteSpace: "normal",
                        }}
                    >
                        {texto}
                    </Typography>
                </Box>

                <Box sx={{ pb: '1rem', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <TextField
                        placeholder="Filtrar Entregadores"
                        variant="outlined"
                        fullWidth
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        size="small"
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }
                        }}
                    />
                    <ButtonLoading loading={desatribuirStore.loading} variant="text" sx={{ mt: '1rem', width: 'min-content' }} onClick={() => { setOpenDesatribuir(true) }}>
                        Desatribuir
                    </ButtonLoading>
                </Box>
                <Box sx={{ flexGrow: 1, width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
                    {(filteredAndSortedEntregadores && filteredAndSortedEntregadores.length > 0)
                        ? filteredAndSortedEntregadores.map((entregador: Entregador, index: number) => (
                            <CardPrincipal sx={(theme) => ({
                                py: '1rem',
                                '&:last-child': { pb: '1rem' },
                                outline: pedidoAtual?.entregadorUsuarioID === entregador?.usuarioID ? `4px dashed ${theme.palette.primary.main}` : 'none'
                            })} key={index}>
                                <Stack sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <AvatarWithModal sx={{ width: 48, height: 48, mr: '1rem' }} username={entregador?.nome} src={entregador?.perfilUrl} />
                                    <GridColumn style={{ alignItems: 'flex-start' }}>
                                        <Typography variant="h6">{entregador?.nome}</Typography>
                                        {entregador?.pedidosEmAndamento?.length > 0
                                            &&
                                            <Typography variant="body2">
                                                Pedido(s) em andamento:{" "}
                                                <br />
                                                {entregador?.pedidosEmAndamento?.map((pedido: any, index: number) => (
                                                    <Stack key={pedido?.codigoQuatro} sx={{ display: 'inline-block', m: '0.1rem', cursor: 'default' }}>
                                                        <Tooltip title={`${pedido?.situacao}`} arrow placement="top">
                                                            <Chip label={`#${pedido?.codigoQuatro}`} sx={{ borderRadius: '4px' }} />
                                                        </Tooltip>
                                                    </Stack>
                                                ))}
                                            </Typography>
                                        }
                                    </GridColumn>
                                    <ButtonLoading
                                        variant="iconeComTextoFilled"
                                        onClick={() => { handleAtribuir(entregador, false); }}
                                        style={{ marginLeft: 'auto' }}
                                        loading={atribuirStore.loading}
                                    >
                                        Atribuir <ArrowForward />
                                    </ButtonLoading>
                                </Stack>
                            </CardPrincipal>
                        ))
                        : <Container sx={{ flexGrow: 1, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ textAlign: 'center', width: '100%' }} variant="notFound">
                                Nenhum Entregador Disponível
                            </Typography>
                        </Container>
                    }
                </Box>
            </GridPainelLateral>
            <ModalTaxaExtra
                open={openModal}
                setOpen={setOpenModal}
                data={manuais}
                entregador={entregadorSelecionado}
            />
            {modalConfigCobranca && <ModalConfigCobranca
                modalProps={{
                    open: modalConfigCobranca,
                    onClose: handleCloseConfigCobranca,
                }}
                erroDespachar={erroDespachar}
                callback={() => {
                    console.log('ativou');
                    handleAtribuir(entregadorSelecionado, true);
                }}
            />}
            <ModalOpcoes
                open={openDesatribuir}
                onClose={() => { setOpenDesatribuir(false) }}
                opcoes={opcoesDesatribuir}
                acao="Desatribuir"
                descricao={`Deseja remover o entregador ${pedidoAtual?.entregadorNome} do pedido #${pedidoAtual?.codigoQuatro}?`}
            />
        </>
    );
};

export default AtribuirPedido;
