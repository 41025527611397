import { Box, Button, Checkbox, Divider, FormControlLabel, InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { BoxLoading, ButtonLoading, ContainerBodyModal, InputDate, InputTime, ModalBase, SelectToggleAtivo, TextFieldMoney, TipoDeVeiculo, useResponsive } from 'src/ds';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { vagaPadraoSalvarClear, vagaPadraoSalvarStart } from 'src/store/reducers/escalas';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { VagaPadrao, VagaPadraoSalvarPayload } from 'src/dtos/EscalasDTO';

interface ModalHandleVagaPadraoProps {
    open: boolean;
    onClose: () => void;
    callback: () => void;
    modelo?: VagaPadrao
}

interface FormData {
    vagaPadraoID?: string;
    pickID?: string;
    nome: string;
    ativo: boolean;
    tipoVeiculoID: string;
    horaInicio: string;
    horaFim: string;
    detalhes: string;
    taxaTotalCobrada: number | string;
    taxaTotalEntregador: number | string;
    qtdPedidosMinimo: number | string;
    taxaMinimaGarantidaCobrada: number | string;
    taxaMinimaGarantidaEntregador: number | string;
    qtdPedidosMinimoGarantidoCobrado: number | string;
    qtdPedidosMinimoGarantidoEntregador: number | string;
    checkboxMinGarantidoPago: boolean
    checkboxMinGarantidoCobrado: boolean
    checkboxQntMinPedidos: boolean
}

const ModalHandleVagaPadrao: React.FC<ModalHandleVagaPadraoProps> = ({
    open,
    onClose,
    callback,
    modelo
}) => {
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();
    const hookForm = useForm<FormData>();
    const { formState: { errors }, handleSubmit, control, watch } = hookForm
    const { showError, showSuccess } = useSnackbarContext();
    const vagaPadraoSalvar = useSelector((state: RootState) => state.escalas.vagaPadraoSalvar);
    const isEdit = !!modelo?.vagaPadraoID

    useEffect(() => {
        hookForm.reset({
            pickID: '',
            nome: '',
            ativo: true,
            tipoVeiculoID: '',
            horaInicio: '',
            horaFim: '',
            detalhes: '',
            taxaTotalCobrada: '0',
            taxaTotalEntregador: '0',
            qtdPedidosMinimo: '0',
            taxaMinimaGarantidaCobrada: '0',
            taxaMinimaGarantidaEntregador: '0',
            qtdPedidosMinimoGarantidoCobrado: '0',
            qtdPedidosMinimoGarantidoEntregador: '0',

            checkboxMinGarantidoPago: false,
            checkboxMinGarantidoCobrado: false,
            checkboxQntMinPedidos: false,
        })
    }, [])

    useEffect(() => {
        if (isEdit) {
            hookForm.reset({
                vagaPadraoID: modelo?.vagaPadraoID,
                pickID: modelo?.pickID,
                nome: modelo?.nome,
                ativo: modelo?.ativo,
                tipoVeiculoID: modelo?.tipoVeiculoID,
                horaInicio: modelo?.horaInicio,
                horaFim: modelo?.horaFim,
                detalhes: modelo?.detalhes,

                // Lidando com Mínimo garantido cobrado
                taxaTotalCobrada:
                    modelo.taxaMinimaGarantidaCobrada != null && modelo.taxaMinimaGarantidaCobrada != 0
                        ? modelo.taxaMinimaGarantidaCobrada
                        : modelo?.taxaTotalCobrada || '0',
                taxaMinimaGarantidaCobrada: '',
                checkboxMinGarantidoCobrado: modelo?.taxaMinimaGarantidaCobrada != null && modelo.taxaMinimaGarantidaCobrada != 0,

                // Lidando com Mínimo garantido pago
                taxaTotalEntregador: modelo?.taxaMinimaGarantidaEntregador != null && modelo.taxaMinimaGarantidaEntregador != 0
                    ? modelo.taxaMinimaGarantidaEntregador
                    : modelo?.taxaTotalEntregador || '0',
                checkboxMinGarantidoPago: modelo?.taxaMinimaGarantidaEntregador != null && modelo.taxaMinimaGarantidaEntregador != 0,
                taxaMinimaGarantidaEntregador: '',

                // Lidando com qntPedidosMinimos
                qtdPedidosMinimo: modelo?.qtdPedidosMinimoGarantidoCobrado !== null && modelo.qtdPedidosMinimoGarantidoCobrado || '0',
                qtdPedidosMinimoGarantidoCobrado: '',
                qtdPedidosMinimoGarantidoEntregador: '',
                checkboxQntMinPedidos: !!(modelo?.qtdPedidosMinimoGarantidoCobrado !== null && modelo.qtdPedidosMinimoGarantidoCobrado || ''),
            })
        }
    }, [isEdit])

    useEffect(() => {
        if (vagaPadraoSalvar.data) {
            showSuccess({
                message: vagaPadraoSalvar.data.mensagem,
                duration: 5000
            })
            callback();
            onClose();
        } else if (vagaPadraoSalvar.error) {
            showError({
                message: vagaPadraoSalvar.error,
                duration: 5000
            });
        }
        return () => {
            dispatch(vagaPadraoSalvarClear());
        }
    }, [vagaPadraoSalvar.data, vagaPadraoSalvar.error])

    function onSubmit(data: FormData) {
        const {
            checkboxMinGarantidoCobrado,
            checkboxMinGarantidoPago,
            checkboxQntMinPedidos,
            ...rest
        } = data;

        // Começamos com os campos padrão
        const sanitezedData: VagaPadraoSalvarPayload = {
            vagaPadraoID: rest.vagaPadraoID || '',
            pickID: rest.pickID || '',
            nome: rest.nome,
            ativo: rest.ativo,
            tipoVeiculoID: rest.tipoVeiculoID,
            horaInicio: rest.horaInicio,
            horaFim: rest.horaFim,
            detalhes: rest.detalhes,
            taxaTotalCobrada: rest.taxaTotalCobrada || '',
            taxaTotalEntregador: rest.taxaTotalEntregador || '',
            qtdPedidosMinimo: rest.qtdPedidosMinimo || '',
            taxaMinimaGarantidaCobrada: rest.taxaMinimaGarantidaCobrada || '',
            taxaMinimaGarantidaEntregador: rest.taxaMinimaGarantidaEntregador || '',
            qtdPedidosMinimoGarantidoCobrado: rest.qtdPedidosMinimoGarantidoCobrado || '',
            qtdPedidosMinimoGarantidoEntregador: rest.qtdPedidosMinimoGarantidoEntregador || '',
        };

        // Ajustes baseados nos checkboxes
        if (checkboxQntMinPedidos) {
            sanitezedData.qtdPedidosMinimoGarantidoCobrado = rest.qtdPedidosMinimo || '';
            sanitezedData.qtdPedidosMinimoGarantidoEntregador = rest.qtdPedidosMinimo || '';
        }

        if (checkboxMinGarantidoCobrado) {
            sanitezedData.taxaMinimaGarantidaCobrada = rest.taxaTotalCobrada || '';
            sanitezedData.taxaTotalCobrada = 0;
        }

        if (checkboxMinGarantidoPago) {
            sanitezedData.taxaMinimaGarantidaEntregador = rest.taxaTotalEntregador || '';
            sanitezedData.taxaTotalEntregador = 0;
        }

        // Garantir valores não nulos para o backend
        const payload = Object.fromEntries(
            Object.entries(sanitezedData).map(([key, value]) => [key, value === 0 ? 0 : value || ''])
        );

        // Dispatch com o payload final
        dispatch(vagaPadraoSalvarStart(payload as VagaPadraoSalvarPayload));
    }
    return (
        <ModalBase
            open={open}
            onClose={onClose}
            headerModal={{
                title: `${isEdit ? 'Editar' : 'Cadastrar'} vaga padrão`
            }}
        >
            <ContainerBodyModal sx={{ height: 'min-content', maxHeight: '95vh' }}>
                <BoxLoading loading={false}>
                    <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction={"column"} spacing={2}>
                            <Controller
                                control={control}
                                name='nome'
                                rules={{ required: 'Campo obrigatório' }}
                                render={({ field }) => (
                                    <TextField
                                        error={!!errors.nome}
                                        helperText={errors.nome?.message}
                                        size='small'
                                        label='Nome'
                                        slotProps={{ inputLabel: { shrink: !!watch('nome') } }}
                                        {...field}
                                    />
                                )}
                            />
                            <SelectToggleAtivo
                                hookForm={{
                                    methods: hookForm,
                                    name: 'ativo',
                                    rules: { validate: value => value !== null || 'Campo obrigatório' },
                                }}
                                toggleLabels={{ ativo: 'Ativo', inativo: 'Inativo' }}
                                toggleValues={{ ativo: 'true', inativo: 'false' }}
                            />
                            <TipoDeVeiculo
                                hookForm={hookForm}
                            />
                            <InputTime
                                hookForm={{
                                    methods: hookForm,
                                    name: 'horaInicio',
                                    label: 'Hora de inicio',
                                    rules: { required: 'Campo obrigatório' }
                                }}
                            />
                            <InputTime
                                hookForm={{
                                    methods: hookForm,
                                    name: 'horaFim',
                                    label: 'Hora de fim',
                                    rules: { required: 'Campo obrigatório' }
                                }}
                            />
                            <Controller
                                control={control}
                                name='detalhes'
                                render={({ field }) => (
                                    <TextField
                                        error={!!errors.detalhes}
                                        helperText={errors.detalhes?.message}
                                        size='small'
                                        label='Detalhes'
                                        multiline
                                        rows={4}
                                        slotProps={{ inputLabel: { shrink: !!watch('detalhes') } }}
                                        {...field}
                                    />
                                )}
                            />
                            <Stack
                                direction={isMobile ? "column" : "row"}
                                spacing={isMobile ? 0 : 2}
                                divider={isMobile ? undefined : <Divider orientation="vertical" flexItem />}
                                sx={{ width: '100%' }}
                            >
                                <TextFieldMoney
                                    hookForm={{
                                        methods: hookForm,
                                        name: 'taxaTotalCobrada',
                                        rules: { required: 'Campo obrigatório' },
                                        defaultValue: modelo?.taxaMinimaGarantidaCobrada != null && modelo.taxaMinimaGarantidaCobrada != 0
                                            ? modelo.taxaMinimaGarantidaCobrada
                                            : modelo?.taxaTotalCobrada
                                    }}
                                    label='Taxa total cobrada'
                                    sx={{ flex: '1' }}
                                />
                                <Controller
                                    name='checkboxMinGarantidoCobrado'
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <FormControlLabel
                                                sx={{ flex: '1' }}
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        value={watch("checkboxMinGarantidoCobrado")}
                                                        checked={watch("checkboxMinGarantidoCobrado")}
                                                        onChange={field.onChange}
                                                    />
                                                }
                                                label="Mínimo garantido cobrado"
                                            />
                                        )
                                    }}
                                />
                            </Stack>
                            <Stack
                                direction={isMobile ? "column" : "row"}
                                spacing={isMobile ? 0 : 2}
                                divider={isMobile ? undefined : <Divider orientation="vertical" flexItem />}
                                sx={{ width: '100%' }}
                            >
                                <TextFieldMoney
                                    hookForm={{
                                        methods: hookForm,
                                        name: 'taxaTotalEntregador',
                                        rules: { required: 'Campo obrigatório' },
                                        defaultValue: modelo?.taxaMinimaGarantidaEntregador != null && modelo.taxaMinimaGarantidaEntregador != 0
                                            ? modelo.taxaMinimaGarantidaEntregador
                                            : modelo?.taxaTotalEntregador,
                                    }}
                                    label='Taxa total entregador'
                                    sx={{ flex: '1' }}
                                />
                                <Controller
                                    name='checkboxMinGarantidoPago'
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <FormControlLabel
                                                sx={{ flex: '1' }}
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        value={watch("checkboxMinGarantidoPago")}
                                                        checked={watch("checkboxMinGarantidoPago")}
                                                        onChange={field.onChange}
                                                    />
                                                }
                                                label="Mínimo garantido pago"
                                            />
                                        )
                                    }}
                                />
                            </Stack>
                            <Stack
                                direction={isMobile ? "column" : "row"}
                                spacing={isMobile ? 0 : 2}
                                divider={isMobile ? undefined : <Divider orientation="vertical" flexItem />}
                                sx={{ width: '100%' }}
                            >
                                <Controller
                                    control={control}
                                    name='qtdPedidosMinimo'
                                    render={({ field }) => (
                                        <TextField
                                            error={!!errors.qtdPedidosMinimo}
                                            helperText={errors.qtdPedidosMinimo?.message}
                                            size='small'
                                            label='Qtd. pedidos mínimo'
                                            slotProps={{
                                                input: {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Os clientes podem definir uma meta de pedidos para os entregadores, garantindo um valor mínimo a ser pago pelo número de pedidos. Após ultrapassar a quantidade mínima de pedidos, o pagamanento sera o mínimo mais taxa por pedido excedente." arrow placement='top'>
                                                                <HelpOutlineIcon />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                    type: 'number',
                                                    inputProps: {
                                                        min: 0,
                                                    },
                                                },
                                                inputLabel: { shrink: watch('qtdPedidosMinimo') != null }
                                            }}
                                            {...field}
                                            sx={{ flex: '1' }}
                                        />
                                    )}
                                />
                                <Controller
                                    name='checkboxQntMinPedidos'
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <FormControlLabel
                                                sx={{ flex: '1' }}
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        value={!!watch("checkboxQntMinPedidos")}
                                                        checked={watch("checkboxQntMinPedidos")}
                                                        onChange={field.onChange}
                                                    />
                                                }
                                                label="Qtd. mínima de pedidos"
                                            />
                                        )
                                    }}
                                />
                            </Stack>
                            <Stack direction={"row"} spacing={1} justifyContent="flex-end">
                                <Button
                                    variant='containedFilled'
                                    sx={{ width: 'min-content' }}
                                    onClick={onClose}
                                    color='secondary'
                                    type='button'
                                >
                                    Cancelar
                                </Button>
                                <ButtonLoading
                                    loading={vagaPadraoSalvar.loading}
                                    variant='containedFilled'
                                    sx={{ width: 'min-content' }}
                                    type="submit"
                                >
                                    {isEdit ? 'Editar' : 'Cadastrar'}
                                </ButtonLoading>
                            </Stack>
                        </Stack>
                    </Box>
                </BoxLoading>
            </ContainerBodyModal>
        </ModalBase>
    )
}

export default ModalHandleVagaPadrao