import { Box, Button, InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { BoxLoading, ButtonLoading, ContainerBodyModal, ModalBase } from 'src/ds';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { pontuacaoPaginaClear, pontuacaoPaginaStart, pontuacaoSalvarClear, pontuacaoSalvarStart } from 'src/store/reducers/entregador';
import { RootState } from 'src/store/reducers';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface ModalHandlePontuacaoProps {
    open: boolean;
    onClose: () => void;
    callback: () => void;
    pontuacaoID?: string;
}

interface FormData {
    pontuacaoID?: string;
    nome: string;
    pontuacaoCadastro: number;
    pontuacaoLogin: number;
    pontuacaoPrimeiroPedidoDoDia: number;
    pontuacaoAceitarPedido: number;
    pontuacaoRecusarPedido: number;
}

const ModalHandlePontuacao: React.FC<ModalHandlePontuacaoProps> = ({
    open,
    onClose,
    callback,
    pontuacaoID
}) => {
    const dispatch = useDispatch();
    const hookForm = useForm<FormData>();
    const { register, formState: { errors }, handleSubmit, control, watch } = hookForm
    const { showError, showSuccess } = useSnackbarContext();
    const pontuacaoSalvarStore = useSelector((state: RootState) => state.entregadores.pontuacaoSalvar);
    const pontuacaoPaginaStore = useSelector((state: RootState) => state.entregadores.pontuacaoPagina);
    const isEdit = !!pontuacaoID

    useEffect(() => {
        if (pontuacaoID) {
            dispatch(pontuacaoPaginaStart({ id: pontuacaoID }));
        }
    }, [pontuacaoID])

    useEffect(() => {
        if (pontuacaoPaginaStore.data) {
            hookForm.reset({
                pontuacaoID: pontuacaoPaginaStore.data.modelo.pontuacaoID,
                nome: pontuacaoPaginaStore.data.modelo.nome,
                pontuacaoCadastro: pontuacaoPaginaStore.data.modelo.pontuacaoCadastro,
                pontuacaoLogin: pontuacaoPaginaStore.data.modelo.pontuacaoLogin,
                pontuacaoPrimeiroPedidoDoDia: pontuacaoPaginaStore.data.modelo.pontuacaoPrimeiroPedidoDoDia,
                pontuacaoAceitarPedido: pontuacaoPaginaStore.data.modelo.pontuacaoAceitarPedido,
                pontuacaoRecusarPedido: pontuacaoPaginaStore.data.modelo.pontuacaoRecusarPedido
            })
        } else if (pontuacaoPaginaStore.error) {
            showError({
                message: pontuacaoPaginaStore.error || 'Um erro inesperado aconteceu',
                duration: 5000
            });
        }
        return () => {
            dispatch(pontuacaoPaginaClear());
        }
    }, [pontuacaoPaginaStore.data, pontuacaoPaginaStore.error])

    useEffect(() => {
        if (pontuacaoSalvarStore.data) {
            showSuccess({
                message: pontuacaoSalvarStore.data.mensagem,
                duration: 5000
            })
            callback();
            onClose();
        } else if (pontuacaoSalvarStore.error) {
            showError({
                message: pontuacaoSalvarStore.error,
                duration: 5000
            });
        }
        return () => {
            dispatch(pontuacaoSalvarClear());
        }
    }, [pontuacaoSalvarStore.data, pontuacaoSalvarStore.error])


    function onSubmit(data: FormData) {
        console.log(data)
        dispatch(pontuacaoSalvarStart(data));
    }

    return (
        <ModalBase open={open} onClose={onClose} headerModal={{ title: isEdit ? 'Editar pontuação' : 'Cadastrar pontuação' }}>
            <ContainerBodyModal sx={{ height: 'min-content', maxHeight: '95vh' }}>
                <BoxLoading loading={pontuacaoPaginaStore.loading}>
                    <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction={"column"} spacing={2}>
                            <Controller
                                control={control}
                                name='nome'
                                rules={{ required: 'Campo obrigatório' }}
                                render={({ field }) => (
                                    <TextField
                                        error={!!errors.nome}
                                        helperText={errors.nome?.message}
                                        size='small'
                                        label='Nome'
                                        slotProps={{ inputLabel: { shrink: !!watch('nome')  } }}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name='pontuacaoCadastro'
                                rules={{ required: 'Campo obrigatório' }}
                                render={({ field }) => (
                                    <TextField
                                        type="number"
                                        error={!!errors.pontuacaoCadastro}
                                        helperText={errors.pontuacaoCadastro?.message}
                                        size='small'
                                        label='Cadastro'
                                        slotProps={{ inputLabel: { shrink: watch('pontuacaoCadastro') != null } }}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name='pontuacaoLogin'
                                rules={{ required: 'Campo obrigatório' }}
                                render={({ field }) => (
                                    <TextField
                                        type="number"
                                        error={!!errors.pontuacaoLogin}
                                        helperText={errors.pontuacaoLogin?.message}
                                        size='small'
                                        label='Login diário'
                                        slotProps={{ inputLabel: { shrink: watch('pontuacaoLogin') != null } }}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name='pontuacaoPrimeiroPedidoDoDia'
                                rules={{ required: 'Campo obrigatório' }}
                                render={({ field }) => (
                                    <TextField
                                        type="number"
                                        error={!!errors.pontuacaoPrimeiroPedidoDoDia}
                                        helperText={errors.pontuacaoPrimeiroPedidoDoDia?.message}
                                        size='small'
                                        label='Primeiro pedido do dia'
                                        slotProps={{ inputLabel: { shrink: watch('pontuacaoPrimeiroPedidoDoDia') != null } }}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name='pontuacaoAceitarPedido'
                                rules={{ required: 'Campo obrigatório' }}
                                render={({ field }) => (
                                    <TextField
                                        type="number"
                                        error={!!errors.pontuacaoAceitarPedido}
                                        helperText={errors.pontuacaoRecusarPedido?.message}
                                        size='small'
                                        label='Aceitar pedido'
                                        slotProps={{ inputLabel: { shrink: watch('pontuacaoAceitarPedido') != null } }}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name='pontuacaoRecusarPedido'
                                rules={{ required: 'Campo obrigatório' }}
                                render={({ field }) => (
                                    <TextField
                                        type="number"
                                        error={!!errors.pontuacaoRecusarPedido}
                                        helperText={errors.pontuacaoRecusarPedido?.message}
                                        size='small'
                                        label='Recusar pedido'
                                        slotProps={{
                                            inputLabel: { shrink: watch('pontuacaoRecusarPedido') != null },
                                            input: {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip arrow placement="top" title="Coloque um número negativo para abater da pontuação">
                                                            <InfoOutlinedIcon />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )
                                            }
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                <Button
                                    variant='containedFilled'
                                    sx={{ width: 'min-content' }}
                                    onClick={onClose}
                                    color='secondary'
                                    type='button'
                                >
                                    Cancelar
                                </Button>
                                <ButtonLoading
                                    loading={pontuacaoSalvarStore.loading}
                                    variant='containedFilled'
                                    sx={{ width: 'min-content' }}
                                    type="submit"
                                >
                                    {isEdit ? 'Editar' : 'Cadastrar'}
                                </ButtonLoading>
                            </Stack>
                        </Stack>
                    </Box>
                </BoxLoading>
            </ContainerBodyModal>
        </ModalBase>
    )
}

export default ModalHandlePontuacao