import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbarContext } from 'src/context/SnackbarContext'
import { ButtonLoading, ModalOpcoes, useResponsive } from 'src/ds'
import { RootState } from 'src/store/reducers'
import { excluirJornadaClear, excluirJornadaStart, finalizarJornadaClear, finalizarJornadaStart, iniciarJornadaClear, iniciarJornadaStart } from 'src/store/reducers/escalas'

interface ButtonHandleVagaProps {
    pedidoID: string
    pedidoIDAtual?: string
    entregador: {
        entregadorUsuarioID?: string | null;
        dataChegouEstabelecimento?: string | null;
        dataFinalizado?: string | null;
    },
    callback?: () => void
    closeCriarVaga?: () => void
}

const ButtonHandleVaga: React.FC<ButtonHandleVagaProps> = ({
    pedidoID,
    pedidoIDAtual,
    entregador,
    callback,
    closeCriarVaga
}) => {
    const { showError, showSuccess, showSnackbar } = useSnackbarContext()
    const SEM_ENTREGADOR = '00000000-0000-0000-0000-000000000000'
    const { isMobile } = useResponsive()
    const dispatch = useDispatch()
    const excluirJornada = useSelector((state: RootState) => state.escalas.excluirJornada)
    const finalizarJornada = useSelector((state: RootState) => state.escalas.finalizarJornada)
    const iniciarJornada = useSelector((state: RootState) => state.escalas.iniciarJornada)
    const { entregadorUsuarioID, dataChegouEstabelecimento, dataFinalizado } = entregador
    const [modalExcluirVaga, setModalExcluirVaga] = useState(false);
    const [modalFinalizarVaga, setModalFinalizarVaga] = useState(false);

    function handleIniciarVaga() { dispatch(iniciarJornadaStart(pedidoID)) };

    function handleFinalizarVaga() { dispatch(finalizarJornadaStart(pedidoID)) };

    function handleExcluirVaga() { dispatch(excluirJornadaStart(pedidoID)) };

    const handleModalExcluir = () => { setModalExcluirVaga(true) };

    const handleModalFinalizar = () => { setModalFinalizarVaga(true) };

    useEffect(() => {
        if (excluirJornada.data) {
            showSuccess({
                message: excluirJornada.data.mensagem,
                duration: 5000
            });
            setModalExcluirVaga(false)
            dispatch(excluirJornadaClear())
            closeCriarVaga?.()
        } else if (excluirJornada.error) {
            showError({
                message: excluirJornada.error,
                duration: 5000
            });
        }
    }, [excluirJornada.data, excluirJornada.error]);

    useEffect(() => {
        if (finalizarJornada.data) {
            showSuccess({
                message: finalizarJornada.data.mensagem,
                duration: 5000
            });
            setModalFinalizarVaga(false)
            callback?.()
        } else if (finalizarJornada.error) {
            showError({
                message: finalizarJornada.error,
                duration: 5000
            });
        }
        return () => {
            dispatch(finalizarJornadaClear())
        }
    }, [finalizarJornada.data, finalizarJornada.error]);

    useEffect(() => {
        if (iniciarJornada.data) {
            showSuccess({
                message: iniciarJornada.data.mensagem,
                duration: 5000
            });
            callback?.()
        } else if (iniciarJornada.error) {
            showError({
                message: iniciarJornada.error,
                duration: 5000
            });
        }
        return () => {
            dispatch(iniciarJornadaClear())
        }
    }, [iniciarJornada.data, iniciarJornada.error]);

    return (
        <>
            {entregadorUsuarioID && entregadorUsuarioID !== SEM_ENTREGADOR
                ? (!dataChegouEstabelecimento
                    ? (
                        <ButtonLoading
                            variant="containedFilled"
                            color='green'
                            sx={{ width: isMobile ? '100%' : 'min-content' }}
                            onClick={handleIniciarVaga}
                            loading={!!(pedidoIDAtual ? iniciarJornada?.loading && pedidoID === pedidoIDAtual : iniciarJornada?.loading)}
                        >
                            Iniciar jornada
                        </ButtonLoading>
                    ) : !dataFinalizado && (
                        <ButtonLoading
                            variant="containedFilled"
                            color='yellow'
                            sx={{ width: isMobile ? '100%' : 'min-content' }}
                            onClick={handleModalFinalizar}
                            loading={!!(pedidoIDAtual ? finalizarJornada?.loading && pedidoID === pedidoIDAtual : finalizarJornada?.loading)}
                        >
                            Finalizar jornada
                        </ButtonLoading>
                    )
                ) : null}
            {(!pedidoIDAtual || entregadorUsuarioID && entregadorUsuarioID !== SEM_ENTREGADOR) && (
                <ButtonLoading
                    variant="containedFilled"
                    color='red'
                    sx={{ width: isMobile ? '100%' : 'min-content' }}
                    onClick={handleModalExcluir}
                    loading={!!(pedidoIDAtual ? excluirJornada?.loading && pedidoID === pedidoIDAtual : excluirJornada?.loading)}
                >
                    {dataChegouEstabelecimento ? 'Excluir' : 'Fechar vaga (excluir)'}
                </ButtonLoading>
            )}

            {modalFinalizarVaga && (
                <ModalOpcoes
                    open={modalFinalizarVaga}
                    onClose={() => setModalFinalizarVaga(false)}
                    descricao={'Deseja finalizar esta vaga?'}
                    acao={'Finalizar Vaga'}
                    opcoes={[
                        { label: 'Cancelar', action: () => setModalFinalizarVaga(false) },
                        { label: 'Confirmar', action: handleFinalizarVaga, loading: finalizarJornada?.loading },
                    ]}
                />
            )}

            {modalExcluirVaga && (
                <ModalOpcoes
                    open={modalExcluirVaga}
                    onClose={() => setModalExcluirVaga(false)}
                    acao='Excluir vaga'
                    descricao={'Você deseja excluir esta vaga?'}
                    opcoes={[
                        { label: 'Cancelar', action: () => { setModalExcluirVaga(false) }, },
                        { label: 'Confirmar', loading: excluirJornada?.loading, action: handleExcluirVaga }
                    ]}
                />
            )}
        </>
    )
}

export default ButtonHandleVaga