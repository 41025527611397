import { createTheme, Theme as MuiTheme, ThemeProvider, PaletteMode } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import WarningIcon from '@mui/icons-material/Warning';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { deepmerge } from '@mui/utils';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { Box, CircularProgress } from '@mui/material';
import { buscarTemplateLoginStart } from 'src/store/reducers/utils';


const generateCSS = (theme: MuiTheme) => {
  return `
    :root {
      --primary-main: ${theme.palette.primary.main};
      --primary-dark: ${theme.palette.primary.dark};
      --primary-contrastText: ${theme.palette.primary.contrastText};
      --primary-light: ${theme.palette.primary.light};
      --secondary-main: ${theme.palette.secondary.main};
      --background-default: ${theme.palette.background.default};
      --background-paper: ${theme.palette.background.paper};
      --text-primary: ${theme.palette.text.primary};
      --text-secondary: ${theme.palette.text.secondary};
    }

    body {
      font-family: ${theme.typography.fontFamily};
      background-color: var(--background-default);
      color: var(--text-primary);
    }

    h1 {
      font-size: ${theme.typography.h1.fontSize};
      font-weight: ${theme.typography.h1.fontWeight};
    }
  `;
};

const CustomThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const template = useSelector((state: RootState) => state.config?.template);
  const error = useSelector((state: RootState) => state.config?.error);
  const [theme, setTheme] = useState<MuiTheme | null>(null);
  const colorMode: PaletteMode = useSelector((state: RootState) => state.utils.colorMode as PaletteMode);
  const devMode = useSelector((state: RootState) => state.utils?.devMode);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(buscarTemplateLoginStart({}));
  }, [])


  useEffect(() => {
    console.log('template', template, loading);
    if (template?.template) {
      setLoading(false);
    }
  }, [template?.template]);

  const customColors = {
    primary: {
      main: '#000',
      contrastText: '#ffffff',

    },
    secondary: {
      main: '#3E1284',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
      disabled: '#e2e2e2',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
      paperDark: '#f9f9f9',
    },
    status: {
      'Criado': '#00a0ff',
      'Pronto': '#c653c6',
      'Despachado': '#efa743',
      'Recebido': '#D70000',
      'Em rota': '#0C61C6',
      'Aceito': '#ffff33',
      'Na origem': '#99ccff',
      'Retornando': '#4AA800',
    },
    red: {
      50: '#ffebee',
      100: '#ffcdd2',
      200: '#ef9a9a',
      300: '#e57373',
      400: '#ef5350',
      500: '#f44336',
      600: '#e53935',
      700: '#d32f2f',
      800: '#c62828',
      900: '#b71c1c',
      A100: '#ff8a80',
      A200: '#ff5252',
      A400: '#ff1744',
      A700: '#d50000',
    },
    green: {
      50: '#E8F5E9',
      100: '#E8F5E9',
      200: '#C8E6C9',
      300: '#A5D6A7',
      400: '#81C784',
      500: '#4CAF50',
      600: '#43A047',
      700: '#388E3C',
      800: '#2E7D32',
      900: '#1B5E20',
      A100: '#B9F6CA',
      A200: '#69F0AE',
      A400: '#00E676',
      A700: '#00C853',
    },
    blue: {
      50: '#E3F2FD',
      100: '#E3F2FD',
      200: '#BBDEFB',
      300: '#90CAF9',
      400: '#64B5F6',
      500: '#2196F3',
      600: '#1E88E5',
      700: '#1976D2',
      800: '#1565C0',
      900: '#0D47A1',
      A100: '#82B1FF',
      A200: '#448AFF',
      A400: '#2979FF',
      A700: '#2962FF',
    },
    yellow: {
      50: '#fffde7',
      100: '#fff9c4',
      200: '#fff59d',
      300: '#fff176',
      400: '#ffee58',
      500: '#ffeb3b',
      600: '#fdd835',
      700: '#fbc02d',
      800: '#f9a825',
      900: '#f57f17',
      A100: '#ffff8d',
      A200: '#ffff00',
      A400: '#ffea00',
      A700: '#ffd600',
    }
  };

  const customSizes: MuiTheme['sizes'] = {
    fontSize: {
      xs: '.75rem',  // 12px
      sm: '.875rem', // 14px
      md: '1rem',    // 16px
      lg: '1.5rem',  // 24px
      xl: '2rem',    // 32px
    },
    lineHeight: {
      xs: '.875rem', // 14px
      sm: '1rem',    // 16px
      md: '1.5rem',  // 24px
      lg: '2rem',    // 32px
      xl: '3rem',    // 48px
    },
    borderRadius: {
      xs: '.25rem',  // 4px
      sm: '.5rem',   // 8px
      md: '0.75rem', // 12px
      lg: '1.25rem', // 20px
      xl: '2rem',    // 32px
    },
    padding: {
      xs: '.5rem',   // 8px
      sm: '0.75rem', // 12px
      md: '1rem',    // 16px 
      lg: '1.5rem',  // 24px
      xl: '2rem',    // 32px
    },
    gap: {
      xs: '.5rem',    // 8px
      sm: '0.625rem', // 10px
      md: '1rem',     // 16px
      lg: '1.5rem',   // 24px
      xl: '2rem',     // 32px
    },
  }
  if (theme?.palette) {
    document.documentElement.style.setProperty('--primary-color', theme.palette.primary.main);
    document.documentElement.style.setProperty('--secondary-color', theme.palette.text.secondary);
  } else {
    console.error('Theme is null or undefined.');
  }

  useEffect(() => {
    console.log('%cPick N Go!', 'color: white; font-size: 24px;background-color: #FFA210; padding: 2px 5px; border-radius: 5px;');
  }, []);

  useEffect(() => {
    if (template?.sucesso && customColors.primary && customColors.secondary) {
      const { corFonteMenu, } = template.template;
      const corPrimaria = template.template?.parametros?.['cor.primaria'] || corFonteMenu;
      const corSecundaria = template.template?.parametros?.['cor.secundaria'] || '#3E1284';


      customColors.primary.main = corPrimaria ? corPrimaria : corFonteMenu;

      if (corSecundaria) {
        customColors.secondary.main = corSecundaria;
      }

      if (devMode) {
        customColors.primary.main = '#ff00e1';
        customColors.secondary.main = '#00ff11';
      }
    } else if (error) {
      throw 'Erro ao obter template!';
    }

    const defaultTheme = createTheme({
      palette: {
        ...customColors,
        mode: colorMode,
        primary: {
          ...customColors.primary,
        },
        secondary: {
          ...customColors.secondary,
        },
        ...(colorMode === 'dark' ? {
          background: {
            paper: '#121212',
            default: '#121212',
          },
          text: {
            primary: '#ffffff',
            secondary: '#fff',
            disabled: '#e2e2e2',
          },
        } : {}),
        status: {
          ...customColors.status,
        },
      },
      sizes: {
        ...customSizes
      }
    })

    const customTheme = createTheme({
      palette: {
        ...defaultTheme.palette,
      },
      typography: {
        fontFamily: 'Inter, sans-serif',
        h1: {
          fontSize: customSizes.fontSize.xl,
          fontWeight: 'bold',
          lineHeight: customSizes.lineHeight.xl,
          letterSpacing: '-1px',
        },
        h2: {
          fontSize: customSizes.fontSize.lg,
          fontWeight: 'semibold',
          lineHeight: customSizes.lineHeight.lg,
          letterSpacing: '-1px',
        },
        body1: {
          fontSize: customSizes.fontSize.md,
          lineHeight: customSizes.lineHeight.md,
          '& a': {
            color: defaultTheme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
        body2: {
          fontSize: customSizes.fontSize.sm,
          lineHeight: customSizes.lineHeight.sm,
          '& a': {
            color: defaultTheme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
        span: {
          fontSize: 'inherit',
          fontFamily: 'inherit',
          letterSpacing: 'inherit',
          lineHeight: 'inherit',
        },
        monospaced: {
          fontFamily: '"Roboto Mono", monospace;',
        },
        notFound: {
          fontSize: customSizes.fontSize.md,
          lineHeight: customSizes.lineHeight.md,
          fontWeight: 800,
          color: defaultTheme.palette.grey[500],
          '& a': {
            color: defaultTheme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
        subtitle1: {
          fontSize: customSizes.fontSize.sm,
          lineHeight: customSizes.lineHeight.sm,
          fontWeight: 600,
          color: defaultTheme.palette.grey[700],
          '& a': {
            color: defaultTheme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
          backgroundColor: defaultTheme.palette.grey[200],
          padding: 12,

          borderRadius: 5,
          textAlign: 'center',
          ...(defaultTheme.applyStyles('dark', {
            backgroundColor: defaultTheme.palette.grey[900],
            color: defaultTheme.palette.getContrastText(defaultTheme.palette.grey[900]),
          }) as { [key: string]: string | number }),
        }
      },
      spacing: 8,
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      transitions: {
        easing: {
          easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
          easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
          easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
          sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1100,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              backgroundColor: defaultTheme.palette.background.default,
              color: defaultTheme.palette.primary.main,
              boxSizing: 'border-box',
              borderRadius: customSizes.borderRadius.xl,
              width: '100%',
              minWidth: 'fit-content',
              textTransform: 'none',
              borderColor: 'initial',
              boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.07)',
              transition: 'all .3s ease-in-out',
              '&:hover': {
                backgroundColor: defaultTheme.palette.grey[300],
              },
              '&:disabled': {
                backgroundColor: defaultTheme.palette.grey[300],
                borderColor: defaultTheme.palette.grey[600],
                color: defaultTheme.palette.grey[600],
                '& svg': {
                  color: defaultTheme.palette.grey[600],
                },
              },
              '& a': {
                color: defaultTheme.palette.primary.main,
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
              padding: `calc(${customSizes.padding.xs} / 2) ${customSizes.padding.xs}`,
              fontSize: '0.875rem',
            },
            sizeSmall: {
              padding: '2px 4px',
              fontSize: '0.475rem',
            },
          },
          variants: [
            /* Botão primário, cor secundária */
            {
              props: {
                color: 'secondary',
              },
              style: {
                color: defaultTheme.palette.secondary.main,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.grey[300]
                },
                '&:disabled': {
                  backgroundColor: defaultTheme.palette.grey[300],
                  borderColor: defaultTheme.palette.grey[500],
                },
              }
            },
            /* Botão primário, cor cinza */
            {
              props: {
                color: 'grey',
              },
              style: {
                color: defaultTheme.palette.grey[600],
                '&:hover': {
                  backgroundColor: defaultTheme.palette.grey[400]
                },
                '&:disabled': {
                  backgroundColor: defaultTheme.palette.grey[300],
                  borderColor: defaultTheme.palette.grey[500],
                },
              }
            },
            /* Botão Outlined, cor primária */
            {
              props: {
                variant: 'outlined',
              },
              style: {
                color: defaultTheme.palette.grey[500],
                border: `1px solid ${defaultTheme.palette.grey[500]}`,
                boxShadow: 'none',
                backgroundColor: defaultTheme.palette.background.default,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.primary.main,
                  color: defaultTheme.palette.primary.contrastText,
                  border: `1px solid ${defaultTheme.palette.primary.main}`,
                },
                '&:disabled': {
                  backgroundColor: '#e2e2e2',
                },
              }
            },
            /* Botão Outlined, cor secundária */
            {
              props: {
                variant: 'outlined', color: 'secondary',
              },
              style: {
                '&:hover': {
                  backgroundColor: defaultTheme.palette.secondary.main,
                  color: defaultTheme.palette.secondary.contrastText,
                  border: `1px solid ${defaultTheme.palette.secondary.main}`,
                },
              }
            },
            /* Botão Outlined, cor cinza */
            {
              props: {
                variant: 'outlined', color: 'grey',
              },
              style: {
                '&:hover': {
                  backgroundColor: defaultTheme.palette.grey[500],
                  color: defaultTheme.palette.grey[100],
                  border: `1px solid ${defaultTheme.palette.grey[500]}`,
                },
              }
            },
            /* Botão Filled, cor primária */
            {
              props: {
                variant: 'filled',
              },
              style: {
                backgroundColor: defaultTheme.palette.primary.main,
                color: defaultTheme.palette.primary.contrastText,
                border: `1px solid ${defaultTheme.palette.primary.main}`,
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: defaultTheme.palette.background.default,
                  color: defaultTheme.palette.grey[500],
                  border: `1px solid ${defaultTheme.palette.grey[500]}`,
                },
                '&:disabled': {
                  backgroundColor: '#e2e2e2',
                },
              }
            },
            /* Botão Filled, cor secundária */
            {
              props: {
                variant: 'filled', color: '',
              },
              style: {
                color: defaultTheme.palette.secondary.contrastText,
                border: `1px solid ${defaultTheme.palette.secondary.main}`,
                backgroundColor: defaultTheme.palette.secondary.main,
              }
            },
            /* Botão Filled, cor cinza */
            {
              props: {
                variant: 'filled', color: 'grey',
              },
              style: {
                color: defaultTheme.palette.grey[50],
                border: `1px solid ${defaultTheme.palette.grey[500]}`,
                backgroundColor: defaultTheme.palette.grey[500],
              }
            },
            /* Botão Contained, cor primária */
            {
              props: {
                variant: 'contained',
              },
              style: {
                backgroundColor: defaultTheme.palette.background.default,
                color: defaultTheme.palette.primary.main,
                borderRadius: customSizes.borderRadius.sm,
                border: `1px solid ${defaultTheme.palette.primary.main}`,
                boxShadow: 'none',
                // padding: `${customSizes.padding.md} ${customSizes.padding.xl}`,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.primary.main,
                  color: defaultTheme.palette.primary.contrastText,
                },
                '&:disabled': {
                  backgroundColor: defaultTheme.palette.grey[200],
                  borderColor: defaultTheme.palette.grey[600],
                },
              }
            },
            /* Botão Contained, cor secundária */
            {
              props: {
                variant: 'contained', color: 'secondary',
              },
              style: {
                backgroundColor: defaultTheme.palette.background.default,
                color: defaultTheme.palette.secondary.main,
                border: `1px solid ${defaultTheme.palette.secondary.main}`,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.secondary.main,
                  color: defaultTheme.palette.secondary.contrastText,
                },
              }
            },
            /* Botão Contained, cor secundária */
            {
              props: {
                variant: 'contained', color: 'grey',
              },
              style: {
                backgroundColor: defaultTheme.palette.background.default,
                color: defaultTheme.palette.grey[500],
                border: `1px solid ${defaultTheme.palette.grey[500]}`,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.grey[500],
                  color: defaultTheme.palette.grey[50],
                },
              }
            },
            /* Botão Contained Filled, cor primária */
            {
              props: {
                variant: 'containedFilled',
              },
              style: {
                backgroundColor: defaultTheme.palette.primary.main,
                color: defaultTheme.palette.text.secondary,
                borderRadius: customSizes.borderRadius.sm,
                border: `1px solid ${defaultTheme.palette.primary.main}`,
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: defaultTheme.palette.background.default,
                  color: defaultTheme.palette.primary.main,
                },
                '&:disabled': {
                  backgroundColor: defaultTheme.palette.grey[200],
                  borderColor: defaultTheme.palette.grey[600],
                },
              }
            },
            /* Botão Contained Filled, cor secundária */
            {
              props: {
                variant: 'containedFilled', color: 'secondary',
              },
              style: {
                backgroundColor: defaultTheme.palette.secondary.main,
                color: defaultTheme.palette.text.secondary,
                borderRadius: customSizes.borderRadius.sm,
                border: `1px solid ${defaultTheme.palette.secondary.main}`,
                borderColor: defaultTheme.palette.secondary.main,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.background.default,
                  color: defaultTheme.palette.secondary.main,
                },
              }
            },
            /* Botão containedFilled, cor cinza */
            {
              props: {
                variant: 'containedFilled', color: 'grey',
              },
              style: {
                backgroundColor: defaultTheme.palette.grey[500],
                color: defaultTheme.palette.grey[50],
                border: `1px solid ${defaultTheme.palette.grey[500]}`,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.background.default,
                  color: defaultTheme.palette.grey[500],
                },
                '&:disabled': {
                  backgroundColor: defaultTheme.palette.grey[200],
                },
              }
            },
            /* Botão containedFilled, cor red */
            {
              props: {
                variant: 'containedFilled', color: 'red',
              },
              style: {
                backgroundColor: defaultTheme.palette.red[500],
                color: '#ffffff',
                border: `1px solid transparent`,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.red[600],
                  color: '#ffffff',
                },
                '&:disabled': {
                  backgroundColor: defaultTheme.palette.red[200],
                },
              }
            },
            /* Botão containedFilled, cor green */
            {
              props: {
                variant: 'containedFilled', color: 'green',
              },
              style: {
                backgroundColor: defaultTheme.palette.green[500],
                color: '#ffffff',
                border: `1px solid transparent`,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.green[600],
                  color: '#ffffff',
                },
                '&:disabled': {
                  backgroundColor: defaultTheme.palette.green[200],
                },
              }
            },
            /* Icone com texto Filled */
            {
              props: {
                variant: 'iconeComTextoFilled',
              },
              style: {
                backgroundColor: defaultTheme.palette.primary.main,
                color: defaultTheme.palette.text.secondary,
                border: `1px solid ${defaultTheme.palette.primary.main}`,
                borderColor: 'inherit',
                borderRadius: customSizes.borderRadius.sm,
                width: 'fit-content',
                whiteSpace: 'nowrap',
                startsWithIcon: true,
                alignContent: 'center',
                gap: '.5rem',
                '&:hover': {
                  backgroundColor: defaultTheme.palette.primary.dark,
                },
              }
            },
            /* Icone com texto Filled, cor secundária */
            {
              props: {
                variant: 'iconeComTextoFilled', color: 'secondary',
              },
              style: {
                backgroundColor: defaultTheme.palette.secondary.main,
                color: defaultTheme.palette.text.secondary,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.secondary.dark,
                },
              }
            },
            /* Icone com texto Filled, cor cinza */
            {
              props: {
                variant: 'iconeComTextoFilled', color: 'grey',
              },
              style: {
                backgroundColor: defaultTheme.palette.grey[500],
                color: defaultTheme.palette.text.secondary,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.grey[600],
                },
              }
            },
            /* Icone com texto Filled, cor red */
            {
              props: {
                variant: 'iconeComTextoFilled', color: 'red',
              },
              style: {
                backgroundColor: defaultTheme.palette.red[500],
                color: defaultTheme.palette.text.secondary,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.red[600],
                },
              }
            },
            /* Icone com texto Filled, cor green */
            {
              props: {
                variant: 'iconeComTextoFilled', color: 'green',
              },
              style: {
                backgroundColor: defaultTheme.palette.green[500],
                color: defaultTheme.palette.text.secondary,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.green[600],
                },
              }
            },
            /* Icone com texto */
            {
              props: {
                variant: 'iconeComTexto',
              },
              style: {
                color: defaultTheme.palette.grey[500],
                gap: customSizes.gap.sm,
                flex: 1,
                '& svg': {
                  color: defaultTheme.palette.primary.main,
                },
              }
            },
            /* Icone com texto, cor secundária */
            {
              props: {
                variant: 'iconeComTexto', color: 'secondary',
              },
              style: {
                '& svg': {
                  color: defaultTheme.palette.secondary.main,
                },
              }
            },
            /* Icone com texto, cor cinza */
            {
              props: {
                variant: 'iconeComTexto', color: 'grey',
              },
              style: {
                '& svg': {
                  color: defaultTheme.palette.grey[600],
                },
              }
            },
            {
              props: {
                variant: 'textoSimples'
              },
              style: {

                backgroundColor: 'transparent',
                whiteSpace: 'nowrap',
                color: defaultTheme.palette.grey[600],
                fontSize: customSizes.fontSize.xs,
                borderRadius: '0px',
                width: 'fit-content',
                maxHeight: '20px',
                padding: '0',
                borderRight: '1px solid #e0e0e0',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '&:last-child': {
                  borderRight: 'none',
                },
              }
            },
          ]
        },
        MuiIconButton: {
          variants: [
            // Contained Color Primary
            {
              props: { variant: 'contained' },
              style: {
                border: `1px solid ${defaultTheme.palette.primary.main}`,
                color: defaultTheme.palette.primary.main,
                borderRadius: customSizes.borderRadius.md,
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: defaultTheme.palette.primary.dark,
                  borderColor: defaultTheme.palette.primary.dark,
                  transition: 'all 0.3s ease-in-out',
                  '& .MuiSvgIcon-root': {
                    transition: 'all 0.3s ease-in-out',
                    color: defaultTheme.palette.text.secondary,
                  },
                },
                '&:disabled': {
                  backgroundColor: defaultTheme.palette.grey[200],
                  borderColor: defaultTheme.palette.grey[200],
                }
              }
            },
            // Contained Color Secondary
            {
              props: { variant: 'contained', color: 'secondary' },
              style: {
                border: `1px solid ${defaultTheme.palette.secondary.main}`,
                color: defaultTheme.palette.secondary.main,
                borderRadius: customSizes.borderRadius.md,
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: defaultTheme.palette.secondary.dark,
                  borderColor: defaultTheme.palette.secondary.dark,
                  '& .MuiSvgIcon-root': {
                    color: defaultTheme.palette.text.secondary,
                  },
                }
              }
            },
            // Contained Color Default
            {
              props: { variant: 'contained', color: 'default' },
              style: {
                border: `1px solid ${defaultTheme.palette.grey[400]}`,
                color: defaultTheme.palette.grey[500],
                borderRadius: customSizes.borderRadius.md,
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: defaultTheme.palette.grey['200'],
                  borderColor: defaultTheme.palette.grey[600],
                  '& .MuiSvgIcon-root': {
                    color: defaultTheme.palette.grey[600],
                  },
                }
              }
            },
            // containedFilled Color Primary
            {
              props: { variant: 'containedFilled' },
              style: {
                border: `1px solid ${defaultTheme.palette.primary.main}`,
                color: defaultTheme.palette.text.secondary,
                borderRadius: customSizes.borderRadius.md,
                backgroundColor: defaultTheme.palette.primary.main,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.primary.dark,
                  transition: 'all 0.3s ease-in-out',
                  '& .MuiSvgIcon-root': {
                    transition: 'all 0.3s ease-in-out',
                  },
                }
              }
            },
            // containedFilled Color Secondary
            {
              props: { variant: 'containedFilled', color: 'secondary' },
              style: {
                border: `1px solid ${defaultTheme.palette.secondary.main}`,
                color: defaultTheme.palette.text.secondary,
                borderRadius: customSizes.borderRadius.md,
                backgroundColor: defaultTheme.palette.secondary.main,
                '&:hover': {
                  backgroundColor: defaultTheme.palette.secondary.dark,
                  '& .MuiSvgIcon-root': {
                    // color: defaultTheme.palette.background.default,
                  },
                }
              }
            },
            // containedFilled Color Default
            {
              props: { variant: 'containedFilled', color: 'default' },
              style: {
                border: `1px solid ${defaultTheme.palette.grey[400]}`,
                color: defaultTheme.palette.grey[50],
                borderRadius: customSizes.borderRadius.md,
                backgroundColor: defaultTheme.palette.grey[400],
                '&:hover': {
                  backgroundColor: defaultTheme.palette.grey[600],
                  '& .MuiSvgIcon-root': {
                    color: defaultTheme.palette.grey[200],
                    // color: defaultTheme.palette.background.default,
                  },
                }
              }
            },
          ]
        },
        MuiToggleButtonGroup: {
          styleOverrides: {
            root: {
              borderRadius: customSizes.borderRadius.xs,
            },
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              borderRadius: customSizes.borderRadius.xs,
            },
          },
          variants: [
            {
              props: { variant: 'plain' },
              style: {
                borderColor: 'transparent',
              },
            },
          ]
        },
        MuiTabs: {
          defaultProps: {
            indicatorColor: "primary",
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              textTransform: "none",
              color: defaultTheme.palette.text.primary,
              '&.Mui-selected': {
                color: defaultTheme.palette.primary.main
              }
            }
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              '& label': {
                color: defaultTheme.palette.grey[500],
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: customSizes.borderRadius.xs,
                '&.Mui-focused': {
                  '& fieldset': {
                    borderColor: defaultTheme.palette.primary.main,
                  },
                },
                '& fieldset': {
                  borderColor: defaultTheme.palette.primary.main,
                },
                '&:hover fieldset': {
                  borderColor: defaultTheme.palette.primary.main,
                },
              },
              '& .MuiFilledInput-root': {
                borderRadius: customSizes.borderRadius.xs,
                '&.Mui-focused': {
                  '& fieldset': {
                    borderColor: defaultTheme.palette.primary.main,
                  },
                },
                '& fieldset': {
                  borderColor: defaultTheme.palette.primary.main,
                },
                '&:hover fieldset': {
                  borderColor: defaultTheme.palette.primary.main,
                },
              },
              '& .MuiInput-root': {
                borderRadius: customSizes.borderRadius.xs,
                '&.Mui-focused': {
                  '& fieldset': {
                    borderColor: defaultTheme.palette.primary.main,
                  },
                },
                '& fieldset': {
                  borderColor: defaultTheme.palette.primary.main,
                },
                '&:hover fieldset': {
                  borderColor: defaultTheme.palette.primary.main,
                },
              },
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              color: defaultTheme.palette.text.primary,
            },
          },
        },
        MuiAutocomplete: {
          defaultProps: {
            popupIcon: <KeyboardArrowDown style={{ color: defaultTheme.palette.primary.main }} />,
          },
        },
        MuiSelect: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: defaultTheme.palette.primary.main,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: defaultTheme.palette.primary.dark,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: defaultTheme.palette.primary.dark,
              },
            },
          },
          defaultProps: {
            IconComponent: (props) => <KeyboardArrowDown style={{ color: defaultTheme.palette.primary.main }} {...props} />,
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: defaultTheme.palette.grey[500],
              '&.Mui-focused': {
                color: defaultTheme.palette.primary.main,
              },
              '&.Mui-error': {
                color: defaultTheme.palette.error.main,
              },
            },
          },
        },

        MuiCheckbox: {
          styleOverrides: {
            root: {
              color: '#ADADB3',
              '&.Mui-checked': {
                color: defaultTheme.palette.primary.main,
              },
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              color: defaultTheme.palette.grey[500],
              fontSize: customSizes.fontSize.sm,
              margin: '0',
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              borderRadius: '8px',
              border: '1px solid transparent',
              '&.MuiAlert-standardError': {
                backgroundColor: '#FCE8DB',
                borderColor: '#FCE8DB',
                '& .MuiAlert-icon': {
                  color: '#EF665B',
                },
                '& .MuiAlert-message': {
                  color: '#71192F',
                },
                '& .MuiAlert-action': {
                  color: '#71192F',
                },
                '&:hover': {
                  backgroundColor: '#FCE8DB',
                  borderColor: '#EF665B',
                  '& .MuiAlert-icon': {
                    color: '#EF665B',
                  },
                  '& .MuiAlert-message': {
                    color: '#71192F',
                  },
                  '& .MuiAlert-action': {
                    color: '#71192F',
                  },
                },
                '&:focus': {
                  backgroundColor: '#EF665B',
                  borderColor: '#EF665B',
                  '& .MuiAlert-icon': {
                    color: '#FFFFFF',
                  },
                  '& .MuiAlert-message': {
                    color: '#FFFFFF',
                  },
                  '& .MuiAlert-action': {
                    color: '#FFFFFF',
                  },
                },
              },
              '&.MuiAlert-standardSuccess': {
                backgroundColor: '#EDFBD8',
                borderColor: '#EDFBD8',
                '& .MuiAlert-icon': {
                  color: '#84D65A',
                },
                '& .MuiAlert-message': {
                  color: '#2B641E',
                },
                '& .MuiAlert-action': {
                  color: '#2B641E',
                },
                '&:hover': {
                  backgroundColor: '#EDFBD8',
                  borderColor: '#84D65A',
                  '& .MuiAlert-icon': {
                    color: '#84D65A',
                  },
                  '& .MuiAlert-message': {
                    color: '#2B641E',
                  },
                  '& .MuiAlert-action': {
                    color: '#2B641E',
                  },
                },
                '&:focus': {
                  backgroundColor: '#84D65A',
                  borderColor: '#84D65A',
                  '& .MuiAlert-icon': {
                    color: '#393A37',
                  },
                  '& .MuiAlert-message': {
                    color: '#2B641E',
                  },
                  '& .MuiAlert-action': {
                    color: '#2B641E',
                  },
                },
              },
              '&.MuiAlert-standardWarning': {
                backgroundColor: '#FEF7D1',
                borderColor: '#FEF7D1',
                '& .MuiAlert-icon': {
                  color: '#F7C752',
                },
                '& .MuiAlert-message': {
                  color: '#755118',
                },
                '& .MuiAlert-action': {
                  color: '#755118',
                },
                '&:hover': {
                  backgroundColor: '#FEF7D1',
                  borderColor: '#F7C752',
                  '& .MuiAlert-icon': {
                    color: '#F7C752',
                  },
                  '& .MuiAlert-message': {
                    color: '#755118',
                  },
                  '& .MuiAlert-action': {
                    color: '#755118',
                  },
                },
                '&:focus': {
                  backgroundColor: '#F7C752',
                  borderColor: '#F7C752',
                  '& .MuiAlert-icon': {
                    color: '#393A37',
                  },
                  '& .MuiAlert-message': {
                    color: '#393A37',
                  },
                  '& .MuiAlert-action': {
                    color: '#393A37',
                  },
                },
              },
              '&.MuiAlert-standardInfo': {
                backgroundColor: '#D7F1FD',
                borderColor: '#D7F1FD',
                '& .MuiAlert-icon': {
                  color: '#509AF8',
                },
                '& .MuiAlert-message': {
                  color: '#0C2A75',
                },
                '& .MuiAlert-action': {
                  color: '#0C2A75',
                },
                '&:hover': {
                  backgroundColor: '#D7F1FD',
                  borderColor: '#509AF8',
                  '& .MuiAlert-icon': {
                    color: '#509AF8',
                  },
                  '& .MuiAlert-message': {
                    color: '#0C2A75',
                  },
                  '& .MuiAlert-action': {
                    color: '#0C2A75',
                  },
                },
                '&:focus': {
                  backgroundColor: '#509AF8',
                  borderColor: '#509AF8',
                  '& .MuiAlert-icon': {
                    color: '#FFFFFF',
                  },
                  '& .MuiAlert-message': {
                    color: '#0C2A75',
                  },
                  '& .MuiAlert-action': {
                    color: '#0C2A75',
                  },
                },
              },
            },
          },
          defaultProps: {
            iconMapping: {
              error: <ErrorRoundedIcon />,
              success: <CheckCircleRoundedIcon />,
              warning: <WarningIcon />,
              info: <InfoRoundedIcon />,
            },
          },
        },
        MuiDayCalendar: {
          styleOverrides: {
            weekDayLabel: {
              color: defaultTheme.palette.grey[700],
            },
          },
        },
        MuiPickersDay: {
          styleOverrides: {
            root: {
              '&.MuiPickersDay-dayOutsideMonth': {
                color: defaultTheme.palette.grey[500],
              },
            },
          },
        },
        MuiTableContainer: {
          styleOverrides: {
            root: {
              boxShadow: 'none'
            }
          }
        },
        MuiLink: {
          styleOverrides: {
            root: {
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
                color: defaultTheme.palette.primary.main,
                cursor: 'pointer',
                transition: 'color 0.3s ease',
              },
            }
          }
        }
      },
    })

    const finalTheme = createTheme(deepmerge(defaultTheme, customTheme))
    setTheme(finalTheme)
    window.theme = finalTheme
  }, [template, colorMode, devMode]);

  useEffect(() => {
    if (theme) {
      const styleCustom = document.getElementById('style-custom-theme');
      if (styleCustom) {
        document.head.removeChild(styleCustom);
      }
      const cssText = generateCSS(theme);
      const styleTag = document.createElement('style');
      styleTag.id = 'style-custom-theme';
      document.head.appendChild(styleTag);
      styleTag.innerHTML = cssText;
    }
  }, [theme]);

  if (!theme) return null;

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;

};

export { generateCSS };
export default CustomThemeProvider;
