import { useForm } from "react-hook-form";
import TabelaClientes from "../../components/Clientes/TabelaClientes";
import { useRef } from "react";
import { RootState } from "src/store/reducers";
import { DynamicDataContainer } from "src/ds";
import { useSelector } from "react-redux";
import { DynamicDataContainerRef } from "src/ds/components/DynamicDataContainer";

const Clientes = () => {
    const clientesDados = useSelector((state: RootState) => state.clientes.listar?.data);
    const loadingClientes = useSelector((state: RootState) => state.clientes.listar?.loading);

    const hookForm = useForm({
        defaultValues: {
            filtros: '',
            empresaID: '',
            dtInicial: '',
            dtFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const cardRef = useRef<DynamicDataContainerRef>(null)

    return (
        <DynamicDataContainer
            ref={cardRef}
            hookForm={hookForm}
            cardFormFiltrosProps={{
                dispatchMethods: {
                    actionName: 'listaClientesStart'
                },
                fields: [
                    { label: "Buscar", name: "filtros" },
                    { label: 'Empresas', name: 'empresaID', type: 'autocompleteEmpresa' },
                ],
                loading: loadingClientes
            }}
            sections={[
                {
                    label: "Clientes",
                    tableNavigation: {
                        indiceInicial: clientesDados?.indiceInicial,
                        indiceFinal: clientesDados?.indiceFinal,
                        indiceTotal: clientesDados?.total,
                        ultimaPagina: clientesDados?.ultimaPagina ?? 0,
                    },
                    component: <TabelaClientes
                        clientes={clientesDados?.lista}
                        loading={loadingClientes}
                        fezRequisicao={cardRef.current?.fezRequisicao}
                    />,
                },
            ]}
        />
    )
};

export default Clientes;
